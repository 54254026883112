import React from "react";
import styles from "./PreviewPage.module.scss";
import { useLockBodyScroll } from "../../components/shared/Modal/Modal";
import cn from "classnames";

const PreviewPage = ({ className }) => {
  useLockBodyScroll(true);
  return (
    <div className={cn(styles.preview, className)}>
      <div className={styles.letterWrapper}>Innovate Transport Solution</div>
      {/*/!*{"Innovate Transport Solution".split("").map((letter) => (*!/*/}
      {/*  <span>{letter}</span>*/}
      {/*))}*/}
      {/*</div>*/}
    </div>
  );
};

export default PreviewPage;
