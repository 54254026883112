import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import Layout from "../components/Layout/Layout";
import Products from "../pages/Products/Products";
import AboutUs from "../pages/AboutUs/AboutUs";
import Contacts from "../pages/Contacts/Contacts";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path={"products"} element={<Products />} />
        <Route path={"aboutUs"} element={<AboutUs />} />
        <Route path={"contacts"} element={<Contacts />} />
      </Route>
    </Routes>
  );
};

export default Routers;
