export const phoneMask = (value, code, mask) => {
  value = value.replace(/^(\d{2})/g, "($1) ");
  value = value.replace(/^(\d{5})/g, "$1-");
  value = value.replace(/(\d{5})(\d{4})/g, "$1-$2");

  return value;
};
export const telephoneValidation = (tel) => {
  const x = tel
    .replace(/\D/g, "")
    .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,3})/);

  if (!x[0]) {
    tel = "";
    return tel;
  }
  if (!x[1]) {
    tel = "+";

    return tel;
  }

  if (!x[2]) {
    tel = `+${x[1]}`;

    return tel;
  }

  tel =
    `+${x[1]} (${x[2]}` +
    (x[3] ? `) ${x[3]}` : "") +
    (x[4] ? `-${x[4]}` : "") +
    (x[5] ? `-${x[5]}` : "") +
    (x[6] ? `-${x[6]}` : "");
  return tel;
};
export const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const validateName = (name) => {
  const re =
    /^(?=[a-z0-9а-яА-ЯёЁІі])[a-z0-9а-яА-ЯёЁІі\s]{0,99}[a-z0-9а-яА-ЯёЁІі]$/i;
  return re.test(name);
};

export const cutLongText = (text) =>
  text.length > 40 ? text.slice(0, 40) : text;
