import { useEffect, useState } from "react";

export const useDevice = () => {
  const [deviceType, setTypeDevice] = useState("PC");
  useEffect(() => {
    switch (true) {
      case window.innerWidth > 1024: {
        setTypeDevice("PC");
        break;
      }
      case window.innerWidth <= 1024 && window.innerWidth > 450: {
        setTypeDevice("TABLET");
        break;
      }
      default:
        setTypeDevice("MOBILE");
        break;
    }
  }, [window.innerWidth]);

  return deviceType;
};
