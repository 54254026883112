import React, { useEffect, useState } from "react";

const UseAnimation = (ref) => {
  const [isViewed, setIsViewed] = useState(false);

  useEffect(() => {
    const topPos = (element) => element?.getBoundingClientRect().top;
    const divPos = topPos(ref.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight - 20;
      if (divPos < scrollPos) {
        setIsViewed(true);
        // console.log((ref.current.children[0].className += " anim"));
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [window.scrollY]);
};

export default UseAnimation;
