import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import styles from "./Modal.module.scss";
import CloseIcon from "../../../assets/icons/close-icon.svg";
import Logo from "../../../assets/icons/Logo-header.svg";
import ModalImage from "../../../assets/images/ModalImage.png";
import Button from "../Button/Button";
import Input from "../Input/Input";
import cn from "classnames";
import Checkbox from "../Checkbox/Checkbox";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import emailjs from "emailjs-com";
import { Trans, useTranslation } from "react-i18next";
import { useDevice } from "../../../hooks/useDevice";
import { useNavigate } from "react-router-dom";
import Menu from "../../Menu/Menu";
import FormStepper from "../../FormStepper/FormStepper";
import MenuButton from "../MenuButton/MenuButton";
const appRoot = document.getElementById("root");

export const useLockBodyScroll = (isOpen) => {
  useLayoutEffect(() => {
    if (isOpen) {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = originalStyle;
      };
    }
  }, [isOpen]);
};

const ModalCustom = ({
  isOpen,
  isOpenMenu,
  onClose,
  setOpenMenu,
  setOpenModal,
}) => {
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const [checked, setChecked] = useState(true);
  const [sent, setSent] = useState(true);
  const deviceType = useDevice();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    !isOpen &&
      (() => {
        setSent(false);
        reset();
      })();
  }, [isOpen]);

  if (!appRoot) {
    return null;
  }
  const onSubmit = (data) => {
    sendForm(data);
  };

  const mobileVersion = deviceType === "MOBILE";
  const sendForm = ({ time, email, name, telephone }) => {
    emailjs
      .send(
        "service_1n58anp",
        "template_lxyxwic",
        {
          name: name,
          email: email,
          //time: time,
          telephone: telephone,
        },
        "h05Pn7544EdPq_Jdj"
      )
      .then(
        () => {
          setSent(true);
          reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div
      className={cn(styles.modalWrapper, {
        [styles.modalWrapperOpen]: isOpen,
        [styles.modalWrapperOpenMenu]: isOpenMenu,
      })}
    >
      <div ref={modalRef}>
        <div className={styles.modalHeader}>
          <img className={styles.logo} src={Logo} alt={"logo"} />
          {deviceType === "MOBILE" ? (
            <div className={styles.buttonClose}>
              <MenuButton
                setOpenMenu={setOpenMenu}
                setOpenModal={setOpenModal}
              />
            </div>
          ) : (
            <img
              className={styles.close}
              src={CloseIcon}
              alt={"Close"}
              onClick={onClose}
            />
          )}
        </div>
        <div className={styles.modalContent}>
          {mobileVersion && isOpenMenu ? (
            <Menu />
          ) : (
            isOpen &&
            !isOpenMenu && (
              <div className={styles.modalForm}>
                <h1 className={styles.title}>
                  {sent ? t("thanks") : "Intranso Demo"}
                </h1>
                <h3 className={styles.subtitle}>
                  {sent
                    ? t("Demo request sent")
                    : mobileVersion
                    ? null
                    : t("Send contacts for communication")}
                </h3>
                {sent ? (
                  <div className={styles.sentDescription}>
                    <h3>{t("What will happen next")}</h3>
                    <ul>
                      <li>{t("the manager will contact you")}</li>
                      <li>{t("synchronize convenient time for the demo")}</li>
                      <li>
                        {t(
                          "the manager will conduct a demo familiarization with the system"
                        )}
                      </li>
                      <li>{t("you can ask a question about the system")}</li>
                      <li>{t("get access to a personal demo cabinet")}</li>
                      <li>{t("free for 7 days")}</li>
                    </ul>
                    <div className={styles.buttonContainer}>
                      <Button
                        className={styles.buttonCancel}
                        title={t("understand")}
                        onClick={() => {
                          onClose();
                          reset();
                        }}
                      ></Button>
                    </div>
                  </div>
                ) : mobileVersion ? (
                  <FormStepper
                    errors={errors}
                    setValue={setValue}
                    register={register}
                    watch={watch}
                    sendForm={sendForm}
                  />
                ) : (
                  <form
                    autoComplete={"off"}
                    onSubmit={(e) => {
                      if (checked) handleSubmit(onSubmit)(e);

                      e.preventDefault();
                    }}
                  >
                    <Input
                      name="name"
                      register={register}
                      label={t("name")}
                      watch={watch}
                      errors={errors}
                      setValue={setValue}
                      setError={setError}
                    />
                    <Input
                      label="E-mail"
                      name={"email"}
                      register={register}
                      watch={watch}
                      errors={errors}
                      setValue={setValue}
                      setError={setError}
                    />
                    <Input
                      label={t("telephone")}
                      name={"telephone"}
                      register={register}
                      watch={watch}
                      errors={errors}
                      setValue={setValue}
                      setError={setError}
                    />
                    {/*<Input*/}
                    {/*  label={t("Good time for a demo")}*/}
                    {/*  register={register}*/}
                    {/*  name={"time"}*/}
                    {/*  watch={watch}*/}
                    {/*  errors={errors}*/}
                    {/*  setValue={setValue}*/}
                    {/*/>*/}
                    {/*{errors.email && (*/}
                    {/*  <p style={{ color: "white" }}>проверте поле имя</p>*/}
                    {/*)}*/}
                    <div className={styles.confirmation}>
                      <Checkbox checked={checked} setChecked={setChecked} />
                      <span className={styles.confirmDescription}>
                        <Trans i18nKey="I agree to the terms of the privacy policy and authorize the use of my personal" />
                      </span>
                    </div>
                    <div className={styles.buttonWrapper}>
                      <Button
                        className={styles.buttonSubmit}
                        title={t("send")}
                        type="submit"
                      />
                      <Button
                        className={styles.buttonCancel}
                        onClick={(e) => {
                          e.preventDefault();
                          onClose();
                          reset();
                        }}
                        title={t("cancel")}
                      />
                    </div>
                  </form>
                )}
              </div>
            )
          )}
          <img
            className={styles.imageRight}
            src={ModalImage}
            alt={"modalImage"}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalCustom;
