// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Arrow_arrowIcon__jl0HM {\n  transform: rotate(0);\n  transition: 0.5s; }\n\n.Arrow_arrowIconActive__9XwGd {\n  transition: 0.5s;\n  transform: rotate(90deg); }\n", "",{"version":3,"sources":["webpack://./src/assets/Arrow.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,gBAAgB,EAAA;;AAElB;EACE,gBAAgB;EAChB,wBAAwB,EAAA","sourcesContent":[".arrowIcon {\n  transform: rotate(0);\n  transition: 0.5s;\n}\n.arrowIconActive {\n  transition: 0.5s;\n  transform: rotate(90deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrowIcon": "Arrow_arrowIcon__jl0HM",
	"arrowIconActive": "Arrow_arrowIconActive__9XwGd"
};
export default ___CSS_LOADER_EXPORT___;
