import React from "react";
import cn from "classnames";
import Arrow from "../../../assets/icons/arrow-icon.svg";
import styles from "./Button.module.scss";
import ArrowButton from "../../../assets/Arrow";
import { useDevice } from "../../../hooks/useDevice";
import { useTranslation } from "react-i18next";

const Button = ({
  ref,
  title,
  className,
  onClick,
  type = "button",
  image = false,
  arrowColor,
  disabled = false,
  activeImage = false,
}) => {
  const { t } = useTranslation();
  const deviceType = useDevice();
  return (
    <button
      className={cn(styles.button, className)}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
      {image && (
        <ArrowButton
          color={arrowColor}
          active={activeImage}
          className={styles.buttonArrow}
        />
      )}
    </button>
  );
};

export default Button;
