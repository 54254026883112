export const RightAnimation = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2, duration: 0.4 },
  }),
};
export const RightAnimationImage = {
  hidden: {
    width: 0,
    //opacity: 0,
  },
  visible: (custom) => ({
    width: "100%",
    transition: { delay: custom * 0.2, duration: 1 },
  }),
};
export const LeftAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2, duration: 0.8 },
  }),
};
export const downAnimation = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: (delay) => ({
    y: 0,
    opacity: 1,
    transition: { delay: delay * 0.2, duration: 0.5 },
  }),
};
export const downAnimationTitle = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: (delay) => ({
    y: 0,
    opacity: 1,
    transition: { delay: delay * 0.2, duration: 0.5 },
  }),
};

export const AppearanceAnimation = {
  hidden: {
    x: 50,
    opacity: 0,
  },
  visible: (delay) => ({
    opacity: 1,
    x: 0,
    transition: { delay: delay * 0.2, duration: 0.5 },
  }),
};
export const ImageRightAnimation = {
  hidden: {
    x: 50,
    opacity: 0,
  },
  visible: (delay) => ({
    opacity: 1,
    x: 0,
    transition: { delay: delay * 0.2, duration: 0.5 },
  }),
};
