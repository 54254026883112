import Routes from "./Routes/Routes";
import { useEffect, useState } from "react";
import { AppContext } from "./providers/AppContext";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./services/i18n";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

const App = () => {
  const { t, i18n: i18next } = useTranslation();
  const [state, setState] = useState({
    openModal: false,
    openMenu: false,
    language: "en",
  });
  useEffect(() => {
    setState((prev) => ({ ...prev, language: i18next.resolvedLanguage }));
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <div className="App">
        <ScrollToTop />
        <AppContext.Provider value={{ state, setState }}>
          <Routes />
        </AppContext.Provider>
      </div>
    </I18nextProvider>
  );
};

export default App;
