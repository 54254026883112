import React, { useContext, useState } from "react";
import styles from "./Accordion.module.scss";
import classNames from "classnames";
import Button from "../shared/Button/Button";
import { AppContext } from "../../providers/AppContext";
import { useTranslation } from "react-i18next";
import ArrowButton from "../../assets/Arrow";
import { motion } from "framer-motion";
import { downAnimation, RightAnimation } from "../../styles/animations";

const Accordion = ({ item, activeItem, setActiveItem, setHoverIndex, key }) => {
  const [closeCurrentItem, setCloseCurrentItem] = useState(false);
  const { setState } = useContext(AppContext);
  const { t } = useTranslation();

  const openItemHandle = () => {
    item.id === activeItem ? setActiveItem(null) : setActiveItem(item.id);
    // setActiveItem(item.id);
  };
  const onHoverEnter = () => {
    setHoverIndex(item.id);
  };
  const onHoverLeave = () => {
    setHoverIndex(null);
  };

  return (
    <motion.div
      className={styles.accordionItem}
      initial={"hidden"}
      whileInView={"visible"}
      viewport={{ amount: 0.3, once: true }}
      variants={downAnimation}
      custom={(key + 2) / 10}
    >
      <div
        className={styles.accordionTitle}
        onClick={openItemHandle}
        onMouseEnter={onHoverEnter}
        onMouseLeave={onHoverLeave}
      >
        {item.title}
        <ArrowButton color={"white"} active={item?.id === activeItem} />
      </div>
      <div
        className={classNames(styles.accordionBody, {
          [styles.accordionBodyShow]: item?.id === activeItem,
        })}
      >
        <div className={styles.accordionContent}>
          <ul>
            {item.list.map((li) => (
              <li key={li}>{li}</li>
            ))}
          </ul>
          <a
            className={styles.buttonConnect}
            ///title={t("contactManager")}
            onClick={() => setState({ openModal: true })}
          >
            {t("contactManager")}
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default Accordion;
