import React from "react";
import cn from "classnames";
import styles from "./Checkbox.module.scss";
const Checkbox = ({ checked, setChecked, value }) => {
  return (
    <div className={styles.checkboxContainer}>
      <input
        className={styles.hiddenCheckbox}
        checked={checked}
        value={value}
        readOnly
      />
      <div
        className={cn(styles.customCheckbox, {
          [styles.activeCheckbox]: checked,
        })}
        onClick={() => setChecked(!checked)}
      >
        <svg className={styles.checkboxIcon} viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </svg>
      </div>
    </div>
  );
};

export default Checkbox;
