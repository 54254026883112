import React, { useContext } from "react";
import styles from "../shared/Modal/Modal.module.scss";
import Button from "../shared/Button/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../providers/AppContext";
import button from "../shared/Button/Button";
import cn from "classnames";

const Menu = () => {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const { state, setState } = useContext(AppContext);

  const navigationHandle = (link) => {
    navigate(`/${link}`);
    setState((prev) => ({ ...prev, openModal: false, openMenu: false }));
  };
  const languageButtons = [
    { id: 1, title: "Ua", lng: "ua" },
    { id: 1, title: "En", lng: "en" },
    { id: 1, title: "Ru", lng: "ru" },
  ];
  const downloadHandle = () => {
    const resUa = "/documents/INTRANSOSmartCounter(UA).pdf";
    const resRu = "/documents/INTRANSOSmartCounter(RU).pdf";
    const resEn = "/documents/INTRANSOSmartCounter(EN).pdf";
    const fileByLanguage =
      i18n.language === "ru" ? resRu : i18n.language === "en" ? resEn : resUa;

    const fileValue = fileByLanguage.split("/")[2];

    const tempLink = document.createElement("a");
    tempLink.href = fileByLanguage;
    tempLink.setAttribute("download", fileValue);
    tempLink.click();
  };
  const demoAccessHandle = () => {
    setState((prev) => ({ ...prev, openModal: true, openMenu: false }));
  };

  const changeLanguageHandle = (lng) => {
    i18n.changeLanguage(lng);
    setState((prev) => ({ ...prev, language: lng }));
  };
  return (
    <div className={styles.menuWrapper}>
      <div className={styles.mobileNavigation}>
        <span onClick={() => navigationHandle("")}>{t("main")}</span>
        <span onClick={() => navigationHandle("products")}>
          {t("products")}
        </span>
        <span onClick={() => navigationHandle("aboutUs")}>{t("aboutUs")}</span>
        <span onClick={() => navigationHandle("contacts")}>
          {t("contacts")}
        </span>
      </div>
      <div className={styles.buttonAction}>
        {/*<span onClick={downloadHandle}>{t("uploadPresentation")}</span>*/}
        <span onClick={demoAccessHandle}>{t("getDemoAccess")}</span>
      </div>
      <div className={styles.languageButton}>
        {languageButtons.map((buttonItem) => (
          <Button
            key={buttonItem.id}
            title={buttonItem.title}
            onClick={() => changeLanguageHandle(buttonItem.lng)}
            className={cn(styles.currentButton, {
              [styles.activeButton]: buttonItem.lng === state.language,
            })}
          />
        ))}
      </div>
    </div>
  );
};

export default Menu;
