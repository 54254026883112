// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Cursor_cursor__vIP1i {\n  position: fixed;\n  z-index: 2000;\n  border-radius: 50%;\n  background: transparent;\n  box-shadow: 1px 1px 2px black;\n  width: 20px;\n  height: 20px;\n  transform: translate3d(0, 0, 0);\n  pointer-events: none;\n  -webkit-backdrop-filter: invert(1);\n          backdrop-filter: invert(1); }\n", "",{"version":3,"sources":["webpack://./src/components/shared/Cursor/Cursor.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EAEb,kBAAkB;EAClB,uBAAuB;EACvB,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,+BAA6B;EAC7B,oBAAoB;EACpB,kCAAyB;UAAzB,0BAAyB,EAAA","sourcesContent":[".cursor{\n  position: fixed;\n  z-index: 2000;\n  //border:1px solid black;\n  border-radius: 50%;\n  background: transparent;\n  box-shadow: 1px 1px 2px black;\n  width: 20px;\n  height: 20px;\n  transform: translate3d(0,0,0);\n  pointer-events: none;\n  backdrop-filter:invert(1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cursor": "Cursor_cursor__vIP1i"
};
export default ___CSS_LOADER_EXPORT___;
