import React from "react";

export const ru = {
  ////********/Header
  main: "Главная",
  products: "Продукты",
  aboutUs: "О нас",
  contacts: "Контакты",
  headerHomeTitle:
    "СДЕЛАЙТЕ СВОЙ ТРАНСПОРТ УМНЫМ С ПОМОЩЬЮ INTRANSO",
  headerHomeTitleLine1: "СДЕЛАЙТЕ СВОЙ",
  headerHomeTitleLine2: "ТРАНСПОРТ УМНЫМ",
  headerHomeTitleLine3: "С INTRANSO",
  headerHomeTitleLine4: "",
  headerHomeTitleLine5: "",
  headerHomeTitleLine6: "",
  headerHomeTitleLine7: "",

  headerHomeTitleMobile:
    "СДЕЛАЙТЕ СВОЙ ТРАНСПОРТ УМНЫМ С ПОМОЩЬЮ INTRANSO",
  headerHomeTitleMobileLine1: "СДЕЛАЙТЕ",
  headerHomeTitleMobileLine2: "СВОЙ  ",
  headerHomeTitleMobileLine3: "ТРАНСПОРТ ",
  headerHomeTitleMobileLine4: "УМНЫМ",
  headerHomeTitleMobileLine5: "С INTRANSO",
  headerHomeTitleMobileLine6: "",
  headerHomeTitleMobileLine7: "",
  headerHomeTitleMobileLine8: "",

  headerProductTitle:
    "INTRANSO SMART COUNTER Автоматизированный <br /> подсчет пассажиров <br /> в режиме реального времени",
  //Автоматизированный подсчет пассажиров в режиме реального времени
  headerProductTitleLine1: "INTRANSO SMART ",
  headerProductTitleLine2: "COUNTER",
  headerProductTitleLine3: "",
  headerProductTitleLine4: "",
  headerProductTitleLine5: "",
  headerProductTitleLine6: "",
  headerProductTitleLine7: "",

  subHeaderProductTitle: "Автоматизированный подсчет<br /> пассажиров в реальном времени",
  subHeaderProductTitleTablet: "Автоматизированный <br />подсчет пассажиров <br />в реальном времени",

  headerProductTitleMobile: "",
  headerProductTitleMobileLine1: "INTRANSO",
  headerProductTitleMobileLine2: "SMART",
  headerProductTitleMobileLine3: "COUNTER",
  headerProductTitleMobileLine4: "",
  headerProductTitleMobileLine5: "",
  headerProductTitleMobileLine6: "",
  headerProductTitleMobileLine7: "",

  subHeaderProductTitleMobile: "Автоматизированный <br />подсчет пассажиров в<br /> реальном времени",
  headerAboutUsTitle:
    "Израильская компания с 10 летним опытом работы на международных рынках",

  headerAboutUsTitleLine1: "Израильская компания",
  headerAboutUsTitleLine2: " с 10 летним опытом работы",
  headerAboutUsTitleLine3: "на международных рынках",
  headerAboutUsTitleLine4: "",
  headerAboutUsTitleLine5: "",
  headerAboutUsTitleLine6: "",
  headerAboutUsTitleLine7: "",

  subHeaderAboutUsTitle: " Наши решения в концепции \"Город будущего\" помогают <br />на основе исторических моделей и данных в режиме онлайн <br />прогнозировать будущие потоки. Управлять маршрутами, <br />поездками, расписаниями с контролем состояния <br />транспорта и транспортных задач",

  headerAboutUsTitleTablet:
    "Израильская Компания <br/> специализирующаяся <br/>на разработке и внедрении<br/> инновационных решений в сфере общественных<br/> перевозок ",
  IsraeliTechnology: "Израильская технология",
  AIBasedSolutions: "Решения на основе искусственного интеллекта для <br />проектирования, создания и управления подключенной <br />транспортной инфраструктурой",
  AIBasedSolutionsMobile: "Решения на основе <br />искусственного интеллекта для проектирования, создания и <br />управления подключенной <br />транспортной <br />инфраструктурой",

  headerAboutUsTitleMobile: "Израильская компания с 10 летним опытом работы на международных рынках",
  headerAboutUsTitleMobileLine1: "Израильская ",
  headerAboutUsTitleMobileLine2: "компания с 10 летним  ",
  headerAboutUsTitleMobileLine3: "опытом работы на международных",
  headerAboutUsTitleMobileLine4: " рынках",
  headerAboutUsTitleMobileLine5: "",
  headerAboutUsTitleMobileLine6: "",
  headerAboutUsTitleMobileLine7: "",

  subHeaderAboutUsTitleMobile: " Наши решения в концепции <br />\"Город будущего\" помогают <br />на основе исторических <br />моделей и данных в режиме <br />онлайн прогнозировать будущие <br />потоки. Управлять маршрутами, <br />поездками, расписаниями с <br />контролем состояния <br />транспорта и транспортных <br />задач",

  ourPresentation: "Наша презентация",
  ourPresentationTablet: "Презентация",
  demo: "Запросить демо",
  demoTablet: "Демо",
  headerHomeDescription:
    "Планирование транспортных средств и подсчет пассажиров <br />для оптимальной частоты поездки транспортных средств",
  headerHomeDescriptionTablet:
    "Планирование транспортных средств и подсчет пассажиров для оптимальной частоты поездки транспортных средств",
  headerHomeDescriptionMobile:  "Планирование транспортных средств и подсчет пассажиров для оптимальной частоты поездки транспортных средств",
  headerProductDescription: "проверенная <br />технология <br />и устройство",
  headerProductDescriptionMobile: "проверенная технология <br />и устройство",
  headerAboutUsDescription:
    "Наш фокус - рекомендательные услуги, разработка <br />и управление сетями, маршрутами для общественного<br /> транспорта и автомобилей без водителя",
  headerAboutUsDescriptionTablet:
    "Наш фокус - рекомендательные услуги, разработка и управление сетями, маршрутами для общественного транспорта и автомобилей без водителя",
  headerAboutUsDescriptionMobile: "Наш фокус - рекомендательные услуги, <br />разработка и управление сетями, <br />маршрутами для общественного транспорта <br />и автомобилей без водителя",
  ///**********************/Home Page
  "Our sensors and computer vision-based software are designed to count the number of passengers at any point of the journey":
    "Наши датчики и программное обеспечение на основе компьютерного зрения предназначены для подсчета количества пассажиров в любой точке пути",
  "Our sensors and computer vision-based software are designed to count the number of passengers at any point of the journey mobile":
    "Наши датчики и программное обеспечение на основе компьютерного зрения предназначены для <br />подсчета количества пассажиров в любой <br />точке пути",
  "For transport companies, acting on this data improves economics and transparency":
    "Для транспортных компаний работа с этими данными <br />улучшает экономику и прозрачность",
  "For transport companies, acting on this data improves economics and transparency mobile":
    "Для транспортных компаний <br />работа с этими данными улучшает <br />экономику и прозрачность",
  "additional profit daily":
    "дополнительной прибыли<br /> ежедневно",
  "additional profit daily mobile":
    "дополнительной прибыли <br />ежедневно",
  "costs savings monthly":
    "экономия затрат <br />ежемесячно",
  "costs savings monthly mobile":
    "экономия затрат <br />ежемесячно",
  aboutTheProduct: "Узнать про продукт",
  whoUses: "Наше решение <br />для",
  whoUsesMobile: "Наше решение для",
  privateTransportCompanies: "Частных транспортных компаний",
  "Private transport companies": "Частные транспортные компании",
  "Private transport companies mobile": "Частные транспортные компании",
  "сontrol of passanger flow in real time": "контроль пассажиропотока в режиме реального времени  ",
  "predicting flows": "прогнозирование транспортных потоков",
  "revenue collection control": "контроль сбора виручки",
  "GPS monitoring of vehicles operation": "GPS-мониторинг работы транспортных средств",
  "personnel control": "контроль персонала",
  "online fleet management": "онлайн-управление автопарком",
  "efficient organization of the work of conductor teams": "эффективная организация работы дирижера команды",

  municipalCarriers: "Муниципальных перевозчиков",
  comparisonTheNumberPayments:
    "сравнение количества онлайн-платежей<br/> с реальным пассажиропотоком",
  comparisonTheNumberPaymentsMobile:
    "сравнение количества онлайн-платежей с реальным пассажиропотоком",
  increasingPaymentDiscipline: "повышение платежной дисциплины",
  identificationPassengers: "идентификация «безбилетных» пассажиров",
  efficientOrganization:
    "эффективная организация работы контрольных<br/> бригад",
  efficientOrganizationMobile:
    "эффективная организация работы контрольных бригад",
  regionalAgencies: "Региональных агентств <br/>и муниципальных органов власти",
  efficientOrganizationPassengerTransportation:
    "эффективная организация процесса<br/> перевозки пассажиров",
  efficientOrganizationPassengerTransportationMobile:
    "эффективная организация процесса перевозки пассажиров",
  planningRoutesSchedules: "планирование маршрутов, расписаний и рейсов",
  optimizationExisting: "оптимизация существующих маршрутных сетей",
  controlOverTimeliness: "контроль за своевременностью рейсов",
  optimizationCompensation: "оптимизация компенсации субсидируемых тарифов",
  controlPaymentsCompleted:
    "контроль оплат за выполненные транспортные<br/> задачи",
  controlPaymentsCompletedMobile:
    "контроль оплат за выполненные транспортные задачи",
  dispatchCenterManagement: "управление диспетчерским центром",
  efficientUseTransportInfrastructure:
    "эффективное использование транспортной<br/> инфраструктуры",
  efficientUseTransportInfrastructureMobile:
    "эффективное использование<br/> транспортной инфраструктуры",
  railwayCompaniesSubways: "Железнодорожных компаний и метро",
  controlRevenueCollectionCash: "контроль сбора выручки при наличной оплате",
  effectiveOrganizationTransportNetwork:
    "эффективная организация транспортной сети <br/> с учетом расписаний других участников<br/> пассажирских перевозок",
  effectiveOrganizationTransportNetworkMobile:
    "эффективная организация транспортной сети  с учетом расписаний других участников пассажирских перевозок",
  comparisonNumberOnlinePayments:
    "сравнение количества онлайн-платежей <br/>(билетов) с фактическим пассажиропотоком",
  comparisonNumberOnlinePaymentsMobile:
    "сравнение количества онлайн-платежей (билетов) с фактическим пассажиропотоком",
  planningOptimizingRollingStock:
    "планирование и оптимизация использования<br/> подвижного состава",
  planningOptimizingRollingStockMobile:
    "планирование и оптимизация использования подвижного состава",
  transportationChildrenStaff: "Перевозки детей и персонала",
  contactManager: "Связаться с менеджером",
  controlSafeTransportation: "контроль безопасной перевозки",
  monitoringTimelinessFlights: "контроль своевременности рейсов",
  planningRouteRealTime:
    "планирование лучшего маршрута в режиме<br/> реального времени",
  planningRouteRealTimeMobile:
    "планирование лучшего маршрута в режиме реального времени",
  efficientUseTransport: "эффективное использование транспорта",
  notificationArrival: "уведомление о прибытии",
  flightBooking: "бронирование билетов",
  whatMakesIntransoUnique: "В чем уникальность Intranso",
  whatMakesIntransoUniqueMobile: "В чем уникальность<br/> Intranso",
  advancedTechnologyPlanningOptimization:
    "передовая технология для контроля, планирования, оптимизации,<br/> управления и отчетности на базе <br/>искусственного интеллекта (AI)",
  advancedTechnologyPlanningOptimizationMobile:
    "передовая технология для<br/> контроля, планирования, оптимизации, управления и отчетности на базе искусственного<br/> интеллекта (AI)",
  AIPoweredSystem: "Система на базе искусственного<br/> интеллекта строит интеллектуальную сеть,<br/> определяет и реализует оптимальные<br/> транзитные потоки для пассажиров,<br/> следующих в одном направлении",
  AIPoweredSystemMobile: "Система на базе искусственного интеллекта строит интеллектуальную сеть, определяет и реализует оптимальные транзитные потоки для пассажиров, следующих в одном направлении",
  AIPoweredSystemTablet: "Система на базе искусственного интеллекта строит интеллектуальную сеть, определяет и реализует оптимальные транзитные потоки для пассажиров, следующих в одном направлении",
  engagingWithHistorical: "Использование исторических <br />данных и данных в режиме реального времени о количестве пассажиров является основой для успешного создания, управления и эксплуатации динамичной интеллектуальной подключенной транспортной сети",
  engagingWithHistoricalTablet: "Использование исторических данных и данных в режиме реального времени о количестве пассажиров является основой для успешного создания, управления и эксплуатации динамичной интеллектуальной подключенной транспортной сети",
  ourSolutionHelpsTransportOperators: "Наше решение помогает <br/>транспортным операторам сократить эксплуатационные расходы и динамично увеличивать прибыль, контролировать  и планировать все перевозки",
  ourSolutionHelpsTransportOperatorsTablet: "Наше решение <br/>помогает транспортным операторам сократить эксплуатационные расходы и динамично увеличивать прибыль, контролировать  и планировать все перевозки",
  ourSolutionHelpsTransportOperatorsMobile: "Наше решение помогает транспортным операторам сократить эксплуатационные расходы и динамично увеличивать прибыль, контролировать  и планировать все перевозки",
  ownDevelopmentSoftware: "Программное и аппаратное<br/> обеспечение собственной разработки",
  cloudSecure: "Данные надежно защищены <br/>и хранятся до 10 лет",
  minimizationHumanFactor: "Минимизация человеческого <br/>фактора",
  ///****************Footer
  anyQuestions: "Возникли вопросы?",
  weWillAdviseYouQuestions: "Мы проконсультируем вас по всем вопросам",
  demoAccess: "Демо доступ",
  ourManagerWillContactYou:
    "Наш менеджер свяжется с вами и предоставит <br/>доступ в демонстрационный кабинет",
  ourManagerWillContactYouMobile:
    "Наш менеджер свяжется<br/> с вами и предоставит доступ в<br/> демонстрационный кабинет",
  wantToSeeDemo: "Хочу посмотреть демо",
  leaderExpertMarketSolutionsTransportInfrastructure:
    "лидер и эксперт рынка решений для транспортной инфраструктуры в организации общественных перевозок с успешным опытом разработки<br /> и внедрения более 10 лет",
  leaderExpertMarketSolutionsTransportInfrastructureMobile:
    "лидер и эксперт рынка <br/>решений для транспортной инфраструктуры в организации общественных перевозок с успешным опытом разработки и внедрения более 10 лет",

  uploadPresentation: "Загрузить презентацию",
  getDemoAccess: "Получить демо доступ",
  contact: "Контакты",
  usefulInfo: "Полезное инфо",
  "Us on social networks": "Мы в соцсетях",

  /////***********************Product Page
  "Automatic passengers counting system":
    "Автоматичекая система подсчета пассажиров",
  realTimePassengerTraffic: "Учет пассажиропотока <br/>в режиме реального времени на основе системы, запатентованной Intranso",
  realTimePassengerTrafficMobile: "Учет пассажиропотока <br/>в режиме реального времени на основе системы, запатентованной Intranso",
  "The device is mounted on all modes of public transport, adapts to all types of doors":
    "Устройство устанавливается на все виды<br/> городского транспорта, адаптируеться <br/>под все типы дверей (от h 1, 2 m)",
  "The device is mounted on all modes of public transport, adapts to all types of doors mobile":
    "Устройство устанавливается на все виды городского транспорта, адаптируеться под все типы дверей <br/>(от h 1, 2 m)",
  theIntransoAIDevice: "Устройство Intranso AI работает на основе технологии компьютерного зрения",
  theIntransoAIDeviceTablet: "Устройство Intranso AI работает на основе <br />технологии компьютерного зрения",
  theIntransoAIDeviceMobile: "Устройство Intranso AI работает на основе технологии компьютерного зрения",
  PassengerTrafficInReal:"Пассажиропоток в режиме реального времени по маршрутам, остановкам, выпускам, автомобилям",
  RoutesAndTrips: "Мониторинг состояния маршрутов<br /> и поездок",
  trackingOfTransport: "Отслеживание транспорта в режиме реального времени",
  AnticipationOfPossible: "Предвидение возможных проблем",
  ETAEstimated: "ETA (Расчетное время прибытия) Уведомление <br />о задержке автомобиля",
  smartCounters: "Smart counters",
  videoReport: "Video report",
  gpsTracker: "GPS tracker",
  informationSystems: "Information systems",
  softwareApplication: "Software application",
  onlineVideoSurveillance: "Online video surveillance in transport",
  getDemo: "Запросить демо",
  allDataTransferredServer:
    "Все данные<br/> передаються <br/>на облачный сервер",
  "Intranso Pcounter Software receives the data":
    "Данные получает <br/>Intranso Pcounter <br/> Software",
  "Detailed statistics on passenger flow for each vehicle":
    "Подробная статистика о пассажиропотоке <br/>для каждого транспортного средства:",
  "Detailed statistics on passenger flow for each vehicle mobile":
    "Подробная статистика о <br/>пассажиропотоке для<br/> каждого транспортного средства:",
  route: "маршрута",
  trips: "поездки",
  stops: "остановки",
  forAnyGivenPeriod: "за любой период времени",
  vehicleLoadAnalysis: "Аналитика загрузки <br/>транпортных средств",
  webAndMobileVersions: "Веб и мобильная версия",
  itAllAboutIntranso: "это все про INTRANSO  ",
  toolEffectiveDecisionMaking:
    "Инструмент  для принятия<br/> эффективных решений",
  plan: "Планировать",
  control: "Контролировать",
  optimize: "Оптимизировать",
  forFleet: "Для автопарков",
  application: "Приложение",
  planning: "Планирование",
  controlInstruments: "Инструменты контроля",
  flightMonitoring: "Мониторинг рейсов",
  forFleetManagement: "Для автопарков",
  fleetManagement: "Управление автопарком",
  fleetManagementMobile: "Управление автопарком",
  realTimePassengerFlowInformation: "Информация о пассажиропотоке<br/> в режиме реального времени",
  realTimePassengerFlowInformationTablet: "Информация <br/>о пассажиропотоке в режиме реального времени",
  realTimePassengerFlowInformationMobile: "Информация о пассажиропотоке в режиме реального времени",
  operationControl: "Операционный контроль",
  operationControlMobile: "Операционный контроль",
  planningScheduling: "Планирование и расписания",
  planningSchedulingMobile: "Планирование и расписания",
  workOptimization: "Оптимизация работы",
  workOptimizationMobile: "Оптимизация работы",
  businessIntelligence: "Бизнес-аналитика",
  businessIntelligenceMobile: "Бизнес-аналитика",
  "Application for the driver": "Приложение для водителя",
  from: "от",
  to: "до",
  "Create routes on the map": "Создание маршрутов на карте ",
  "route scheduling": "Планирование расписания ",
  "assign employees to transport tasks on planned routes":
    "Назначение исполнителей для транспортных<br/> заданий по запланированным маршрутам ",
  "assign employees to transport tasks on planned routes mobile":
    "Назначение исполнителей для транспортных заданий по запланированным маршрутам ",
  "Export data from other systems": "Экспорт данных из других систем",

  "optimization of routes, flights": "Оптимизация маршрутов, рейсов ",
  onlineExecutionChart: "Диаграмма выполнения онлайн",
  "different colors for completed":
    "Различные цвета для готовых,<br/> незавершенных и невыполненных рейсов ",
  "different colors for completed mobile":
    "Различные цвета для готовых,<br/> незавершенных<br/ и невыполненных рейсов ",

  "incomplete and missed flights": "Незавершенные и пропущенные рейсы",
  "detailed information about each flight":
    "Подробная информация о каждом рейсе",
  toolsForControl: "Инструменты контроля",
  creationStops:
    "Создание остановок (на карте, по<br/> координатам или данным экспорта) ",
  creationStopsMobile:
    "Создание остановок (на карте,<br/> по координатам или данным экспорта) ",
  createEditRoutes: "Создание/редактирование маршрутов ",
  "support for layouts and multi-directional trips":
    "Поддержка схем и разнонаправленных<br/> рейсов (прямые, обратные, нулевые поездки)",
  "support for layouts and multi-directional trips mobile":
    "Поддержка схем<br/> и разнонаправленных рейсов (прямые, обратные, нулевые поездки)",
  "correcting and arranging stops": "Корректировка и упорядочение остановок",
  "editing routes on the map": "Редактирование маршрутов на карте",
  "editing routes on the map mobile": "Редактирование маршрутов<br/> на карте",
  "fleet management tools": "Инструменты для управления автопарком",
  "GPS tracking and monitoring": "GPS отслеживание и мониторинг",
  "GPS tracking and monitoring mobile": "GPS отслеживание<br/> и мониторинг",
  "wide range of reports": "Широкий диапазон отчетов",
  "Records of service and repair work":
    "Учет проведения сервисных и ремонтных работ",
  "Records of service and repair work mobile":
    "Учет проведения сервисных<br/> и ремонтных работ",
  "maintenance planning": "Планирование технического обслуживания ",
  "online control of repair costs":
    "Контроль расходов на ремонт в режиме онлайн",
  "online control of repair costs mobile":
    "Контроль расходов на ремонт<br/> в режиме онлайн",
  "Employee time tracking": "Мониторинг рабочего времени сотрудников",
  "fuel consumption control": "Контроль расхода топлива",
  "information and messages about traffic intervals on the route":
    "Информация и сообщения об интервалах<br/> движения транспорта на маршруте ",
  "information and messages about traffic intervals on the route mobile":
    "Информация и сообщения<br/> об интервалах движения<br/> транспорта на маршруте ",
  "notification of the trip and changes": "Уведомление о поездке и изменениях",
  "notification of the trip and changes mobile":
    "Уведомление о поездке<br/> и изменениях",
  "detailed information about the route with timetable":
    "подробная информация о маршруте<br/> с расписанием движения",
  "detailed information about the route with timetable mobile":
    "подробная информация<br/> о маршруте с расписанием<br/> движения",
  "comparison of the actual trip with the schedule and estimated time of arrival":
    "Сравнение фактической поездки с расписанием<br/> и расчетным временем прибытия",
  "comparison of the actual trip with the schedule and estimated time of arrival tablet":
    "Сравнение фактической поездки с расписанием и расчетным<br/> временем прибытия",
  "comparison of the actual trip with the schedule and estimated time of arrival mobile":
    "Сравнение фактической<br/> поездки с расписанием<br/> и расчетным временем прибытия",
  integrationWithElectronicPayment:
    "Интеграция с электронными системами<br/> оплат дает возможность в режиме<br/> реального времени контролировать<br/> оплаты(валидации) за проезд",
  integrationWithElectronicPaymentMobile:
    "Интеграция с электронными<br/> системами оплат дает возможность<br/> в режиме реального времени <br/>контролировать оплаты<br/>(валидации) за проезд",
  IntransoProductsImproveRevenue:
    "Продукты Intranso улучшают сбор доходов транспортных компаний, снижают затраты <br/>и повышают прозрачность <br/>и управляемость бизнеса",
  IntransoProductsImproveRevenueMobile:
    "Продукты Intranso улучшают сбор доходов транспортных компаний, снижают затраты и повышают прозрачность и управляемость бизнеса",
  weJustOfferSystemForCountingPassengers:
    "Мы предлагаем не просто систему для подсчета<br/> пассажиров – мы предлагаем<p> комплексное<br/> решение</p> задач клиентов с использованием <br/>инновационных информационных технологий",
  weJustOfferSystemForCountingPassengersTablet:
    "Мы предлагаем не просто систему для<br/> подсчета пассажиров – мы предлагаем<br/><p> комплексное решение</p> задач клиентов <br/>с использованием инновационных<br/> информационных технологий",
  weJustOfferSystemForCountingPassengersMobile:
    "Мы предлагаем не <br/>просто систему для подсчета пассажиров – мы предлагаем<p> комплексное решение</p> задач клиентов с использованием инновационных информационных технологий",
  centralizedManagementOfThePublicTransport:
    "ЦЕНТРАЛИЗОВАННОЕ УПРАВЛЕНИЕ ИНФРАСТРУКТУРОЙ ОБЩЕСТВЕННОГО ТРАНСПОРТА ЧЕРЕЗ УМНЫЕ СЕТИ",
  centralizedManagementOfThePublicTransportMobile:
    "ЦЕНТРАЛИЗОВАННОЕ УПРАВЛЕНИЕ ИНФРАСТРУКТУРОЙ ОБЩЕСТВЕННОГО ТРАНСПОРТА ЧЕРЕЗ УМНЫЕ СЕТИ",
  implementationEffect: "Эффект<br/> от внедрения",
  implementationEffectMobile: "Эффект от внедрения",
  realTimePassengerFlowByRoutes:
    "Пассажиропоток в режиме реального времени по маршрутам, остановкам, выпускам, траспортным стредствам",
  realTimePassengerFlowByRoutesTablet:
    "Пассажиропоток в режиме реального<br/> времени по маршрутам, остановкам,<br/> выпускам, траспортным стредствам",
  realTimePassengerFlowByRoutesMobile:
    "Пассажиропоток в режиме реального времени по маршрутам, остановкам, выпускам, траспортным стредствам",
  convenientPlanningTransport: "Удобное планирование транспортной работы",
  convenientPlanningTransportTablet:
    "Удобное планирование транспортной<br/> работы",
    incomeIncreaseTo30: "Увеличение дохода до 30%",
  OperatingEfficiencyIncrease: "Уменьшение операционных <br/>и эксплуатационных затрат до 20%",
  qualityControlTraffic:
    "Качественный контроль показателя загруженности транспортного средства",
  qualityControlTrafficMobile:
    "Качественный контроль<br/> показателя загруженности транспортного средства",
  successfulPlanningOptimalNumberVehicles:
    "Успешное планирование оптимального<br/> количества транспорта, выходящего<br/> на маршрут",
  successfulPlanningOptimalNumberVehiclesTablet: "Успешное планирование оптимального количества<br/> транспорта, выходящего<br/> на маршрут",
  successfulPlanningOptimalNumberVehiclesMobile: "Успешное планирование оптимального количества транспорта, выходящего<br/> на маршрут",
  complianceWithNormsPermissibleNumber:
    "Соблюдение норм допустимого<br/> количества пассажиров в салоне<br/> автомобиля",
  complianceWithNormsPermissibleNumberMobile:
    "Соблюдение норм допустимого количества пассажиров<br/> в салоне автомобиля",
  optimizationWorkControllers: "Оптимизация работы контролеров",
  benefits: "Преимущества",
  theWayToTheCityOfTheFuture: "Путь к \"Город будущего\" - концепция <br />Smart City - реализация",
  theWayToTheCityOfTheFutureMobile: "Путь к \"Город будущего\" <br />- концепция Smart City -<br /> реализация",
  TheConceptOfModularProduct: "Концепция модульной  разработки продукта позволяет модульное подключение услуг <br />в зависимости от ваших потребностей",
  TheConceptOfModularProductTablet: "Концепция модульной  разработки продукта позволяет модульное подключение услуг <br />в зависимости от ваших потребностей",
  TheConceptOfModularProductMobile: "Концепция модульной  <br/>разработки продукта позволяет модульное подключение услуг <br/>в зависимости от ваших потребностей",

  "Increase revenue by controlling revenue collection by up to 30%":
    "Увеличение дохода за счет контроля<br/> сбора выручки <p>на 30%</p> ",
  "Increase revenue by controlling revenue collection by up to 30% mobile":
    "Увеличение дохода <br/> за счет контроля<br/> сбора выручки <p>на 30%</p> ",
  schedulingAndMatchingPassengers:
    "Планирование и подсчет пассажиров<br/> для оптимальной частоты транспортных<br/> средств в режиме реального времени",
  schedulingAndMatchingPassengersTablet:
    "Планирование и подсчет<br/> пассажиров для оптимальной<br/> частоты транспортных средств<br/> в режиме реального времени",
  schedulingAndMatchingPassengersMobile:
    "Планирование и подсчет<br/> пассажиров для оптимальной<br/> частоты транспортных средств<br/> в режиме реального времени",
  "Increasing competitiveness through the introduction of information technology":
    "Повышение конкуретноспособности<br/> за счет внедрение информационных <br/> технологий",
  "Increasing competitiveness through the introduction of information technology tablet":
    "Повышение конкуретноспособности<br/> за счет внедрение<br/> информационных  технологий",
  "Increasing competitiveness through the introduction of information technology mobile":
    "Повышение конкуретноспособности <br/>за счет внедрение информационных  технологий",
  paybackPeriod:
    "Окупаемость покупки систем<br/> <p>от 3-ох до 5-ти месяцев</p>",
  paybackPeriodMobile:
    "Окупаемость покупки систем<br/> <p>от 3-ох до 5-ти месяцев</p>",
  UsingTheServiceIsSimple: "Пользоваться услугой просто<br/> и легко для работников любого уровня ответственности",
  UsingTheServiceIsSimpleMobile: "Пользоваться услугой просто <br/>и легко для работников любого уровня ответственности",
  AutomaticCountingOfPassengers:
    "Автоматический подсчет пассажиров <br/>не зависит от человеческого <br/>фактора и минимизирует его",
  AutomaticCountingOfPassengersTablet:
    "Автоматический подсчет <br/>пассажиров не зависит <br/>от человеческого фактора <br/>и минимизирует его",
  AutomaticCountingOfPassengersMobile:
    "Автоматический подсчет<br/> пассажиров не зависит<br/> от человеческого фактора<br/> и минимизирует его",
  ///*******************************************/Contact Us
  "Contact us": "Связатся с нами",
  "Contact us mobile": "Связатся<br/> с нами",
  "Our managers will help you choose the most effective solution for the development of your business":
    "Наши менеджеры помогут вам подобрать<br/> наиболее эффективное решение для<br/> развития вашего бизнеса",
  "Our managers will help you choose the most effective solution for the development of your business mobile":
    "Наши менеджеры помогут вам подобрать наиболее эффективное решение для развития вашего бизнеса",
  Israel: "Израиль",
  Ukraine: "Украина",
  Australia: "Австралия",
  locationIsrael: "г. Тель-Авив,<br/> Израиль",
  locationUkraine: "ул. Cоборная 24,<br/>Винница,<br/> Украина 21000",
  locationAustralia: "",
  /////////////////////////*About Us
  "We believe in a sustainable city of the future with unmanned public transport, a centralized control system to ensure traffic jams, accidents and emissions free travel":
    "Мы верим в устойчивый город<br/> будущего с беспилотным<br/> общественным транспортом,<br/> централизованная система<br/> управления обеспечивает<br/> движение без пробок, аварий<br/> и выбросов",
  "We believe in a sustainable city of the future with unmanned public transport, a centralized control system to ensure traffic jams, accidents and emissions free travel mobile":
    "Мы верим в устойчивый город будущего с беспилотным общественным транспортом, централизованная система управления обеспечивает движение без пробок, аварий и выбросов",
  "Our Vision": "Наше видение",
  "We want to live in a world where traffic forecasting, development and management of networks, public transport routes and unmanned vehicles are based on the analysis of historical and online passenger and vehicle route data":
    "Мы хотим жить в мире, где прогнозирование<br/> транспортных потоков, развитие и управление сетями,<br/> маршрутами общественного транспорта и беспилотных<br/> транспортных средств основано на анализе<br/> исторических и онлайн-данных о маршрутах<br/> пассажиров и транспортных средств",
  "We want to live in a world where traffic forecasting, development and management of networks, public transport routes and unmanned vehicles are based on the analysis of historical and online passenger and vehicle route data mobile":
    "Мы хотим жить в мире, где прогнозирование транспортных потоков, развитие и управление сетями, маршрутами общественного транспорта и беспилотных транспортных средств основано на анализе исторических и онлайн-данных о маршрутах пассажиров и транспортных средств",

  "Our competencies": "Наши компетенции",
  "We develop and implement advanced solutions for planning, control and optimization in public transportation":
    "мы разрабатываем и внедряем<br/> передовые решения для планирования,<br/> контроля и оптимизации в сфере<br/> общественных перевозок",
  "We develop and implement advanced solutions for planning, control and optimization in public transportation mobile":
    "мы разрабатываем<br/> и внедряем передовые<br/> решения для планирования,<br/> контроля и оптимизации<br/> в сфере общественных<br/> перевозок",

  "We conduct passenger flow studies to successfully modify and optimize transportation networks":
    "мы проводим исследования<br/> пассажиропотока для успешного изменения<br/> и оптимизации транспортных сетей",
  "We conduct passenger flow studies to successfully modify and optimize transportation networks mobile":
    "мы проводим исследования<br/> пассажиропотока для <br/>успешного изменения<br/> и оптимизации<br/> транспортных сетей",
  "We manufacture and install automatic passenger counting devices":
    "мы производим и устанавливаем<br/> устройства для автоматического<br/> подсчета пассажиров",
  "We manufacture and install automatic passenger counting devices mobile":
    "мы производим<br/> и устанавливаем устройства<br/> для автоматического<br/> подсчета пассажиров",
  "Our experience": "Наш опыт",
  "Online Intranso Smart Counter Device Systems":
    "online систем <br/>Intranso Smart Counter<br/> Device",
  installations: "инсталяций",
  country: "стран",
  "daily number of passengers counted by Intranso systems":
    "ежедневное количество<br/> пасажиров обработаное<br/> системой Intranso",
  "transport operators": "транспортных операторов",
  "countries expertise:Israel, Europe":
    "экспертной компетенции:<br/> Израиль, Европа",
  thanks: "Спасибо!",
  "Demo request sent": "Запрос на демо отправлен",
  "What will happen next": "Что будет дальше:",
  "the manager will contact you": "с вами свяжется менеджер",
  "synchronize convenient time for the demo":
    "синхронизируете удобное время для демо",
  "the manager will conduct a demo familiarization with the system":
    "менеджер проведет демо-ознакомление с системой",
  "you can ask a question about the system": "сможете задать вопрос по системе",
  "get access to a personal demo cabinet":
    "получите доступ в личный демо-кабинет",
  "free for 7 days": "бесплатно на 7 дней",
  understand: "Ок, понятно",
  "send contacts for communication": "Отправить контакты для связи",
  send: "Отправить",
  cancel: "Отменить",
  telephone: "Телефон",
  name: "Имя",
  enter: "Введите",
  next: "Дальше",
  "Just 3 steps and our managers will help you choose the most effective solution for the development of your business":
    "Всего 3 шага и наши менеджеры помогут выбрать наиболее эффективное решение для развития вашего бизнеса",
  "Good time for a demo": "Удобное время для демо",
  "I agree to the terms of the privacy policy and authorize the use of my personal":
    "Я согласен с условиями политики конфиденциальности<br/> и разрешаю использовать мои персональные ...",
  "Send contacts for communication": "Отправить контакты для связи",
  "enter valid email": "Введите корректный email",
  "enter valid name": "Введите корректное имя",
  "enter valid telephone": "Введите корректный номер телефона",
  COMPANY: "КОМПАНИЯ",
  CONTACT_US: "СВЯЖИТЕСЬ С НАМИ",
  SOCIAL_NETWORKS: "СОЦСЕТИ",
  Presentation: "Презетация"
};

export const ua = {
  ////********/Header
  main: "Головна",
  products: "Продукти",
  aboutUs: "Про нас",
  contacts: "Контакти",
  headerHomeTitle:
    "ЗРОБІТЬ СВІЙ ТРАНСПОРТ РОЗУМНИМ З INTRANSO",
  headerHomeTitleLine1: "ЗРОБІТЬ СВІЙ ",
  headerHomeTitleLine2: "ТРАНСПОРТ РОЗУМНИМ",
  headerHomeTitleLine3: "З INTRANSO",
  headerHomeTitleLine4: "",
  headerHomeTitleLine5: "",
  headerHomeTitleLine6: "",
  headerHomeTitleLine7: "",
  headerHomeTitleLine8: "",

  headerHomeTitleMobile:
    "ЗРОБІТЬ СВІЙ ТРАНСПОРТ РОЗУМНИМ ЗА ДОПОМОГОЮ INTRANSO",

  headerHomeTitleMobileLine1: "ЗРОБІТЬ  ",
  headerHomeTitleMobileLine2: "СВІЙ ",
  headerHomeTitleMobileLine3: "ТРАНСПОРТ",
  headerHomeTitleMobileLine4: "РОЗУМНИМ",
  headerHomeTitleMobileLine5: " З INTRANSO",
  headerHomeTitleMobileLine6: "",
  headerHomeTitleMobileLine7: "",
  headerHomeTitleMobileLine8: "",

  headerProductTitle:
    "INTRANSO SMART COUNTER ",
  headerProductTitleLine1: "INTRANSO SMART",
  headerProductTitleLine2: "COUNTER",
  headerProductTitleLine3: "",
  headerProductTitleLine4: "",
  headerProductTitleLine5: "",
  headerProductTitleLine6: "",
  headerProductTitleLine7: "",
  headerProductTitleLine8: "",

  subHeaderProductTitle: "Автоматизований підрахунок<br /> пасажирів у реальному часі",
  subHeaderProductTitleTablet: "Автоматизований <br /> підрахунок пасажирів <br /> у реальному часі",
  headerProductTitleMobile:
    "INTRANSO SMART COUNTER Автоматичний підрахунок пасажирів у реальному часі",
  headerProductTitleMobileLine1: "INTRANSO",
  headerProductTitleMobileLine2: "SMART",
  headerProductTitleMobileLine3: "COUNTER",
  headerProductTitleMobileLine4: "",
  headerProductTitleMobileLine5: "",
  headerProductTitleMobileLine6: "",
  headerProductTitleMobileLine7: "",
  headerProductTitleMobileLine8: "",
  subHeaderProductTitleMobile: "Автоматизований  <br /> підрахунок пасажирів<br /> у реальному часі",

  headerAboutUsTitle:
    "Ізраїльська компанія з 10 річним досвідом роботи на міжнародних ринках",

  headerAboutUsTitleLine1: "Ізраїльська компанія",
  headerAboutUsTitleLine2: "з 10 річним досвідом роботи",
  headerAboutUsTitleLine3: "на міжнародних ринках",
  headerAboutUsTitleLine4: "",
  headerAboutUsTitleLine5: "",
  headerAboutUsTitleLine6: "",
  headerAboutUsTitleLine7: "",

  subHeaderAboutUsTitle: "Наші рішення в концепції \"Місто майбутнього\" допомагають <br /> на основі історичних моделей і даних у режимі онлайн прогнозувати <br />майбутні потоки. Керувати маршрутами, поїздками, розкладами <br />з контролем стану транспорту і транспортних завдань",

  headerAboutUsTitleTablet:
    "Ізраїльська Компанія <br/> спеціалізується на<br/> розробці та впровадженні<br/> інноваційних рішень у сфері<br/> громадських перевезень",
  IsraeliTechnology: "Ізраїльська технологія",
  AIBasedSolutions: "Рішення на основі штучного інтелекту для <br />проєктування, створення та управління підключеною <br />транспортною інфраструктурою",
  AIBasedSolutionsMobile: "Рішення на основі <br />штучного інтелекту для <br />проєктування, створення та <br />управління підключеною <br />транспортною <br />інфраструктурою",

  headerAboutUsTitleMobile: "Ізраїльська компанія з 10 річним досвідом роботи на міжнародних ринках",
  headerAboutUsTitleMobileLine1: "Ізраїльська",
  headerAboutUsTitleMobileLine2: "компанія з 10 річним  ",
  headerAboutUsTitleMobileLine3: "досвідом роботи на ",
  headerAboutUsTitleMobileLine4: "міжнародних ринках",
  headerAboutUsTitleMobileLine5: "",
  headerAboutUsTitleMobileLine6: "",
  headerAboutUsTitleMobileLine7: "",

  subHeaderAboutUsTitleMobile: "Наші рішення в концепції <br />\"Місто майбутнього\" допомагають <br />на основі історичних <br />моделей і даних у режимі <br />онлайн прогнозувати майбутні <br />потоки. Керувати маршрутами, <br />поїздками, розкладами з <br />контролем стану транспорту <br />і транспортних завдань",

  ourPresentation: "Наша презентація",
  ourPresentationTablet: "Презентація",
  demo: "Запросити демо",
  demoTablet: "Демо",
  headerHomeDescription:
    "Планування транспортних засобів і підрахунок пасажирів  <br /> для оптимальної частоти поїздки транспортних засобів",
  headerHomeDescriptionTablet:
  "Планування транспортних засобів і підрахунок пасажирів для оптимальної частоти поїздки транспортних засобів",
  headerHomeDescriptionMobile: "Планування транспортних засобів і підрахунок пасажирів для оптимальної частоти поїздки транспортних засобів",

  headerProductDescription: "перевірена <br />технологія <br />та пристрій",
  headerProductDescriptionMobile: "перевірена технологія <br />та пристрій",
  headerAboutUsDescription:
    "Наш фокус - рекомендаційні послуги,  розробка <br />та управління мережами, маршрутами для <br />громадського транспорту та автомобілів без водія",
  headerAboutUsDescriptionTablet:
    "Наш фокус - рекомендаційні послуги,  <br />розробка та управління мережами,<br/> маршрутами для громадського  <br />транспорту та автомобілів без водія",
  headerAboutUsDescriptionMobile:
    "Наш фокус - рекомендаційні послуги, <br />розробка та управління мережами, <br />маршрутами для громадського транспорту <br />та автомобілів без водія",

  ///**********************/Home Page
  "Our sensors and computer vision-based software are designed to count the number of passengers at any point of the journey":
    "Наші датчики та програмне забезпечення на основі комп'ютерного зору призначені для підрахунку кількості пасажирів у будь-якій точці шляху",
  "Our sensors and computer vision-based software are designed to count the number of passengers at any point of the journey mobile":
    "Наші датчики та <br />програмне забезпечення <br />на основі комп'ютерного зору призначені для підрахунку кількості пасажирів у будь-якій <br />точці шляху",
  "For transport companies, acting on this data improves economics and transparency":
    "Для транспортних компаній робота з цими даними <br />покращує економіку та прозорість",
  "For transport companies, acting on this data improves economics and transparency mobile":
    "Для транспортних компаній <br />робота з цими даними покращує економіку та прозорість",
  "additional profit daily":
    "додаткового прибутку<br /> щодня",
  "additional profit daily mobile":
    "додаткового прибутку<br /> щодня",
  "costs savings monthly":
    "щомісячна економія<br /> витрат",
  "costs savings monthly mobile":
    "щомісячна економія<br /> витрат",
  aboutTheProduct: "Дізнатись про продукт",
  whoUses: "Наше рішення <br />для ",
  whoUsesMobile: "Наше рішення для ",
  privateTransportCompanies: "Приватних транспортних компаній",
  "Private transport companies":
    "Приватні транспортні компанії",
  "Private transport companies mobile":
    "Приватні транспортні компанії",
  "сontrol of passanger flow in real time": "контроль пасажиропотоку в режимі реального часу",
  "predicting flows": "прогнозування транспортних потоків",
  "revenue collection control": "контроль збору виручки",
  "GPS monitoring of vehicles operation": "GPS-моніторинг роботи транспортних засобів",
  "personnel control": "контроль персоналу",
  "online fleet management": "онлайн-керування автопарком",
  "efficient organization of the work of conductor teams": "ефективна організація роботи диригента команди",
  municipalCarriers: "Муніципальних перевізників",
  comparisonTheNumberPayments:
    "порівняння кількості онлайн-платежів<br/> із реальним пасажиропотоком",
  comparisonTheNumberPaymentsMobile:
    "порівняння кількості онлайн-платежів із реальним пасажиропотоком",
  increasingPaymentDiscipline: "підвищення платіжної дисципліни",
  identificationPassengers: "ідентифікація «безквиткових» пасажирів",
  efficientOrganization: "ефективна організація роботи контрольних<br/> бригад",
  efficientOrganizationMobile:
    "ефективна організація роботи контрольних бригад",
  regionalAgencies: "Регіональних агентств <br/>і муніципальних органів влади",
  efficientOrganizationPassengerTransportation:
    "ефективна організація процесу <br/>перевезення пасажирів",
  efficientOrganizationPassengerTransportationMobile:
    "ефективна організація процесу перевезення пасажирів",
  planningRoutesSchedules: "планування маршрутів, розкладів та рейсів",
  optimizationExisting: "оптимізація існуючих маршрутних мереж",
  controlOverTimeliness: "контроль за своєчасністю рейсів",
  optimizationCompensation: "оптимізація компенсації субсидованих тарифів",
  controlPaymentsCompleted:
    "контроль оплат за виконані транспортні<br/> завдання",
  controlPaymentsCompletedMobile:
    "контроль оплат за виконані транспортні завдання",
  dispatchCenterManagement: "управління диспетчерським центром",
  efficientUseTransportInfrastructure:
    "ефективне використання транспортної<br/> інфраструктури",
  efficientUseTransportInfrastructureMobile:
    "ефективне використання <br/>транспортної інфраструктури",
  railwayCompaniesSubways: "Залізничних компаній та метро",
  controlRevenueCollectionCash: "контроль збору виручки при готівковій оплаті",
  effectiveOrganizationTransportNetwork:
    "ефективна організація транспортної мережі <br/> з урахуванням розкладів інших учасників  <br/>пасажирських перевезень",
  effectiveOrganizationTransportNetworkMobile:
    "ефективна організація транспортної мережі  з урахуванням розкладів інших учасників пасажирських перевезень",
  comparisonNumberOnlinePayments:
    "порівняння кількості онлайн-платежів<br/>(квитків) із фактичним пасажиропотоком",
  comparisonNumberOnlinePaymentsMobile:
    "порівняння кількості онлайн-платежів (квитків) із фактичним пасажиропотоком",
  planningOptimizingRollingStock:
    "планування та оптимізація використання<br/> рухомого складу",
  planningOptimizingRollingStockMobile:
    "планування та оптимізація використання рухомого складу",
  transportationChildrenStaff: "Перевезення дітей та персоналу",
  contactManager: "Зв'язатися з менеджером",
  controlSafeTransportation: "контроль безпечного перевезення",
  monitoringTimelinessFlights: "контроль за своєчасністю виконання рейсів",
  planningRouteRealTime:
    "планування кращого маршруту в режимі<br/> реального часу",
  planningRouteRealTimeMobile:
    "планування кращого маршруту в режимі реального часу",
  efficientUseTransport: "ефективне використання транспорту",
  notificationArrival: "повідомлення про прибуття",
  flightBooking: "бронювання рейсу",
  whatMakesIntransoUnique: "У чому унікальність Intranso",
  whatMakesIntransoUniqueMobile: "У чому унікальність<br/> Intranso",
  advancedTechnologyPlanningOptimization:
    "передова технологія для контроля, планування, оптимізації,<br/> управління та звітності на базі <br/>штучного інтелекту (AI)",
  advancedTechnologyPlanningOptimizationMobile:
    "передова технологія<br/> для контроля, планування, <br/> оптимізації, управління та звітності <br/>на базі штучного інтелекту (AI)",
  AIPoweredSystem:
    "Система на базі штучного<br/> інтелекту будує інтелектуальну мережу,<br/> визначає та реалізує оптимальні <br/>транзитні потоки для пасажирів,<br/> що прямують в одному напрямку",
  AIPoweredSystemMobile:
    "Система на базі штучного інтелекту будує інтелектуальну мережу, визначає та реалізує оптимальні транзитні потоки для пасажирів, що прямують в одному напрямку",
  AIPoweredSystemTablet:
    "Система на базі штучного інтелекту будує інтелектуальну мережу, визначає та реалізує оптимальні транзитні потоки для пасажирів, що прямують в одному напрямку",
  engagingWithHistorical:
    "Використання історичних даних та даних<br/> у режимі реального часу про кількість<br/> пасажирів є основою для успішного<br/> створення, керування та експлуатації<br/> динамічної інтелектуальної підключеної <br/>транспортної мережі",
  engagingWithHistoricalTablet: "Використання історичних даних та даних у режимі реального часу про кількість пасажирів є основою для успішного створення, керування та експлуатації динамічної інтелектуальної підключеної транспортної мережі",
  ourSolutionHelpsTransportOperators: "Наше рішення <br/>допомагає транспортним операторам <br/>скоротити експлуатаційні витрати <br/>та динамічно збільшувати прибуток, <br/>контролювати та планувати всі <br/>перевезення",
  ourSolutionHelpsTransportOperatorsTablet: "Наше рішення <br/>допомагає транспортним операторам скоротити експлуатаційні витрати та динамічно збільшувати прибуток, контролювати та планувати всі перевезення",
  ourSolutionHelpsTransportOperatorsMobile:
    "Наше рішення допомагає транспортним операторам скоротити експлуатаційні витрати та динамічно збільшувати прибуток, контролювати та планувати всі перевезення",
  ownDevelopmentSoftware:
    "Програмне <br/>та апаратне забезпечення<br/> власної розробки",
  cloudSecure: "Дані надійно захищені <br/>та зберігаються до 10 років",
  minimizationHumanFactor: "Мінімізація людського<br/> фактора",
  ///****************Footer
  anyQuestions: "Виникли питання?",
  weWillAdviseYouQuestions: "Ми проконсультуємо вас з усіх питань",
  demoAccess: "Демо доступ",
  ourManagerWillContactYou:
    "Наш менеджер зв'яжеться з вами та надасть <br/>доступ до демонстраційного кабінету",
  ourManagerWillContactYouMobile:
    "Наш менеджер зв'яжеться <br/>з вами та надасть доступ<br/> до демонстраційного кабінету",
  wantToSeeDemo: "Хочу подивитись демо",
  leaderExpertMarketSolutionsTransportInfrastructure:
    "лідер та експерт ринку рішень для <br />транспортної інфраструктури в організації громадських перевезень з успішним досвідом розробки та впровадження понад 10 років",
  leaderExpertMarketSolutionsTransportInfrastructureMobile:
    "лідер та експерт ринку <br/> рішень для транспортної інфраструктури в організації громадських перевезень з успішним досвідом розробки та впровадження понад 10 років",
  uploadPresentation: "Завантажити презентацію",
  getDemoAccess: "Отримати демо доступ",
  contact: "Контакти",
  usefulInfo: "Корисне інфо",
  "Us on social networks": "Ми у соцмережах",

  /////***********************Product Page
  "Automatic passengers counting system": "Автоматична система підрахунку пасажирів",
  realTimePassengerTraffic: "Визначення  пасажиропотоку в режимі реального часу <br />на основі системи, запатентованої Intranso",
  realTimePassengerTrafficMobile: "Визначення  пасажиропотоку в режимі реального часу на основі системи, запатентованої Intranso",
  "The device is mounted on all modes of public transport, adapts to all types of doors":
    "Пристрій встановлюється на всі види <br/>міського транспорту, адаптується<br/> під всі типи дверей (від h 1, 2 m)",
  "The device is mounted on all modes of public transport, adapts to all types of doors mobile":
    "Пристрій встановлюється на всі види міського транспорту, адаптується під всі типи дверей (від h 1, 2 m)",
  theIntransoAIDevice: "Пристрій Intranso AI працює на основі технології комп'ютерного зору",
  theIntransoAIDeviceTablet: "Пристрій Intranso AI працює на основі<br /> технології комп'ютерного зору",
  theIntransoAIDeviceMobile: "Пристрій Intranso AI<br /> працює на основі технології комп'ютерного зору",
  PassengerTrafficInReal:"Пасажиропотік в режимі реального часу за маршрутами, зупинками, випусками, автомобілями",
  RoutesAndTrips: "Моніторинг стану маршрутів та поїздок",
  trackingOfTransport: "Відстеження транспорту в режимі реального часу",
  AnticipationOfPossible: "Передбачення можливих проблем",
  ETAEstimated: "ETA (Розрахунковий час прибуття) Повідомлення про затримку автомобіля",
  smartCounters: "Smart counters",
  videoReport: "Video report",
  gpsTracker: "GPS tracker",
  informationSystems: "Information systems",
  softwareApplication: "Software application",
  onlineVideoSurveillance: "Online video surveillance in transport",
  getDemo: "Запросити демо",
  allDataTransferredServer: "Всі дані<br/> передаються <br/>на хмарний сервер",
  "Intranso Pcounter Software receives the data":
    "Дані отримує <br/> Intranso Pcounter <br/> Software",
  "Detailed statistics on passenger flow for each vehicle":
    "Детальна статистика про пасажиропоток <br/>для кожного транспортного засобу:",
  "Detailed statistics on passenger flow for each vehicle mobile":
    "Детальна статистика <br/>про пасажиропоток для кожного<br/> транспортного засобу:",
  route: "маршрута",
  trips: "поїздки",
  stops: "зупинки",
  forAnyGivenPeriod: "за будь-який період часу",
  vehicleLoadAnalysis: "Аналітика завантаження <br/>транспортних засобів",
  webAndMobileVersions: "Веб і мобільна версія",
  itAllAboutIntranso: "це все про INTRANSO  ",
  toolEffectiveDecisionMaking:
    "Інструмент для прийняття<br/> ефективних рішень",
  plan: "Планувати",
  control: "Контролювати",
  optimize: "Оптимізовувати",
  forFleet: "Для автопарків",
  application: "Додаток",
  planning: "Планування",
  controlInstruments: "Інструменти контролю",
  flightMonitoring: "Моніторинг рейсів",
  forFleetManagement: "Для автопарків",
  fleetManagement: "Управління автопарком",
  fleetManagementMobile: "Управління автопарком",
  realTimePassengerFlowInformation: "Інформація про пасажиропоток <br />у режимі реального часу",
  realTimePassengerFlowInformationTablet: "Інформація про пасажиропоток у режимі реального часу",
  realTimePassengerFlowInformationMobile: "Інформація про пасажиропоток у режимі реального часу",
  operationControl: "Операційний контроль",
  operationControlMobile: "Операційний контроль",
  planningScheduling: "Планування та розклади",
  planningSchedulingMobile: "Планування та розклади",
  workOptimization: "Оптимізація роботи",
  workOptimizationMobile: "Оптимізація роботи",
  businessIntelligence: "Бізнес-аналітика",
  businessIntelligenceMobile: "Бізнес-аналітика",
  "Application for the driver": "Додаток для водія",
  from: "від",
  to: "до",
  "Create routes on the map": "Створення маршрутів на карті ",
  "route scheduling": "Планування розкладу",
  "assign employees to transport tasks on planned routes":
    "Призначення виконавців для транспортних<br/> завдань за запланованими маршрутами",
  "assign employees to transport tasks on planned routes mobile":
    "Призначення виконавців для транспортних завдань за<br/> запланованими маршрутами",
  "Export data from other systems": "Експорт даних з інших систем",

  "optimization of routes, flights": "Оптимізація маршрутів, рейсів",
  onlineExecutionChart: "Діаграма виконання онлайн",
  "different colors for completed":
    "Різні кольори для готових,<br/> незавершених та невиконаних рейсів",
  "different colors for completed mobile":
    "Різні кольори для готових,<br/> незавершених<br/> та невиконаних рейсів",

  "incomplete and missed flights": "Невиконані та пропущені рейси",
  "detailed information about each flight":
    "Детальна інформація про кожен рейс",
  toolsForControl: "Инструменты контроля",
  creationStops:
    "Створення зупинок (на карті, за<br/> координатами чи даними експорту)",
  creationStopsMobile:
    "Створення зупинок (на карті, за координатами чи даними експорту)",
  createEditRoutes: "Створення/редагування маршрутів",
  "support for layouts and multi-directional trips":
    "Підтримка схем та різноспрямованих <br/>рейсів (прямі, зворотні, нульові поїздки)",
  "support for layouts and multi-directional trips mobile":
    "Підтримка схем та різноспрямованих рейсів (прямі, зворотні, нульові поїздки)",
  "correcting and arranging stops": "Коригування та впорядкування зупинок",
  "editing routes on the map": "Редагування маршрутів на карті",
  "editing routes on the map mobile": "Редагування маршрутів<br/> на карті",

  "fleet management tools": "Інструменти для керування автопарком",
  "GPS tracking and monitoring": "GPS відстеження та моніторинг",
  "GPS tracking and monitoring mobile": "GPS відстеження<br/> та моніторинг",
  "wide range of reports": "Широкий діапазон звітів",
  "Records of service and repair work":
    "Облік проведення сервісних та ремонтних робіт",
  "Records of service and repair work mobile":
    "Облік проведення сервісних<br/> та ремонтних робіт",
  "maintenance planning": "Планування технічного обслуговування",
  "online control of repair costs": "Контроль витрат на ремонт у режимі онлайн",
  "online control of repair costs mobile":
    "Контроль витрат на ремонт<br/> у режимі онлайн",
  "Employee time tracking": "Моніторинг робочого часу працівників",
  "fuel consumption control": "Контроль витрати пального",
  "information and messages about traffic intervals on the route":
    "Інформація та повідомлення про інтервали<br/> руху транспорту на маршруті",
  "information and messages about traffic intervals on the route mobile":
    "Інформація та повідомлення про інтервали руху транспорту на маршруті",
  "notification of the trip and changes": "повідомлення про поїздку та зміни",
  "notification of the trip and changes mobile":
    "повідомлення про поїздку та зміни",
  "detailed information about the route with timetable":
    "Детальна інформація про маршрут<br/> за розкладом руху",
  "detailed information about the route with timetable mobile":
    "Детальна інформація про маршрут за розкладом руху",
  "comparison of the actual trip with the schedule and estimated time of arrival":
    "Порівняння фактичної поїздки з розкладом<br/> та розрахунковим часом прибуття",
  "comparison of the actual trip with the schedule and estimated time of arrival tablet":
    "Порівняння фактичної поїздки з розкладом та розрахунковим<br/> часом прибуття",
  "comparison of the actual trip with the schedule and estimated time of arrival mobile":
    "Порівняння фактичної поїздки з розкладом<br/> та розрахунковим часом прибуття",
  integrationWithElectronicPayment:
    "Інтеграція з електронними системами<br/> оплат дає можливість в режимі<br/> реального часу контролювати <br/>оплати (валідації) за проїзд",
  integrationWithElectronicPaymentMobile:
    "Інтеграція з електронними<br/> системами оплат дає можливість в<br/>  режимі реального часу<br/>  контролювати оплати<br/> (валідації) за проїзд",
  IntransoProductsImproveRevenue:
    "Продукти Intranso покращують збір доходів транспортних компаній, знижують витрати <br />та підвищують прозорість <br />та керованість бізнесу",
  IntransoProductsImproveRevenueMobile:
    "Продукти Intranso покращують збір доходів транспортних компаній, знижують витрати та підвищують прозорість та керованість бізнесу",
  weJustOfferSystemForCountingPassengers:
    "Ми пропонуємо не просто систему для<br/> підрахунку пасажирів – ми пропонуємо <p>комплексне вирішення</p> завдань клієнтів<br/> з використанням інноваційних<br/> інформаційних технологій",
  weJustOfferSystemForCountingPassengersTablet:
    "Ми пропонуємо не просто систему для<br/> підрахунку пасажирів – ми пропонуємо<br/> <p>комплексне вирішення</p> завдань клієнтів<br/> з використанням інноваційних<br/> інформаційних технологій",
  weJustOfferSystemForCountingPassengersMobile:
    "Ми пропонуємо не <br/>просто систему для підрахунку пасажирів – ми пропонуємо <p>комплексне вирішення</p> завдань клієнтів з використанням інноваційних інформаційних <br/>технологій",
  centralizedManagementOfThePublicTransport:
    "ЦЕНТРАЛІЗОВАНЕ УПРАВЛІННЯ ІНФРАСТРУКТУРОЮ ГРОМАДСЬКОГО ТРАНСПОРТУ ЧЕРЕЗ РОЗУМНІ МЕРЕЖІ",
  centralizedManagementOfThePublicTransportMobile:
    "ЦЕНТРАЛІЗОВАНЕ УПРАВЛІННЯ ІНФРАСТРУКТУРОЮ ГРОМАДСЬКОГО ТРАНСПОРТУ ЧЕРЕЗ РОЗУМНІ МЕРЕЖІ",
  implementationEffect: "Ефект<br/> від впровадження",
  implementationEffectMobile: "Ефект від впровадження",
  realTimePassengerFlowByRoutes:
    "Пасажиропотік в режимі реального часу за маршрутами, зупинками, випусками, транспортними засобами",
  realTimePassengerFlowByRoutesTablet:
    "Пасажиропотік в режимі реального часу<br/> за маршрутами, зупинками, випусками,<br/> транспортними засобами",
  realTimePassengerFlowByRoutesMobile:
    "Пасажиропотік в режимі реального часу за маршрутами, зупинками, випусками, транспортними засобами",
  convenientPlanningTransport: "Зручне планування транспортної роботи",
  convenientPlanningTransportTablet: "Зручне планування транспортної<br/> роботи",
  incomeIncreaseTo30: "Збільшення доходу до 30%",
  OperatingEfficiencyIncrease: "Зменшення операційних та експлуатаційних витрат до 20%",
  qualityControlTraffic:
    "Якісний контроль показника завантаженості транспортного засобу",
  qualityControlTrafficMobile:
    "Якісний контроль<br/> показника завантаженості транспортного засобу",
  successfulPlanningOptimalNumberVehicles:
    "Успішне планування оптимальної<br/> кількості транспорту, що виходить<br/> на маршрут",
  successfulPlanningOptimalNumberVehiclesTablet: "Успішне планування оптимальної<br/> кількості транспорту, що виходить <br/>на маршрут",
  successfulPlanningOptimalNumberVehiclesMobile: "Успішне планування оптимальної кількості транспорту, що виходить на маршрут",

  complianceWithNormsPermissibleNumber:
    "Дотримання норм допустимої кількості пасажирів у салоні автомобіля",
  complianceWithNormsPermissibleNumberMobile:
    "Дотримання норм допустимої кількості пасажирів у салоні автомобіля",
  optimizationWorkControllers: "Оптимізація роботи контролерів",
  benefits: "Переваги",
  theWayToTheCityOfTheFuture: "Шлях до “Місто майбутнього” - концепція <br />Smart City - реалізація",
  theWayToTheCityOfTheFutureMobile: "Шлях до “Місто майбутнього” <br />- концепція Smart City -<br /> реалізація",
  TheConceptOfModularProduct: "Концепція модульної розробки<br/> продукту дозволяє модульне підключення <br/>послуг залежно від ваших потреб",
  TheConceptOfModularProductTablet: "Концепція модульної розробки продукту дозволяє модульне підключення послуг залежно від ваших потреб",
  TheConceptOfModularProductMobile: "Концепція модульної розробки продукту дозволяє модульне підключення послуг залежно від ваших потреб",
  "Increase revenue by controlling revenue collection by up to 30%":
    "Збільшення доходу за рахунок контролю<br/> збору виручки <p>на 30%</p>",
  "Increase revenue by controlling revenue collection by up to 30% mobile":
    "Збільшення доходу<br/> за рахунок контролю<br/> збору виручки <p>на 30%</p>",
  schedulingAndMatchingPassengers:
    "Планування та підрахунок пасажирів<br/> для оптимальної частоти транспортних <br/>засобів у режимі реального часу",
  schedulingAndMatchingPassengersTablet:
    "Планування та підрахунок<br/> пасажирів для оптимальної<br/> частоти транспортних засобів<br/> у режимі реального часу",
  schedulingAndMatchingPassengersMobile:
    "Планування та підрахунок <br/>пасажирів для оптимальної <br/>частоти транспортних засобів<br/> у режимі реального часу",
  "Increasing competitiveness through the introduction of information technology":
    "Підвищення конкуренції за рахунок<br/> впровадження інформаційних<br/> технологій",
  "Increasing competitiveness through the introduction of information technology tablet":
    "Підвищення конкуренції <br/>за рахунок впровадження<br/> інформаційних технологій",
  "Increasing competitiveness through the introduction of information technology mobile":
    "Підвищення конкуренції за рахунок впровадження інформаційних технологій",
  paybackPeriod:
    "Окупність покупки систем <br/><p> від 3-х до 5-ти місяців </p>",
  paybackPeriodMobile:
    "Окупність покупки систем<br/> <p> від 3-х до 5-ти місяців </p>",
  UsingTheServiceIsSimple: "Користуватися послугою просто<br/> і легко для працівників будь-якого рівня відповідальності",
  UsingTheServiceIsSimpleMobile: "Користуватися послугою<br/> просто і легко для працівників<br/> будь-якого рівня <br/>відповідальності",
  AutomaticCountingOfPassengers:
    "Автоматичний підрахунок пасажирів<br/> не залежить від людського фактора<br/> та мінімізує його",
  AutomaticCountingOfPassengersTablet:
    "Автоматичний підрахунок <br/>пасажирів не залежить<br/> від людського фактора та<br/> мінімізує його",
  AutomaticCountingOfPassengersMobile:
    "Автоматичний підрахунок пасажирів не залежить від людського фактора <br/>та мінімізує його",
  ///*******************************************/Contact Us
  "Contact us": "Зв'язатися з нами",
  "Contact us mobile": "Зв'язатися<br/> з нами",
  "Our managers will help you choose the most effective solution for the development of your business":
    "Наші менеджери допоможуть вам підібрати<br/> найбільш ефективне рішеннядля розвитку<br/>  вашого бізнесу",
  "Our managers will help you choose the most effective solution for the development of your business mobile":
    "Наші менеджери допоможуть вам підібрати найбільш ефективне рішеннядля розвитку  вашого бізнесу",
  Israel: "Ізраїль",
  Ukraine: "Україна",
  Australia: "Австралія",
  locationIsrael: "м. Тель-Авів,<br/> Ізраїль",
  locationUkraine: "вул. Cоборна 24,<br/>Вінниця,<br/> Україна 21000",
  locationAustralia: "",
  /////////////////////////*About Us
  "We believe in a sustainable city of the future with unmanned public transport, a centralized control system to ensure traffic jams, accidents and emissions free travel":
    "Ми віримо у стійке місто<br/> майбутнього з безпілотним<br/> громадським транспортом,<br/> централізована система<br/> управління забезпечує<br/> рух без пробок, аварій<br/> та викидів",
  "We believe in a sustainable city of the future with unmanned public transport, a centralized control system to ensure traffic jams, accidents and emissions free travel mobile":
    "Ми віримо у стійке місто майбутнього з безпілотним громадським транспортом, централізована система управління забезпечує рух без пробок, аварій та викидів",

  "Our Vision": "Наше бачення",
  "We want to live in a world where traffic forecasting, development and management of networks, public transport routes and unmanned vehicles are based on the analysis of historical and online passenger and vehicle route data":
    "Ми хочемо жити у світі, де прогнозування<br/> транспортних потоків, розвиток та управління мережами,<br/> маршрутами громадського транспорту та безпілотних<br/> транспортних засобів засноване на аналізі історичних<br/> та онлайн-даних про маршрути  пасажирів та<br/> транспортних засобів",
  "We want to live in a world where traffic forecasting, development and management of networks, public transport routes and unmanned vehicles are based on the analysis of historical and online passenger and vehicle route data mobile":
    "Ми хочемо жити у світі, де прогнозування транспортних потоків, розвиток та управління мережами, маршрутами громадського транспорту та безпілотних транспортних засобів засноване на аналізі історичних та онлайн-даних про маршрути  пасажирів та транспортних засобів",

  "Our competencies": "Наші компетенції",
  "We develop and implement advanced solutions for planning, control and optimization in public transportation":
    "ми розробляємо та впроваджуємо<br/> передові рішення для планування,<br/> контролю та оптимізації у сфері<br/> громадських перевезень",
  "We develop and implement advanced solutions for planning, control and optimization in public transportation mobile":
    "ми розробляємо<br/> та впроваджуємо передові<br/> рішення для планування,<br/> контролю та оптимізації<br/> у сфері громадських<br/> перевезень",

  "We conduct passenger flow studies to successfully modify and optimize transportation networks":
    "ми проводимо дослідження<br/> пасажиропотоку для успішної зміни<br/> та оптимізації транспортних мереж",
  "We conduct passenger flow studies to successfully modify and optimize transportation networks mobile":
    "ми проводимо дослідження пасажиропотоку для успішної зміни та оптимізації транспортних мереж",
  "We manufacture and install automatic passenger counting devices":
    "ми виробляємо та встановлюємо<br/> пристрої для автоматичного<br/> підрахунку пасажирів",
  "We manufacture and install automatic passenger counting devices mobile":
    "ми виробляємо<br/> та встановлюємо пристрої<br/> для автоматичного<br/> підрахунку пасажирів",
  "Our experience": "Наш досвід",
  "Online Intranso Smart Counter Device Systems":
    "online систем <br/>Intranso Smart Counter<br/> Device",
  installations: "інсталяцій",
  country: "країн",
  "daily number of passengers counted by Intranso systems":
    "щоденна кількість<br/> пасажирів оброблена<br/> системою Intranso",
  "transport operators": "транспортних операторів",
  "countries expertise:Israel, Europe":
    "експертної компетенції:<br/> Ізраїль, Європа",

  ///****/
  thanks: "Дякую!",
  "Demo request sent": "Запит на демо надіслано",
  "What will happen next": "Що буде далі:",
  "the manager will contact you": "з вами зв'яжеться менеджер",
  "synchronize convenient time for the demo":
    "синхронізуєте зручний час для демо",
  "the manager will conduct a demo familiarization with the system":
    "менеджер проведе демо-ознайомлення із системою",
  "you can ask a question about the system":
    "зможете поставити запитання щодо системи",
  "get access to a personal demo cabinet":
    "отримаєте доступ в особистий демо-кабінет",
  "free for 7 days": "безкоштовно на 7 днів",
  understand: "Добре, зрозуміло",
  "send contacts for communication": "Надіслати контакти для зв'язку",
  send: "Надіслати",
  cancel: "Скасувати",
  telephone: "Телефон",
  name: "Ім'я",
  enter: "Введіть",
  next: "Далі",
  "Good time for a demo": "Зручний час для демонстрації",
  "I agree to the terms of the privacy policy and authorize the use of my personal":
    "Я погоджуюсь з умовами політики конфіденційності <br/>та дозволяю використовувати мої персональні ...",
  "Send contacts for communication": "Надіслати контакти для зв'язку",
  "Just 3 steps and our managers will help you choose the most effective solution for the development of your business":
    "Всього 3 кроки і наші менеджери допоможуть підібрати найефективніше рішення для розвитку вашого бізнесу",
  "enter valid email": "Введіть коректний email",
  "enter valid name": "Введіть коректне ім'я",
  "enter valid telephone": "Введіть коректний номер телефону",
  COMPANY: "КОМПАНІЯ",
  CONTACT_US: "ЗВ'ЯЖІТЬСЯ З НАМИ",
  SOCIAL_NETWORKS: "СОЦМЕРЕЖІ",
  Presentation: "Презентація"
};

export const en = {
  ///**********/Header
  main: "Main",
  products: "Products",
  aboutUs: "About Us",
  contacts: "Contacts",
  headerHomeTitle:
    "MAKE YOUR TRANSPORT SMARTER WITH INTRANSO",
  headerHomeTitleLine1: "MAKE YOUR",
  headerHomeTitleLine2: "TRANSPORT SMARTER",
  headerHomeTitleLine3: "WITH INTRANSO",
  headerHomeTitleLine4: "",
  headerHomeTitleLine5: "",
  headerHomeTitleLine6: "",
  headerHomeTitleLine7: "",

  headerHomeTitleMobile:
  "MAKE YOUR TRANSPORT SMARTER WITH INTRANSO",

  headerHomeTitleMobileLine1: "MAKE YOUR",
  headerHomeTitleMobileLine2: "TRANSPORT",
  headerHomeTitleMobileLine3: "SMARTER",
  headerHomeTitleMobileLine4: "WITH",
  headerHomeTitleMobileLine5: "INTRANSO",
  headerHomeTitleMobileLine6: "",
  headerHomeTitleMobileLine7: "",

  headerProductTitle: "INTRANSO SMART COUNTER",
  headerProductTitleLine1: "INTRANSO SMART ",
  headerProductTitleLine2: "COUNTER",
  headerProductTitleLine3: "",
  headerProductTitleLine4: "",
  headerProductTitleLine5: "",
  headerProductTitleLine6: "",
  headerProductTitleLine7: "",
  subHeaderProductTitle: "Automated passengers  <br />counter in real-time",
  subHeaderProductTitleTablet: "Automated passengers  <br />counter in real-time",

  headerProductTitleMobile: "INTRANSO SMART COUNTER",
  headerProductTitleMobileLine1: "INTRANSO",
  headerProductTitleMobileLine2: "SMART",
  headerProductTitleMobileLine3: "COUNTER",
  headerProductTitleMobileLine4: "",
  headerProductTitleMobileLine5: "",
  headerProductTitleMobileLine6: "",
  headerProductTitleMobileLine7: "",
  subHeaderProductTitleMobile: "Automated passengers <br />counter in real-time",

  headerAboutUsTitle:
    "Israeli company with 10 years of experience in global markets",

  headerAboutUsTitleLine1: "Israeli company",
  headerAboutUsTitleLine2: " with 10  years of experience",
  headerAboutUsTitleLine3: "in global markets",
  headerAboutUsTitleLine4: "",
  headerAboutUsTitleLine5: "",
  headerAboutUsTitleLine6: "",
  headerAboutUsTitleLine7: "",

  subHeaderAboutUsTitle: "Our solutions in the \"City of the Future\" concept help to<br /> predict future flows based on historical models and online <br />data. Manage routes, trips, schedules with control of traffic <br />status and transportation tasks",

  headerAboutUsTitleMobile:
    "Israeli company with 10 years of experience in global markets",

  headerAboutUsTitleMobileLine1: "Israeli company with",
  headerAboutUsTitleMobileLine2: " 10 years of experience",
  headerAboutUsTitleMobileLine3: "  in global markets",
  headerAboutUsTitleMobileLine4: "",
  headerAboutUsTitleMobileLine5: "",
  headerAboutUsTitleMobileLine6: "",
  headerAboutUsTitleMobileLine7: "",
  headerAboutUsTitleMobileLine8: "",

  subHeaderAboutUsTitleMobile: "Our solutions in the \"City of the<br />Future\" concept help to predict<br />future flows based on historical<br />models and online data. Manage<br />routes, trips, schedules with <br />control of traffic status and<br /> transportation tasks",

  headerAboutUsTitleTablet:
    "An Israeli company specializing <br/>in the development<br/> and implementation of innovative<br/> solutions in the field of public<br/> transportation",
  IsraeliTechnology: "Israeli technology",
  AIBasedSolutions: "AI-Based solutions for designing, building and <br />managing connected transportation infrastructure",
  AIBasedSolutionsMobile: "AI-Based solutions for <br />designing, building and <br />managing connected <br />transportation <br />infrastructure",
  ourPresentation: "Our presentation",
  ourPresentationTablet: "Presentation",
  demo: "Request a demo",
  demoTablet: "Demo",
  headerHomeDescription:
    "Vehicle planning and passenger counting <br />for the optimal frequency of vehicles",
  headerHomeDescriptionTablet:
    "Vehicle planning and passenger counting <br />for the optimal frequency of vehicles",
  headerHomeDescriptionMobile:
    "Vehicle planning and passenger<br /> counting for the optimal<br /> frequency of vehicles",
  headerProductDescription: "proven technology<br /> and device",
  headerProductDescriptionMobile: "proven technology<br /> and device",
  headerAboutUsDescription:
    "Our focus is recommendation services, development<br/> and management of networks,  routes for public<br/> transport and driverless vehicles",
  headerAboutUsDescriptionTablet:
    "Our focus is recommendation services, <br/>development and management of <br/>networks, routes for public transport <br/>and driverless vehicles",
  headerAboutUsDescriptionMobile: "Our focus is recommendation servicess, <br/>development and management of networks,<br/> routes for public transport and driverless<br/> vehicles",
  /////**********************/Home Page
  "Our sensors and computer vision-based software are designed to count the number of passengers at any point of the journey":
    "Our sensors and computer vision-based software are designed to count the number of passengers at any point of the journey",
  "Our sensors and computer vision-based software are designed to count the number of passengers at any point of the journey mobile":
    "Our sensors and <br />computer vision-based<br /> software are designed <br />to count the number of <br />passengers at any point <br />of the journey",
  "For transport companies, acting on this data improves economics and transparency":
    "For transport companies, acting on this <br />data improves economics and transparency",
  "For transport companies, acting on this data improves economics and transparency mobile":
    "For transport companies, <br />acting on this data improves <br />economics and <br />transparency",

  "additional profit daily":
    "additional profit<br /> daily",
  "additional profit daily mobile":
    "additional profit daily",
  "costs savings monthly":
    "costs savings<br /> monthly",
  "costs savings monthly mobile":
    "costs savings monthly",
  aboutTheProduct: "About the product",
  whoUses: "Our<br /> solution for",
  whoUsesMobile: "Our solution for",
  privateTransportCompanies: "Private transport companies",
  "Private transport companies": "Private transport companies",
  "Private transport companies mobile":
    "Private transport companies",
  "сontrol of passanger flow in real time": "сontrol of passanger flow in real time",
  "predicting flows": "predicting flows",
  "revenue collection control": "revenue collection control",
  "GPS monitoring of vehicles operation": "GPS monitoring of vehicles operation",
  "personnel control": "personnel control",
  "online fleet management": "online fleet management",
  "efficient organization of the work of conductor teams": "efficient organization of the work of conductor teams",
  municipalCarriers: "Municipal carriers",
  comparisonTheNumberPayments:
    "comparison of the number of online<br/> payments with real passenger traffic",
  comparisonTheNumberPaymentsMobile:
    "comparison of the number of online payments with real passenger traffic",
  increasingPaymentDiscipline: "increasing payment discipline",
  identificationPassengers: 'identification of "stowaway" passengers',
  efficientOrganization:
    "efficient organization of the work of control<br/> teams",
  efficientOrganizationMobile:
    "efficient organization of the work of control teams",
  regionalAgencies: "Regional agencies and municipal<br/> authorities",
  contactManager: "Contact manager",
  efficientOrganizationPassengerTransportation:
    "efficient organization of the passenger<br/> transportation process",
  efficientOrganizationPassengerTransportationMobile:
    "efficient organization of the passenger transportation process",
  planningRoutesSchedules: "planning of routes, schedules and flights",
  optimizationExisting: "optimization of existing route networks",
  controlOverTimeliness: "control over timeliness of flights",
  optimizationCompensation: "optimization of compensation for subsidized fares",
  controlPaymentsCompleted:
    "control of payments for completed transportation<br/> tasks",
  controlPaymentsCompletedMobile:
    "control of payments for completed transportation tasks",
  dispatchCenterManagement: "dispatch center management",
  efficientUseTransportInfrastructure:
    "efficient use of transport infrastructure",
  efficientUseTransportInfrastructureMobile:
    "efficient use of transport infrastructure",
  railwayCompaniesSubways: "Railway companies and subways",
  controlRevenueCollectionCash: "control of revenue collection at cash payment",
  effectiveOrganizationTransportNetwork:
    "effective organization of the transport network, <br/> taking into account the schedules of other <br/> participants in passenger traffic",
  effectiveOrganizationTransportNetworkMobile:
    "effective organization of the transport network, taking into account the schedules of other  participants in passenger traffic",
  comparisonNumberOnlinePayments:
    "comparison of the number of online payments<br/> (tickets) with the actual passenger traffic",
  comparisonNumberOnlinePaymentsMobile:
    "comparison of the number of online payments (tickets) with the actual passenger traffic",
  planningOptimizingRollingStock:
    "planning and optimizing the use of<br/> rolling stock",
  planningOptimizingRollingStockMobile:
    "planning and optimizing the use of rolling stock",
  transportationChildrenStaff: "Transportation of children and staff",
  controlSafeTransportation: "control of safe transportation",
  monitoringTimelinessFlights: "monitoring the timeliness of flights",
  planningRouteRealTime: "planning the best route in real time",
  planningRouteRealTimeMobile: "planning the best route in real time",
  efficientUseTransport: "efficient use of transport",
  notificationArrival: "notification of arrival",
  flightBooking: "flight booking",
  whatMakesIntransoUnique: "What makes Intranso unique",
  whatMakesIntransoUniqueMobile: "What makes<br/> Intranso unique",
  advancedTechnologyPlanningOptimization:
    "Advanced technology for control, planning, optimization,<br/> management and reporting based on <br/>artificial intelligence (AI)",
  advancedTechnologyPlanningOptimizationMobile:
    "Advanced technology<br/> for control, planning, optimization,<br/>management and reporting based on <br/>artificial intelligence (AI)",
  AIPoweredSystem:
    "The AI-powered system builds smart network, identifies and executes<br/> optimal transit flows for passengers, travelling in the same direction",
  AIPoweredSystemMobile:
    "The AI-powered system builds smart network, identifies and executes optimal transit flows for passengers, travelling in the same direction",
  AIPoweredSystemTablet:
    "The AI-powered system builds smart network, identifies and executes optimal transit flows<br/> for passengers, travelling in the same direction",
  engagingWithHistorical:
    "Engaging with historical and real-time<br /> data on the numbers of assengers is the<br /> basis for successfully creating, managing<br /> and operating a dynamic smart connected<br /> transportation network",
  engagingWithHistoricalTablet:
    "Engaging with historical and <br/>real-time data on the numbers<br/> of passengers is the basis for <br/>successfully creating, managing <br/>and operating a dynamic smart <br/>connected transportation <br/>network",
  ourSolutionHelpsTransportOperators: "Our solution helps transport operators<br/> to reduce operating costs and increase <br/>profits by dynamically planning and<br/> monitoring all transportation",
  ourSolutionHelpsTransportOperatorsTablet: "Our solution helps transport operators to reduce operating costs and increase profits by dynamically planning and monitoring all transportation",
  ourSolutionHelpsTransportOperatorsMobile:
    "Our solution helps transport operators to reduce operating costs and increase profits by dynamically planning and monitoring all transportation",
  ownDevelopmentSoftware: "Own development<br/> software and hardware ",
  cloudSecure: "Data is securely protected <br/>and stored for up to 10 years ",
  minimizationHumanFactor: "Minimization of human<br/> factor",
  ///****************Footer
  anyQuestions: "Any questions?",
  weWillAdviseYouQuestions: "We will advise you on all questions",
  demoAccess: "Demo access",
  ourManagerWillContactYou:
    "Our manager will contact you and give you <br/>access to the demo room",
  ourManagerWillContactYouMobile:
    "Our manager will contact you and give you access to the demo room",
  wantToSeeDemo: "I want to see a demo",
  leaderExpertMarketSolutionsTransportInfrastructure:
    "The leader and expert in the market of solutions for transport infrastructure in the organization of public transportation with more than 10 years of successful experience in development and implementation",
  leaderExpertMarketSolutionsTransportInfrastructureMobile:
    "The leader and expert in the market of solutions for transport infrastructure in the organization of public transportation with more than 10 years of successful experience in development and implementation",

  uploadPresentation: "Download presentation",
  getDemoAccess: "Get demo access",
  contact: "Contact",
  usefulInfo: "Useful info",
  "Us on social networks": "Us on social networks",
  /////***********************Product Page
  "Automatic passengers counting system":
    "Automatic passengers counting system",
  realTimePassengerTraffic: "Real-time passenger traffic accounting based on <br/>a system patented by<br /> Intranso",
  realTimePassengerTrafficMobile: "Real-time passenger <br/>traffic accounting <br/>based on a system <br/>patented by Intranso",
  "The device is mounted on all modes of public transport, adapts to all types of doors":
    "The device is mounted on all modes of <br/> public transport, adapts to all types of<br/> doors (от h 1, 2 m)",
  "The device is mounted on all modes of public transport, adapts to all types of doors mobile":
    "The device is mounted<br/> on all modes of  public<br/> transport, adapts to all types<br/> of doors (от h 1, 2 m)",
  theIntransoAIDevice: "The Intranso AI device is based <br />on computer vision technology",
  theIntransoAIDeviceTablet: "The Intranso AI device is based <br />on computer vision technology",
  theIntransoAIDeviceMobile: "The Intranso AI device <br />is based on computer<br /> vision technology",
  PassengerTrafficInReal:"Passenger traffic in real time on routes, stops, releases, vehicles",
  RoutesAndTrips: "Routes and trips status monitoring",
  trackingOfTransport: "Tracking of transport in real time",
  AnticipationOfPossible: "Anticipation of possible problems",
  ETAEstimated: "ETA (Estimated Time of Arrival) Vehicle Delay Notification",
  smartCounters: "Smart counters",
  videoReport: "Video report",
  gpsTracker: "GPS trcker",
  informationSystems: "Information systems",
  softwareApplication: "Software application",
  onlineVideoSurveillance: "Online video surveillance in transport",
  getDemo: "Get demo",
  allDataTransferredServer:
    "All data is<br/> transferred to the<br/> cloud server",
  "Intranso Pcounter Software receives the data":
    "Intranso Pcounter <br/>Software receives<br/> the data",
  "Detailed statistics on passenger flow for each vehicle":
    "Detailed statistics on passenger<br/> flow for each vehicle:",
  "Detailed statistics on passenger flow for each vehicle mobile":
    "Detailed statistics on passenger<br/> flow for each vehicle:",
  route: "route",
  trips: "trips",
  stops: "stops",
  forAnyGivenPeriod: "for any given period of time",
  vehicleLoadAnalysis: "Vehicle load analysis",
  webAndMobileVersions: "Web and mobile versions",
  itAllAboutIntranso: "it's all about INTRANSO ",
  toolEffectiveDecisionMaking: "A tool for effective decision <br/> making",
  ///*************Tabs
  plan: "Plan",
  control: "Control",
  optimize: "Optimize",
  forFleet: "For fleet",
  application: "Application",
  planning: "Planning",
  controlInstruments: "Control instruments",
  flightMonitoring: "Flight monitoring",
  forFleetManagement: "Fleet management",
  fleetManagement: "Fleet management",
  fleetManagementMobile: "Fleet management",
  realTimePassengerFlowInformation: "Real-time passenger flow information",
  realTimePassengerFlowInformationTablet: "Real-time passenger flow information",
  realTimePassengerFlowInformationMobile: "Real-time passenger flow information",
  operationControl: "Operation control",
  operationControlMobile: "Operation control",
  planningScheduling: "Planning & Scheduling",
  planningSchedulingMobile: "Planning & Scheduling",
  workOptimization: "Work optimization",
  workOptimizationMobile: "Work optimization",
  businessIntelligence: "Business intelligence",
  businessIntelligenceMobile: "Business intelligence",
  "Application for the driver": "Application for  the driver",

  "Create routes on the map": "Create routes on the map",
  "route scheduling": "Route scheduling",
  "assign employees to transport tasks on planned routes":
    "Assign employees to transport tasks<br/> on planned routes",
  "assign employees to transport tasks on planned routes mobile":
    "Assign employees to transport tasks on planned routes",
  "Export data from other systems": "Export data from other systems",

  "optimization of routes, flights": "optimization of routes, flights",
  onlineExecutionChart: "online execution chart",
  "different colors for completed":
    "different colors for completed, incomplete<br/> and missed fights",
  "different colors for completed mobile":
    "different colors for completed, incomplete and missed fights",
  "incomplete and missed flights": "incomplete and missed flights",
  "detailed information about each flight":
    "detailed information about each flight",
  toolsForControl: "tools for control",
  creationStops:
    "Creation of stops (on the map. by<br/> coordinates or export data)",
  creationStopsMobile:
    "Creation of stops (on the map. by coordinates or export data)",
  createEditRoutes: "Create/edit routes",
  "support for layouts and multi-directional trips":
    "Support for layouts and multi-directional<br/> trips (direct/reverse/zero trips)",
  "support for layouts and multi-directional trips mobile":
    "Support for layouts and multi-directional trips (direct/reverse/zero trips)",
  "correcting and arranging stops": "Correcting and arranging stops",
  "editing routes on the map": "Editing routes on the map",
  "editing routes on the map mobile": "Editing routes on the map",
  from: "from",
  to: "to",
  "fleet management tools": "fleet management tools",
  "GPS tracking and monitoring": "GPS tracking and monitoring",
  "GPS tracking and monitoring mobile": "GPS tracking and monitoring",
  "wide range of reports": "wide range of reports",
  "Records of service and repair work": "Records of service and repair work",
  "Records of service and repair work mobile":
    "Records of service and repair work",
  "maintenance planning": "maintenance planning",
  "online control of repair costs": "online control of repair costs",
  "online control of repair costs mobile": "online control of repair costs",
  "Employee time tracking": "Employee time tracking",
  "fuel consumption control": "fuel consumption control",
  "information and messages about traffic intervals on the route":
    "information and messages about traffic<br/> intervals on the route",
  "information and messages about traffic intervals on the route mobile":
    "information and messages about traffic intervals on the route",
  "notification of the trip and changes":
    "notification of the trip and changes",
  "notification of the trip and changes mobile":
    "notification of the trip and changes",
  "detailed information about the route with timetable":
    "detailed information about the route<br/> with timetable",
  "detailed information about the route with timetable mobile":
    "detailed information about the route with timetable",
  "comparison of the actual trip with the schedule and estimated time of arrival":
    "comparison of the actual trip with the<br/> schedule and estimated time of arrival",
  "comparison of the actual trip with the schedule and estimated time of arrival tablet":
    "comparison of the actual trip with the<br/> schedule and estimated time of arrival",
  "comparison of the actual trip with the schedule and estimated time of arrival mobile":
    "comparison of the actual trip with the schedule and estimated time of arrival",
  integrationWithElectronicPayment:
    "Integration with electronic payment systems<br/> enables real-time monitoring of payments<br/> (validations) for the period",
  integrationWithElectronicPaymentMobile:
    "Integration with electronic payment systems<br/> enables real-time monitoring of payments<br/> (validations) for the period",
  IntransoProductsImproveRevenue:
    "Intranso products improve revenue collection of transport companies, reduce costs and improve transparency and manageability of business",
  IntransoProductsImproveRevenueMobile:
    "Intranso products <br />improve revenue collection of transport companies, reduce costs and improve transparency and manageability<br />of business",
  weJustOfferSystemForCountingPassengers:
    "We don't just offer a system for counting<br/> passengers - we offer a <p>comprehensive solution </p> to<br/> customers' problems using innovative information<br/> technologies",
  weJustOfferSystemForCountingPassengersTablet:
    "We don't just offer a system for counting<br/> passengers - we offer a <p>comprehensive solution </p> to<br/> customers' problems using innovative <br/>information technologies",
  weJustOfferSystemForCountingPassengersMobile:
    "We don't just offer a system for counting passengers - we offer a <p>comprehensive solution </p> to customers' problems using innovative information technologies",
  centralizedManagementOfThePublicTransport:
    "Centralized management of the public transport infrastructure via smart network",
  centralizedManagementOfThePublicTransportMobile:
    "Centralized management of the public transport infrastructure via smart network",
  implementationEffect: "Implementation effect",
  implementationEffectMobile: "Implementation effect",
  realTimePassengerFlowByRoutes:
    "Real-time passenger flow by routes, stops, departures, vehicles",
  realTimePassengerFlowByRoutesTablet:
    "Real-time passenger flow by routes,<br/> stops, departures, vehicles",
  realTimePassengerFlowByRoutesMobile:
    "Real-time passenger flow by routes, stops, departures, vehicles",
  convenientPlanningTransport: "Convenient planning of transport work",
  convenientPlanningTransportTablet: "Convenient planning of<br/> transport work",
  incomeIncreaseTo30:"Income increase up to 30%",
  OperatingEfficiencyIncrease: "Operating efficiency increase, operating costs decrease by 20%",
  qualityControlTraffic: "Quality control of the traffic load indicator",
  qualityControlTrafficMobile: "Quality control of the traffic load indicator",
  successfulPlanningOptimalNumberVehicles:
    "Successful planning of the optimal<br/> number of vehicles on the route",
  successfulPlanningOptimalNumberVehiclesTablet: "Successful planning of the optimal<br/> number of vehicles on the route",
  successfulPlanningOptimalNumberVehiclesMobile: "Successful planning of the optimal number of vehicles on the route",
  complianceWithNormsPermissibleNumber:
    "Compliance with the norms of the<br/> permissible number of passengers<br/> in the cabin",
  complianceWithNormsPermissibleNumberMobile:
    "Compliance with the norms of the permissible number of passengers in the cabin",
  optimizationWorkControllers: "Оptimization of work of controllers",
  benefits: "Benefits",
  theWayToTheCityOfTheFuture: "The way to the \"City of the Future\" - Smart <br />City concept - implementation",
  theWayToTheCityOfTheFutureMobile: "The way to the \"City of the Future\" <br />- Smart City concept -<br /> implementation",
  TheConceptOfModularProduct: "The concept of modular product development<br/>  allows modular connection of services<br/> depending on your needs",
  TheConceptOfModularProductTablet: "The concept of modular product <br/> development allows modular connection of services depending on your needs",
  TheConceptOfModularProductMobile: "The concept of modular <br />product development<br /> allows modular connection<br /> of services depending on<br /> your needs",
  "Increase revenue by controlling revenue collection by up to 30%":
    "Increase revenue by controlling revenue<br/> collection by up<p> to 30%</p>",
  "Increase revenue by controlling revenue collection by up to 30% mobile":
    "Increase revenue by<br/>  controlling revenue<br/> collection by up<p> to 30%</p>",
  schedulingAndMatchingPassengers:
    "Scheduling and matching <br/> passengers for optimal frequency<br/> vehicles in real time",
  schedulingAndMatchingPassengersTablet:
    "Scheduling and matching <br/> passengers for optimal frequency<br/> vehicles in real time",
  schedulingAndMatchingPassengersMobile:
    "Scheduling and matching<br/> passengers for optimal<br/> frequency vehicles in real<br/> time",
  "Increasing competitiveness through the introduction of information technology":
    "Increasing competitiveness through<br/> the introduction of information <br/>technology",
  "Increasing competitiveness through the introduction of information technology tablet":
    "Increasing competitiveness<br/> through the introduction of<br/> information technology",
  "Increasing competitiveness through the introduction of information technology mobile":
    "Increasing competitiveness<br/> through the introduction of<br/> information technology",
  paybackPeriod: "Payback period <br/><p>of 3 to 5 months</p>",
  paybackPeriodMobile: "Payback period<br/> <p>of 3 to 5 months</p>",
  UsingTheServiceIsSimple: "Using the service is simple and easy<br/> for employees of any level <br/>of responsibility",
  UsingTheServiceIsSimpleMobile: "Using the service is<br/> simple and easy for<br/> employees of any level<br/> of responsibility",
  AutomaticCountingOfPassengers:
    "Automatic counting of passengers does<br/> not depend on human factor and <br/> minimizes it",
  AutomaticCountingOfPassengersTablet:
    "Automatic counting of <br/>passengers does not depend<br/> on human factor and <br/> minimizes it",
  AutomaticCountingOfPassengersMobile:
    "Automatic counting of <br/>passengers does not depend<br/> on human factor and <br/> minimizes it",
  /////////////////*Contacts Page
  "Contact us": "Contact us",
  "Contact us mobile": "Contact us",
  "Our managers will help you choose the most effective solution for the development of your business":
    "Our managers will help you choose the most<br/> effective solution for the development of<br/> your business",
  "Our managers will help you choose the most effective solution for the development of your business mobile":
    "Our managers will help you choose the most effective solution for the development of your business",
  Israel: "Israel",
  Ukraine: "Ukraine",
  Australia: "Australia",
  locationIsrael: "Tel Aviv, Israel",
  locationUkraine: "Sobornaya St. 24,<br/> Vinnytsia,<br/> Ukraine 21000",
  locationAustralia: "",
  //////////****About us
  "We believe in a sustainable city of the future with unmanned public transport, a centralized control system to ensure traffic jams, accidents and emissions free travel":
    "We believe in a sustainable city<br/> of the future with unmanned<br/> public transport, a centralized<br/> control system to ensure traffic<br/> jams, accidents and emissions<br/> free travel",
  "We believe in a sustainable city of the future with unmanned public transport, a centralized control system to ensure traffic jams, accidents and emissions free travel mobile":
    "We believe in a sustainable city of the future with unmanned public transport, a centralized control system to ensure traffic jams, accidents and emissions free travel",
  "Our Vision": "Our Vision",
  "We want to live in a world where traffic forecasting, development and management of networks, public transport routes and unmanned vehicles are based on the analysis of historical and online passenger and vehicle route data":
    "We want to live in a world where traffic forecasting, development<br/> and management of networks, public transport routes and<br/> unmanned vehicles are based on the analysis of historical and<br/> online passenger and vehicle route data",
  "We want to live in a world where traffic forecasting, development and management of networks, public transport routes and unmanned vehicles are based on the analysis of historical and online passenger and vehicle route data mobile":
    "We want to live in a world where traffic forecasting, development and management of networks, public transport routes and unmanned vehicles are based on the analysis of historical and online passenger and vehicle route data",
  "Our competencies": "Our competencies",
  "We develop and implement advanced solutions for planning, control and optimization in public transportation":
    "We develop and implement<br/> advanced solutions for planning,<br/> control and optimization in public<br/> transportation",
  "We develop and implement advanced solutions for planning, control and optimization in public transportation mobile":
    "We develop and implement advanced solutions for planning, control and optimization in public transportation",
  "We conduct passenger flow studies to successfully modify and optimize transportation networks":
    "We conduct passenger flow studies<br/> to successfully modify and optimize<br/> transportation networks",
  "We conduct passenger flow studies to successfully modify and optimize transportation networks mobile":
    "We conduct passenger flow studies<br/> to successfully modify and optimize<br/> transportation networks",
  "We manufacture and install automatic passenger counting devices":
    "We manufacture and install automatic<br/> passenger counting devices",
  "We manufacture and install automatic passenger counting devices mobile":
    "We manufacture and install automatic passenger counting devices",
  "Our experience": "Our experience",
  "Online Intranso Smart Counter Device Systems":
    "Online Intranso Smart<br/> Counter Device<br/> Systems",
  installations: "installations",
  country: "",
  "daily number of passengers counted by Intranso systems":
    "daily number of passengers<br/> counted by Intranso<br/> systems",
  "transport operators": "transport operators",
  "countries expertise:Israel, Europe":
    "countries expertise:<br/>Israel, Europe",
  ///*********************************************//
  thanks: "Thank you!",
  "Demo request sent": "Demo request sent",
  "What will happen next": "What will happen next:",
  "the manager will contact you": "the manager will contact you",
  "synchronize convenient time for the demo":
    "synchronize convenient time for the demo",
  "the manager will conduct a demo familiarization with the system":
    "the manager will conduct a demo familiarization with the system",
  "you can ask a question about the system":
    "you can ask a question about the system",
  "get access to a personal demo cabinet":
    "get access to a personal demo cabinet",
  "free for 7 days": "free for 7 days",
  understand: "Ok, I understand",
  "send contacts for communication": "Отправить контакты для связи",
  send: "Send",
  cancel: "Cancel",
  telephone: "Telephone",
  name: "Name",
  enter: "Enter",
  next: "Next",
  "Good time for a demo": "Good time for a demo",
  "I agree to the terms of the privacy policy and authorize the use of my personal":
    "I agree to the terms of the privacy policy and authorize the use of my personal...",
  "Send contacts for communication": "Send contacts for communication",
  "Just 3 steps and our managers will help you choose the most effective solution for the development of your business":
    "Just 3 steps and our managers will help you choose the most effective solution for the development of your business",
  "enter valid email": "Enter valid email",
  "enter valid name": "Enter valid name",
  "enter valid telephone": "Enter valid telephone",
  COMPANY: "COMPANY",
  CONTACT_US: "CONTACT US",
  SOCIAL_NETWORKS: "SOCIAL NETWORKS",
  Presentation: "Presentation"
};
