import React, { useContext } from "react";
import styles from "./FooterContacts.module.scss";
import { Trans, useTranslation } from "react-i18next";
import Button from "../shared/Button/Button";
import { AppContext } from "../../providers/AppContext";
import { downAnimation } from "../../styles/animations";
import { motion } from "framer-motion";
import { useDevice } from "../../hooks/useDevice";

const FooterContacts = () => {
  const { t, i18n } = useTranslation();
  const { setState } = useContext(AppContext);
  const deviceType = useDevice();
  const mobileVersion = deviceType === "MOBILE";
  const openModalHandle = () => setState({ openModal: true });
  const phoneNumber =
    i18n.language === "ru" || i18n.language === "ua"
      ? "+38 067 432 80 89"
      : "+972 53 457 54 34";
  return (
    <motion.div
      className={styles.generalInfoWrapper}
      initial={"hidden"}
      whileInView={"visible"}
      viewport={{ amount: 0.2, once: true }}
    >
      <motion.div variants={downAnimation} className={styles.questionContainer}>
        <h2>{t("anyQuestions")}</h2>
        <span>{t("weWillAdviseYouQuestions")}</span>
        <div className={styles.contacts}>
          <a href={`tel:${phoneNumber}`} className={styles.contactsData}>
            {phoneNumber}
          </a>
          <br />
          <a href={`mailto:sales@intranso.com`} className={styles.contactsData}>
            sales@intranso.com
          </a>
        </div>
      </motion.div>
      <motion.div variants={downAnimation} className={styles.questionContainer}>
        <h2>{t("demoAccess")}</h2>
        <span>
          <Trans
            i18nKey={
              mobileVersion
                ? "ourManagerWillContactYouMobile"
                : "ourManagerWillContactYou"
            }
          />
        </span>
        <div className={styles.contacts}>
          <Button
            className={styles.demoButton}
            title={t(mobileVersion ? "demoTablet" : "wantToSeeDemo")}
            onClick={openModalHandle}
            image={true}
            arrowColor={"white"}
          />
        </div>
      </motion.div>
    </motion.div>
  );
};

export default FooterContacts;
