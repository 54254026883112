import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import Logo from "../../assets/icons/Logo-header.svg";
import Button from "../shared/Button/Button";
import cn from "classnames";
import { Link, NavLink, useLocation } from "react-router-dom";
import SelectLanguage from "../shared/SelectLanguage/SelectLanguage";
import { useTranslation, Trans } from "react-i18next";
import { useDevice } from "../../hooks/useDevice";
import { motion } from "framer-motion";
import { downAnimation } from "../../styles/animations";
import MenuButton from "../shared/MenuButton/MenuButton";

const Header = ({ setOpenModal, setOpenMenu }) => {
  const location = useLocation();
  const backgroundClasses = [
    "headerHome",
    "headerProduct",
    "headerAboutUs",
    "headerContacts",
  ];
  const backgroundClassesTablet = [
    "headerHomeTablet",
    "headerProductTablet",
    "headerAboutUs",
    "headerContacts",
  ];
  const backgroundClassesMobile = [
    "headerHomeMobile",
    "headerProductMobile",
    "headerAboutUsMobile",
    "headerContacts",
  ];

  const { t, i18n } = useTranslation();

  const deviceType = useDevice();
  const { pathname } = useLocation();

  useEffect(() => window.scrollTo(0, 0), []);

  const mobileVersion = deviceType === "MOBILE";
  const tabletVersion = deviceType === "TABLET";

  const downloadFileHandle = () => {
    const resUa = "/documents/INTRANSOSmartCounter(UA).pdf";
    const resRu = "/documents/INTRANSOSmartCounter(RU).pdf";
    const resEn = "/documents/INTRANSOSmartCounter(EN).pdf";
    const fileByLanguage =
      i18n.language === "ru" ? resRu : i18n.language === "en" ? resEn : resUa;

    const fileValue = fileByLanguage.split("/")[2];

    const tempLink = document.createElement("a");
    tempLink.href = fileByLanguage;
    tempLink.setAttribute("download", fileValue);
    tempLink.click();
  };

  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false)

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setIsHeaderScrolled(true)
    } else {
      setIsHeaderScrolled(false)
    }
  }

  useEffect(() => {

    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeBackground)

    return () => {
      window.removeEventListener('scroll', changeBackground)
    }
  })

  const informationByLocation = () => {
    switch (location.pathname) {
      case "/":
        return {
          className: 'bigTitleBlock',
          background:
            deviceType === "PC"
              ? styles[backgroundClasses[0]]
              : tabletVersion
              ? styles[backgroundClassesTablet[0]]
              : styles[backgroundClassesMobile[0]],
          title: mobileVersion
            ? [
                t("headerHomeTitleMobileLine1"),
                t("headerHomeTitleMobileLine2"),
                t("headerHomeTitleMobileLine3"),
                t("headerHomeTitleMobileLine4"),
                t("headerHomeTitleMobileLine5"),
              ]
            : [
                t("headerHomeTitleLine1"),
                t("headerHomeTitleLine2"),
                t("headerHomeTitleLine3"),
              ],
        };
      case "/products":
        return {
          className: 'bigTitleBlock',
          background:
            deviceType === "PC"
              ? styles[backgroundClasses[1]]
              : tabletVersion
              ? styles[backgroundClassesTablet[1]]
              : styles[backgroundClassesMobile[1]],
          title: mobileVersion
            ? [
                t("headerProductTitleMobileLine1"),
                t("headerProductTitleMobileLine2"),
                t("headerProductTitleMobileLine3"),
                t("headerProductTitleMobileLine4"),
                t("headerProductTitleMobileLine5"),
              ]
            : [
                t("headerProductTitleLine1"),
                t("headerProductTitleLine2"),
                t("headerProductTitleLine3"),
              ], //(

          //<Trans i18nKey={"headerProductTitle"} />
          //),
        };
      case "/aboutUs":
        return {
          className: 'titleBlock',
          background:
            deviceType === "PC"
              ? styles[backgroundClasses[2]]
              : tabletVersion
              ? styles[backgroundClassesTablet[2]]
              : styles[backgroundClassesMobile[2]],
          title:
            deviceType === "PC" || deviceType === "TABLET"
              ? [
                  t("headerAboutUsTitleLine1"),
                  t("headerAboutUsTitleLine2"),
                  t("headerAboutUsTitleLine3"),
                ]
              : [
                  t("headerAboutUsTitleMobileLine1"),
                  t("headerAboutUsTitleMobileLine2"),
                  t("headerAboutUsTitleMobileLine3"),
                  t("headerAboutUsTitleMobileLine4"),
                  t("headerAboutUsTitleMobileLine5"),
                ],
        };

      case "/contacts":
        return {
          background:
            deviceType === "PC"
              ? styles[backgroundClasses[3]]
              : styles[backgroundClassesTablet[3]],
          title: [],
        };
    }
  };

  return (
    <div
      className={cn(styles.headerWrapper, informationByLocation().background)}
    >
      <div className={`${styles.headerMenu} ${isHeaderScrolled ? styles.headerMenuScrolled: ''}`}>
        <Link to={"/"}>
          <img className={styles.logo} src={Logo} alt={"Logo"} />
        </Link>
        {mobileVersion ? (
          <MenuButton setOpenMenu={setOpenMenu} setOpenModal={setOpenModal} />
        ) : (
          <>
            <nav className={styles.navigation}>
              <NavLink
                to={"/products"}
                className={cn({
                  [styles.active]: pathname === "/products",
                })}
              >
                <div>
                  <span className={styles.link}>{t("products")}</span>
                  <span className={styles.linkHover}>{t("products")}</span>
                </div>
              </NavLink>
              <NavLink
                to={"/aboutUs"}
                className={cn({
                  [styles.active]: pathname === "/aboutUs",
                })}
              >
                <div>
                  <span className={styles.link}>{t("aboutUs")}</span>
                  <span className={styles.linkHover}>{t("aboutUs")}</span>
                </div>
              </NavLink>
              <NavLink
                to={"/contacts"}
                className={cn({
                  [styles.active]: pathname === "/contacts",
                })}
              >
                <div>
                  <span className={styles.link}>{t("contacts")}</span>
                  <span className={styles.linkHover}>{t("contacts")}</span>
                </div>
              </NavLink>
            </nav>
            <SelectLanguage />
          </>
        )}
      </div>
      {location.pathname !== "/contacts" && (
        <>
          <div className={styles[informationByLocation().className]}>
            <motion.h1
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ once: true }}
            >
              {informationByLocation().title.map((line, index) => {
                return (
                  <div key={index}>
                    <motion.span
                      variants={downAnimation}
                      custom={(index + 2) / 5}
                    >
                      {line}
                    </motion.span>
                    <br />
                  </div>
                );
              })}
            </motion.h1>
            {location.pathname === "/" && (
              <motion.p
              initial={"hidden"}
              whileInView={"visible"}
              variants={downAnimation}
              viewport={{ amount: 0.1, once: true }}
              className={styles.subTitleBlock}
              custom={1}
            >
              <Trans
                i18nKey={
                  mobileVersion
                    ? "AIBasedSolutionsMobile"
                    : "AIBasedSolutions"
                }
              />
            </motion.p>
            )}
            {location.pathname === "/aboutUs" && (
              <motion.p
              initial={"hidden"}
              whileInView={"visible"}
              variants={downAnimation}
              viewport={{ amount: 0.1, once: true }}
              className={styles.subTitleAboutUsBlock}
              custom={1}
            >
              <Trans
                i18nKey={
                  mobileVersion
                    ? "subHeaderAboutUsTitleMobile"
                    : "subHeaderAboutUsTitle"
                }
              />
            </motion.p>
            )}
            {location.pathname === "/products" && (
              <motion.p
              initial={"hidden"}
              whileInView={"visible"}
              variants={downAnimation}
              viewport={{ amount: 0.1, once: true }}
              className={styles.subTitleProductBlock}
              custom={1}
            >
              <Trans
                i18nKey={
                  mobileVersion
                    ? "subHeaderProductTitleMobile"
                    : tabletVersion
                      ? "subHeaderProductTitleTablet"
                      : "subHeaderProductTitle"
                }
              />
            </motion.p>
            )}
            <div className={styles.bottomLine} />
          </div>
          <div className={styles.headerOptionalInfo}>
            <div className={styles.bottomLine} />
            <div className={styles.buttonWrapper}>
              {/*<motion.div*/}
              {/*  initial={"hidden"}*/}
              {/*  whileInView={"visible"}*/}
              {/*  viewport={{ once: true }}*/}
              {/*  variants={downAnimation}*/}
              {/*>*/}
              {/*  <Button*/}
              {/*    className={styles.presentationButton}*/}
              {/*    title={*/}
              {/*      deviceType === "PC"*/}
              {/*        ? t("ourPresentation")*/}
              {/*        : t("ourPresentationTablet")*/}
              {/*    }*/}
              {/*    image={mobileVersion || tabletVersion ? false : true}*/}
              {/*    arrowColor={"black"}*/}
              {/*    onClick={downloadFileHandle}*/}
              {/*  />*/}
              {/*</motion.div>*/}
              <motion.div
                initial={"hidden"}
                whileInView={"visible"}
                viewport={{ once: true }}
                variants={downAnimation}
              >
                <Button
                  className={styles.demoButton}
                  title={deviceType === "PC" ? t("demo") : t("demoTablet")}
                  onClick={(e) => {
                    e.preventDefault();
                    // window.scrollTo({
                    //   top: 0,
                    //   left: 0,
                    //   behavior: "smooth",
                    // });
                    setOpenModal();
                    //setOpenMenu();
                  }}
                  image={true}
                  arrowColor={"white"}
                />
              </motion.div>
            </div>
            {location.pathname === "/" && (
              <motion.span
                className={styles.optionalText}
                initial={"hidden"}
                whileInView={"visible"}
                viewport={{ once: true }}
                variants={downAnimation}
              >
                {deviceType === "PC" ? (
                  <Trans i18nKey="headerHomeDescription" />
                ) : tabletVersion
                  ? <Trans i18nKey="headerHomeDescriptionTablet" />
                  : <Trans i18nKey="headerHomeDescriptionMobile" />
                }
              </motion.span>
            )}
            {location.pathname === "/aboutUs" && (
              <motion.span
                className={styles.optionalText}
                initial={"hidden"}
                whileInView={"visible"}
                viewport={{ once: true }}
                variants={downAnimation}
              >
                {deviceType === "PC" ? (
                  <Trans i18nKey="headerAboutUsDescription" />
                ) : mobileVersion ? (
                  <Trans i18nKey="headerAboutUsDescriptionMobile" />
                ) : (
                  <Trans i18nKey="headerAboutUsDescriptionTablet" />
                )}
              </motion.span>
            )}
          </div>
          {location.pathname === "/products" && (
            <motion.div
              className={styles.headerPrecision}
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ once: true }}
              variants={downAnimation}
            >
              <h2>100%</h2>
              <span>
                <Trans i18nKey={mobileVersion ? "headerProductDescriptionMobile": "headerProductDescription"} />
              </span>
            </motion.div>
          )}
        </>
      )}
    </div>
  );
};

export default Header;
