import React, { useRef, useState } from "react";
import ArrowDown from "../../../assets/icons/arrow-down.svg";
import styles from "./Select.module.scss";
import cn from "classnames";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";

const Select = ({
  options,
  onFocus,
  onChange,
  errors,
  name,
  activeInput,
  onBlur,
}) => {
  const selectRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const closeTelephoneSelect = () => {
    selectedOption === null
      ? (() => {
          setIsOpen(false);
          onBlur();
        })()
      : setIsOpen(false);
  };

  useOnClickOutside(selectRef, () => closeTelephoneSelect());

  const toggleHandle = () => {
    setIsOpen(!isOpen);
    onFocus();
  };

  const onOptionClickHandle = (value) => () => {
    setSelectedOption(value);
    onChange(value);
    setIsOpen(false);
  };

  return (
    <div
      ref={selectRef}
      className={cn(styles.selectWrapper, {
        [styles.selectActive]: activeInput,
        [styles.selectError]: errors["time"],
      })}
    >
      <div className={styles.selectTitle} onClick={toggleHandle}>
        <span>{selectedOption}</span>
        <img src={ArrowDown} alt="Down" />
      </div>
      {isOpen && (
        <div className={styles.dropDownListContainer}>
          <ul className={styles.dropDownList}>
            {options.map((option) => (
              <li onClick={onOptionClickHandle(option)} key={option}>
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Select;
