import React, { useEffect, useRef, useState } from "react";
import styles from "./AboutUs.module.scss";
import cn from "classnames";
import CountUp from "react-countup";
import { Trans, useTranslation } from "react-i18next";
import { useDevice } from "../../hooks/useDevice";
import LogoCircle from "../../assets/icons/LogoRotate.svg";
import Marker from "../../assets/icons/marker-icon.svg";

const AboutUs = () => {
  const { t } = useTranslation();
  const cartContainerRef = useRef(null);
  const descriptionRef = useRef(null);
  const secondCartRef = useRef(null);
  const thirdCartRef = useRef(null);
  const imageRef = useRef(null);
  const deviceType = useDevice();
  const mobileVersion = deviceType === "MOBILE";
  const [opacity, setOpacity] = useState(100);
  const [descriptionPosition, setDescriptionPosition] = useState(0);
  const [thirdCartPosition, setThirdCartPosition] = useState(0);
  const [windowPosition, setWindowPosition] = useState(0);
  const [visibleImage, setVisibleImage] = useState(true);

  useEffect(() => {
    if (descriptionPosition > thirdCartPosition) {
      opacity > 0 &&
        (() => {
          setOpacity((prev) => prev - 10);
          setVisibleImage(false);
        })();
    } else {
      windowPosition < 3000 &&
        opacity < 100 &&
        (() => {
          setOpacity((prev) => prev + 10);
          setVisibleImage(true);
        })();
    }
  }, [descriptionPosition, thirdCartPosition]);
  useEffect(() => {
    descriptionRef.current.style.opacity = `${opacity}%`;
  }, [opacity]);
  const handleScroll = () => {
    const topPos = (element) => element?.getBoundingClientRect().top;
    const bottomPos = (element) => element?.getBoundingClientRect().bottom;
    // const getHeight = (element) => element.offsetHeight;
    const thirdCartPos = topPos(thirdCartRef.current);
    const descriptionPos = bottomPos(descriptionRef.current);
    const position = window.pageYOffset;
    setThirdCartPosition(thirdCartPos);
    setDescriptionPosition(descriptionPos);
    setWindowPosition(position);
    // if (thirdCartPos < descriptionPos) {
    //   console.log("Hide");
    //   opacity > 0 && setOpacity((prev) => prev - 10);
    // } else {
    //   opacity < 100 && setOpacity((prev) => prev + 10);
    //   console.log("show");
    // }

    // console.log(thirdCartRef.current.getBoundingClientRect().top);
    // console.log(descriptionRef.current.getBoundingClientRect().bottom);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={styles.aboutUsWrapper}>
        <div className={styles.aboutUsBackground}>
          <div className={styles.shortDescription}>
            <p ref={descriptionRef}>
              <Trans
                i18nKey={
                  mobileVersion
                    ? "We believe in a sustainable city of the future with unmanned public transport, a centralized control system to ensure traffic jams, accidents and emissions free travel mobile"
                    : "We believe in a sustainable city of the future with unmanned public transport, a centralized control system to ensure traffic jams, accidents and emissions free travel"
                }
              />
            </p>
            {visibleImage && (
              <div className={styles.rotateImg} ref={imageRef}>
                <img className={styles.marker} src={Marker} alt={"logo"} />
                <img className={styles.animImg} src={LogoCircle} alt={"logo"} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div ref={cartContainerRef} className={styles.informationCartContainer}>
        <div className={cn(styles.cartInfo, styles.firstCart)}>
          <h3>{t("Our Vision")}</h3>
          <p>
            <Trans
              i18nKey={
                mobileVersion
                  ? "We want to live in a world where traffic forecasting, development and management of networks, public transport routes and unmanned vehicles are based on the analysis of historical and online passenger and vehicle route data mobile"
                  : "We want to live in a world where traffic forecasting, development and management of networks, public transport routes and unmanned vehicles are based on the analysis of historical and online passenger and vehicle route data"
              }
            ></Trans>
          </p>
        </div>
        <div
          ref={secondCartRef}
          className={cn(styles.cartInfo, styles.secondCart)}
        >
          <h3>{t("Our competencies")}</h3>
          <ul className={styles.competencesList}>
            <li className={styles.development}>
              <Trans
                i18nKey={
                  mobileVersion
                    ? "We develop and implement advanced solutions for planning, control and optimization in public transportation mobile"
                    : "We develop and implement advanced solutions for planning, control and optimization in public transportation"
                }
              />
            </li>
            <li className={styles.research}>
              <Trans
                i18nKey={
                  mobileVersion
                    ? "We conduct passenger flow studies to successfully modify and optimize transportation networks mobile"
                    : "We conduct passenger flow studies to successfully modify and optimize transportation networks"
                }
              />
            </li>
            <li className={styles.microcontroller}>
              <Trans
                i18nKey={
                  mobileVersion
                    ? "We manufacture and install automatic passenger counting devices mobile"
                    : "We manufacture and install automatic passenger counting devices"
                }
              />
            </li>
          </ul>
        </div>
        <div
          ref={thirdCartRef}
          className={cn(styles.cartInfo, styles.thirdCart)}
        >
          <h3>{t("Our experience")}</h3>
          <div className={styles.line}>
            <div className={styles.lineInfoItem}>
              <CountUp
                className={styles.counter}
                end={700}
                separator={" "}
                duration={2}
                // enableScrollSpy={true}
                prefix={"+"}
                scrollSpyOnce={true}
              />
              <span className={styles.spanDescription}>
                <Trans i18nKey="Online Intranso Smart Counter Device Systems" />
              </span>
            </div>
            <div className={styles.lineInfoItem}>
              <CountUp
                className={styles.counter}
                end={5000}
                separator={" "}
                duration={2}
                // enableScrollSpy={true}
                prefix={"+"}
                scrollSpyOnce={true}
              />
              <span className={styles.spanDescription}>
                {t("installations")}
              </span>
            </div>
          </div>
          <div className={styles.line}>
            <div className={styles.lineInfoItem}>
              <CountUp
                className={styles.counter}
                end={7000000}
                separator={" "}
                duration={2}
                prefix={"+"}
                // enableScrollSpy={true}
                scrollSpyOnce={true}
              />
              <span className={styles.spanDescription}>
                <Trans i18nKey="daily number of passengers counted by Intranso systems" />
              </span>
            </div>
          </div>
          <div className={styles.line}>
            <div className={styles.line}>
              <div className={styles.lineInfoItem}>
                <CountUp
                  className={styles.counter}
                  end={120}
                  duration={2}
                  // enableScrollSpy={true}
                  prefix={"+"}
                  //enableScrollSpy={true}
                  scrollSpyOnce={true}
                />
                <span className={styles.spanDescription}>
                  <Trans i18nKey="transport operators" />
                </span>
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.lineInfoItem}>
                <CountUp
                  className={styles.counter}
                  end={5}
                  prefix={"+"}
                  duration={2}
                  suffix={` ${t("country")}`}
                  // enableScrollSpy={true}
                  scrollSpyOnce={true}
                />
                <span className={styles.spanDescription}>
                  <Trans i18nKey="countries expertise:Israel, Europe" />
                </span>
              </div>
            </div>
          </div>
        </div>
        {/*<div className={cn(styles.cartInfo, styles.forthCart)}></div>*/}
      </div>
    </>
  );
};

export default AboutUs;
