import React, { useContext, useRef, useState } from "react";
import ArrowDown from "../../../assets/icons/arrow-down.svg";
import cn from "classnames";
import styles from "./SelectLanguage.module.scss";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { AppContext } from "../../../providers/AppContext";

const SelectLanguage = () => {
  const languageRef = useRef(null);
  const { i18n } = useTranslation();
  const languages = ["ua", "ru", "en"];

  const { state, setState } = useContext(AppContext);
  const [openDropDown, setOpenDropDown] = useState(false);
  const changeLanguageHandle = (e) => {
    setState((prev) => ({ ...prev, language: e.target.value }));
    i18n.changeLanguage(languages[e.target.value]);
    document.cookie = `language=${languages[e.target.value]}`;
    setOpenDropDown(false);
  };

  useOnClickOutside(languageRef, () => setOpenDropDown(false));

  const currentLang = i18n.language === 'en-GB' && 'En'
    || i18n.language === 'uk-UA' && 'Ua'
    || i18n.language === 'ru-RU' && 'Ru'
    || i18n.language

  return (
    <div ref={languageRef} className={styles.selectWrapper}>
      <div
        onClick={() => setOpenDropDown(!openDropDown)}
        className={styles.selector}
      >
        <span>{currentLang}</span>
        <img
          className={cn(styles.arrow, { [styles.arrowUp]: openDropDown })}
          src={ArrowDown}
          alt="arrow"
        />
      </div>
      {openDropDown && (
        <div className={styles.dropdownWrapper}>
          <ul onClick={changeLanguageHandle}>
            <li value={0}>Ua</li>
            <li value={2}>En</li>
            <li value={1}>Ru</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectLanguage;
