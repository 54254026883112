import React, { useContext, useState } from "react";
import styles from "./Tabs.module.scss";
import Button from "../../../components/shared/Button/Button";
import cn from "classnames";
import TabPlappingImg from "../../../assets/images/TabPlappingImg.png";
import TabRouteRegistryCutImage from "../../../assets/images/TabRouteRegistryCut.png";
import ToolsControlImage from "../../../assets/images/TabToolsControlImg.png";
import ToolsControlCutImage from "../../../assets/images/TabToolsControlCut.png";
import TabTripMonitoringImage from "../../../assets/images/TabTripMonitoring.png";
import TabTripMonitoringCutImage from "../../../assets/images/TabTripMonitoringCut.png";
import TabAutoParkImage from "../../../assets/images/TabAutoPark.png";
import TabAutoParkCutImage from "../../../assets/images/TabAutoParkCut.png";
import ApplicationImg from "../../../assets/images/applicationImage.png";
import ApplicationCutImg from "../../../assets/images/ApplicationCut.png";
import BusStopEdit1Image from "../../../assets/images/Bus_stops_edit1.png";
import BusStopEdit2Image from "../../../assets/images/Bus_stops_edit2.png";
import BusStopEdit3Image from "../../../assets/images/Bus_stops_edit3.png";
import ScheduleLoading from "../../../assets/images/ScheduleLoading.png";
import CashRecharts from "../../../assets/images/CashRecharts.png";
import CashRechartsCut from "../../../assets/images/cashRechartsCut.png";
import TripCharSidebar from "../../../assets/images/TripCharSidebar.png";
import AutoparkRecharts from "../../../assets/images/AutoparkRecharts.png";
import AutoparkCircle from "../../../assets/images/AutoParkCircle.png";
import Notification from "../../../assets/images/Notification.png";
import RagnalStation from "../../../assets/images/RagnalStation.png";
import Selection from "../../../assets/images/selection.png";

import { AnimatePresence, motion } from "framer-motion";
import { Trans, useTranslation } from "react-i18next";
import { AppContext } from "../../../providers/AppContext";
import { useDevice } from "../../../hooks/useDevice";
import { AppearanceAnimation, downAnimation } from "../../../styles/animations";

const Tabs = () => {
  const { t } = useTranslation();
  const { state } = useContext(AppContext);
  const deviceType = useDevice();
  const contentArray = [
    {
      id: 1,
      title: t("plan"),
      type: "plan",
      image:
        deviceType === "PC" ? TabPlappingImg : TabRouteRegistryCutImage,
      descriptionTitle: t("planning"),
      list: [
        <Trans i18nKey="creationStops" />,
        <Trans i18nKey="createEditRoutes" />,
        <Trans i18nKey="support for layouts and multi-directional trips" />,
        <Trans i18nKey="correcting and arranging stops" />,
        <Trans i18nKey="editing routes on the map" />,
        <Trans i18nKey="Create routes on the map" />,
        <Trans i18nKey="route scheduling" />,
        <Trans
          i18nKey={
            deviceType === "PC"
              ? "assign employees to transport tasks on planned routes"
              : "assign employees to transport tasks on planned routes mobile"
          }
        />,
      ],
    },
    {
      id: 2,
      title: t("control"),
      type: "control",
      image: deviceType === "PC" ? ToolsControlImage : ToolsControlCutImage,
      descriptionTitle: t("controlInstruments"),
      list: [
        <Trans i18nKey="PassengerTrafficInReal" />,
        <Trans i18nKey="RoutesAndTrips" />,
        <Trans i18nKey="trackingOfTransport" />,
        <Trans i18nKey="AnticipationOfPossible" />,
        <Trans i18nKey="ETAEstimated" />,
      ],
    },
    {
      id: 3,
      title: t("optimize"),
      type: "optimize",
      image:
        deviceType === "PC"
          ? TabTripMonitoringImage
          : TabTripMonitoringCutImage,
      descriptionTitle: t("flightMonitoring"),
      list: [
        <Trans i18nKey="optimization of routes, flights" />,
        <Trans i18nKey="onlineExecutionChart" />,
        <Trans i18nKey="different colors for completed" />,
        <Trans i18nKey="editing routes on the map" />,
      ],
    },
    {
      id: 4,
      title: t("forFleet"),
      type: "fleet",
      image: deviceType === "PC" ? TabAutoParkImage : TabAutoParkCutImage,
      descriptionTitle: t("forFleetManagement"),
      list: [
        t("fleet management tools"),
        t("GPS tracking and monitoring"),
        t("wide range of reports"),
        t("Records of service and repair work"),
        t("maintenance planning"),
        t("online control of repair costs"),
        t("Employee time tracking"),
        t("fuel consumption control"),
      ],
    },
    {
      id: 5,
      title: t("application"),
      type: "application",
      image: deviceType === "PC" ? ApplicationImg : ApplicationCutImg,
      descriptionTitle: <Trans i18nKey={"Application for the driver"} />,
      list: [
        <Trans i18nKey="information and messages about traffic intervals on the route" />,
        <Trans i18nKey="notification of the trip and changes" />,
        <Trans i18nKey="detailed information about the route with timetable" />,
        <Trans
          i18nKey={
            deviceType === "PC"
              ? "comparison of the actual trip with the schedule and estimated time of arrival"
              : "comparison of the actual trip with the schedule and estimated time of arrival tablet"
          }
        />,
      ],
    },
  ];
  const [activeButton, setActiveButton] = useState(0);
  const onClickHandle = (index) => {
    setActiveButton(index);
  };
  return (
    <div className={styles.tabsWrapper}>
      <motion.div
        variants={downAnimation}
        initial={"hidden"}
        whileInView={"visible"}
        viewport={{ once: true }}
        className={styles.buttonWrapper}
      >
        {contentArray.map((i, index) => (
          <Button
            className={cn(styles.currentButton, {
              [styles.activeButton]: activeButton === index,
            })}
            title={i.title}
            onClick={() => onClickHandle(index)}
            key={index}
          />
        ))}
      </motion.div>

      <div className={styles.tabsInfo}>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.description}
          >
            <motion.h2
              variants={AppearanceAnimation}
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ once: true }}
              //transition={{ delay: 0.2, duration: 1 }}
              className={styles.descriptionTitle}
            >
              {contentArray[activeButton]?.descriptionTitle}
            </motion.h2>
            <ul>
              {contentArray[activeButton]?.list.map((li, index) => (
                <motion.li
                  //variants={AppearanceAnimation}
                  initial={"hidden"}
                  whileInView={"visible"}
                  viewport={{ once: true }}
                  key={index}
                >
                  {li}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </AnimatePresence>

        <motion.div
          initial={"hidden"}
          whileInView={"visible"}
          className={styles.image}
        >
          <img
            src={contentArray[activeButton].image}
            className={cn(styles.bigImage, {
              [styles.bigImageTablet]: deviceType === "TABLET",
              [styles.bigImageApplication]:
                contentArray[activeButton].type === "application" &&
                deviceType === "PC",
            })}
            alt="generalImage"
          />
          {contentArray[activeButton].type === "plan" && (
            <>
              {deviceType === "PC" && (
                <>
                  <motion.img
                    initial={{ x: "100px" }}
                    animate={{ x: 0 }}
                    transition={{ delay: 0.2 }}
                    className={styles.busStopEdit1}
                    src={BusStopEdit1Image}
                    alt=""
                  />
                  <motion.img
                    initial={{ x: "100px", opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.4 }}
                    className={styles.busStopEdit2}
                    src={BusStopEdit2Image}
                    alt=""
                  />
                  <motion.img
                    initial={{ x: "100px", opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.6 }}
                    className={styles.busStopEdit3}
                    src={BusStopEdit3Image}
                    alt=""
                  />
                </>
              )}
              <motion.img
                initial={{ x: "-100px", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
                className={styles.scheduleLoading}
                src={ScheduleLoading}
                alt=""
              />
            </>
          )}
          {contentArray[activeButton].type === "control" && (
            <motion.img
              initial={{ y: "100px", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className={styles.cashRecharts}
              src={deviceType === "PC" ? CashRecharts : CashRechartsCut}
              alt=""
            />
          )}
          {contentArray[activeButton].type === "optimize" &&
            deviceType === "PC" && (
              <>
                <motion.img
                  initial={{ x: "100px", opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className={styles.tripCharSidebar}
                  src={TripCharSidebar}
                  alt=""
                />
              </>
            )}
          {contentArray[activeButton].type === "fleet" && deviceType === "PC" && (
            <>
              <motion.img
                initial={{ x: "100px", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
                className={styles.autoparkRecharts1}
                src={AutoparkRecharts}
                alt=""
              />
              <motion.img
                initial={{ x: "100px", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
                className={styles.autoparkRecharts2}
                src={AutoparkRecharts}
                alt=""
              />
              <motion.img
                initial={{ x: "100px", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.5 }}
                className={styles.autoparkRecharts3}
                src={AutoparkRecharts}
                alt=""
              />
              <motion.img
                initial={{ y: "100px", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.1 }}
                className={styles.autoparkCircle}
                src={AutoparkCircle}
                alt=""
              />
            </>
          )}
          {contentArray[activeButton].type === "application" &&
            deviceType === "PC" && (
              <>
                <motion.img
                  initial={{ x: "100px", opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className={styles.notification}
                  src={Notification}
                  alt=""
                />
                <motion.img
                  initial={{ x: "100px", opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                  className={styles.ragnalStation}
                  src={RagnalStation}
                  alt=""
                />
                <img
                  className={styles.selectionApplication}
                  src={Selection}
                  alt=""
                />
              </>
            )}
        </motion.div>
      </div>
    </div>
  );
};

export default Tabs;
