import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ImageAnalytic from "../../assets/images/Main-Image1.png";
import PrivateTransportCompaniesImage from "../../assets/images/PrivateTransportCompanies.png";
import MunicipalImage from "../../assets/images/municipalСarriers.png";
import RegionalAgencyImage from "../../assets/images/regionalAgency.png";
import RailwayImage from "../../assets/images/railwayImage.png";
import TransportationStaffImage from "../../assets/images/transportationStaff.png";
import ApproachIcon from "../../assets/icons/approach-icon.svg";
import ModuleIcon from "../../assets/icons/module-connect-icon.svg";
import FlexibilityIcon from "../../assets/icons/flexibility-icon.svg";
import DevelopmentIcon from "../../assets/icons/development-icon.svg";
import CloudIcon from "../../assets/icons/cloud-icon.svg";
import PeopleIcon from "../../assets/icons/people-factor-icon.svg";
import WifiIcon from "../../assets/icons/wifi-icon.gif";
import { motion } from "framer-motion";
import styles from "./Home.module.scss";
import Button from "../../components/shared/Button/Button";
import Accordion from "../../components/Accordion/Accordion";
import { Trans, useTranslation } from "react-i18next";
import {
  downAnimation,
  ImageRightAnimation,
  LeftAnimation,
  RightAnimation,
} from "../../styles/animations";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import useOnScreen from "../../hooks/useScroll";
import cn from "classnames";
import { useDevice } from "../../hooks/useDevice";
import { useInView } from "react-intersection-observer";

const Home = () => {
  const { t } = useTranslation();
  const deviceType = useDevice();
  const mobileVersion = deviceType === "MOBILE";
  const tabletVersion = deviceType === "TABLET";
  const items = [
    {
      id: 1,
      title: <Trans i18nKey={"privateTransportCompanies"} />,
      image: PrivateTransportCompaniesImage,
      list: [
        <Trans i18nKey={"сontrol of passanger flow in real time"} />,
        <Trans i18nKey={"predicting flows"} />,
        <Trans i18nKey={"revenue collection control"} />,
        <Trans i18nKey={"GPS monitoring of vehicles operation"} />,
        <Trans i18nKey={"personnel control"} />,
        <Trans i18nKey={"online fleet management"} />,
      ],
    },
    {
      id: 2,
      title: <Trans i18nKey={"municipalCarriers"} />,
      image: MunicipalImage,
      list: [
        <Trans i18nKey={"сontrol of passanger flow in real time"} />,
        <Trans i18nKey={"predicting flows"} />,
        <Trans i18nKey={"revenue collection control"} />,
        <Trans i18nKey={"efficient organization of the work of conductor teams"} />,
        <Trans i18nKey={"GPS monitoring of vehicles operation"} />,
        <Trans i18nKey={"personnel control"} />,
        <Trans i18nKey={"online fleet management"} />,
      ],
    },
    {
      id: 3,
      title: <Trans i18nKey={"regionalAgencies"} />,
      image: RegionalAgencyImage,
      list: [
        <Trans
          i18nKey={
            mobileVersion
              ? "efficientOrganizationPassengerTransportationMobile"
              : "efficientOrganizationPassengerTransportation"
          }
        />,
        <Trans i18nKey={"planningRoutesSchedules"} />,
        <Trans i18nKey={"optimizationExisting"} />,
        <Trans i18nKey={"controlOverTimeliness"} />,
        <Trans
          i18nKey={
            mobileVersion
              ? "controlPaymentsCompletedMobile"
              : "controlPaymentsCompleted"
          }
        />,
        <Trans i18nKey={"dispatchCenterManagement"} />,
        <Trans
          i18nKey={
            mobileVersion
              ? "efficientUseTransportInfrastructureMobile"
              : "efficientUseTransportInfrastructure"
          }
        />,
      ],
    },
    {
      id: 4,
      title: <Trans i18nKey={"railwayCompaniesSubways"} />,
      image: RailwayImage,
      list: [
        <Trans
          i18nKey={
            mobileVersion
              ? "effectiveOrganizationTransportNetworkMobile"
              : "effectiveOrganizationTransportNetwork"
          }
        />,
        <Trans i18nKey={"revenue collection control"} />,
        <Trans
          i18nKey={
            mobileVersion
              ? "planningOptimizingRollingStockMobile"
              : "planningOptimizingRollingStock"
          }
        />,
        <Trans i18nKey={"efficientUseTransportInfrastructure"} />,
      ],
    },
    {
      id: 5,
      title: <Trans i18nKey={"transportationChildrenStaff"} />,
      image: TransportationStaffImage,
      list: [
        <Trans i18nKey={"controlSafeTransportation"} />,
        <Trans i18nKey={"monitoringTimelinessFlights"} />,
        <Trans
          i18nKey={
            mobileVersion
              ? "planningRouteRealTimeMobile"
              : "planningRouteRealTime"
          }
        />,
        <Trans i18nKey={"efficientUseTransport"} />,
        <Trans i18nKey={"notificationArrival"} />,
        <Trans i18nKey={"flightBooking"} />,
      ],
    },
  ];
  const benefits = [
    {
      id: 1,
      text: (
        <Trans
          i18nKey={
            !mobileVersion
              ? deviceType === "TABLET"
                ? "AIPoweredSystemTablet"
                : "AIPoweredSystem"
              : "AIPoweredSystemMobile"
          }
        />
      ),
      image: ApproachIcon,
    },
    {
      id: 2,
      text: (
        <Trans
          i18nKey={
            deviceType === "TABLET"
              ? "engagingWithHistoricalTablet"
              : "engagingWithHistorical"
          }
        />
      ),
      image: ModuleIcon,
    },
    {
      id: 3,
      text: (
        <Trans
          i18nKey={
            mobileVersion
              ? "ourSolutionHelpsTransportOperatorsMobile"
              : tabletVersion
                ? "ourSolutionHelpsTransportOperatorsTablet"
                : "ourSolutionHelpsTransportOperators"
          }
        />
      ),
      image: FlexibilityIcon,
    },
    {
      id: 4,
      text: <Trans i18nKey="ownDevelopmentSoftware" />,
      image: DevelopmentIcon,
    },
    {
      id: 5,
      text: <Trans i18nKey="cloudSecure" />,
      image: CloudIcon,
    },
    {
      id: 6,
      text: <Trans i18nKey="minimizationHumanFactor" />,
      image: PeopleIcon,
    },
  ];

  let navigate = useNavigate();
  const windowRef = useRef(null);
  const titleRef1 = useRef(null);
  const descriptionRef1 = useRef(null);
  const percentRef = useRef(null);
  const percentDescriptionRef = useRef(null);
  const button1Ref = useRef(null);
  const image1Ref = useRef(null);
  const { isViewed: title1View } = useOnScreen(titleRef1);
  const { isViewed: description1View } = useOnScreen(descriptionRef1);
  const { isViewed: percentView } = useOnScreen(percentRef);
  const { isViewed: percentDescriptionView } = useOnScreen(
    percentDescriptionRef
  );
  const { isViewed: button1View } = useOnScreen(button1Ref);
  const { isViewed: image1 } = useOnScreen(image1Ref);
  const [activeAccordionItem, setActiveAccordionItem] = useState(1);
  const [hoverIndex, setHoverIndex] = useState(null);
  const aboutProductHandle = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    navigate("/products");
  };

  return (
    <div ref={windowRef} style={{ overflowX: "hidden", overflowY: "hidden" }}>
      <div className={styles.analyticsWrapper}>
        <div className={styles.content}>
          <motion.h2
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={downAnimation}
            className={cn(
              styles.analyticTitle
              //     {
              //   [styles.animationAnalyticTitle]: title1View,
              // }
            )}
            ref={titleRef1}
          >
            <Trans
              i18nKey={
                mobileVersion
                  ? "Our sensors and computer vision-based software are designed to count the number of passengers at any point of the journey mobile"
                  : "Our sensors and computer vision-based software are designed to count the number of passengers at any point of the journey"
              }
            />
          </motion.h2>
          <motion.div
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={downAnimation}
            ref={descriptionRef1}
            className={cn(
              styles.subtitle
              //     {
              //   [styles.animationSubtitle]: description1View,
              // }
            )}
          >
            <Trans
              i18nKey={
                deviceType !== "MOBILE"
                  ? "For transport companies, acting on this data improves economics and transparency"
                  : "For transport companies, acting on this data improves economics and transparency mobile"
              }
            />
          </motion.div>
          <div className={styles.percentsWrapper}>
            <div className={styles.percent} ref={percentRef}>
              <motion.div
                initial={"hidden"}
                whileInView={"visible"}
                viewport={{ once: true }}
                variants={downAnimation}
              >
                <CountUp
                  className={cn(styles.counterPercent, {
                    [styles.animationCounterPercent]: percentView,
                  })}
                  end={30}
                  duration={1}
                  prefix={"+"}
                  suffix={"%"}
                  //enableScrollSpy={true}
                  scrollSpyOnce={true}
                />
              </motion.div>

              <motion.span
                ref={percentDescriptionRef}
                className={cn(styles.counterDescription, {
                  [styles.animationCounterDescription]: percentDescriptionView,
                })}
                initial={"hidden"}
                whileInView={"visible"}
                viewport={{ once: true }}
                variants={downAnimation}
              >
                <Trans
                  i18nKey={
                    !mobileVersion
                      ? "additional profit daily"
                      : "additional profit daily mobile"
                  }
                />
              </motion.span>
            </div>
            <div className={styles.percent}>
              <motion.div
                initial={"hidden"}
                whileInView={"visible"}
                viewport={{ once: true }}
                variants={downAnimation}
              >
                <CountUp
                  className={cn(styles.counterPercent, {
                    [styles.animationCounterPercent]: percentView,
                  })}
                  end={20}
                  duration={1}
                  //enableScrollSpy={true}
                  prefix={"-"}
                  suffix={"%"}
                  scrollSpyOnce={true}
                />
              </motion.div>

              <motion.span
                initial={"hidden"}
                whileInView={"visible"}
                viewport={{ once: true }}
                variants={downAnimation}
                className={cn(styles.counterDescription, {
                  [styles.animationCounterDescription]: percentDescriptionView,
                })}
              >
                {" "}
                <Trans
                  i18nKey={
                    !mobileVersion
                      ? "costs savings monthly"
                      : "costs savings monthly mobile"
                  }
                />
              </motion.span>
            </div>
          </div>
          <motion.div
            ref={button1Ref}
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={downAnimation}
          >
            {" "}
            <Button
              className={cn(styles.infoProductButton, {
                [styles.animationInfoProductButton]: button1View,
              })}
              title={t("aboutTheProduct")}
              onClick={aboutProductHandle}
              image={true}
              arrowColor={"black"}
            />
          </motion.div>
        </div>

        <motion.div
          ref={image1Ref}
          initial={"hidden"}
          whileInView={"visible"}
          viewport={{ once: true }}
          variants={ImageRightAnimation}
          className={cn(
            styles.image1
            //     {
            //   [styles.animationImage1]: image1,
            // }
          )}
        >
          <img src={ImageAnalytic} alt="analytic" />
          <img src={WifiIcon} className={styles.wifi} alt="" />
        </motion.div>
      </div>

      <motion.div className={styles.usersWrapper}>
        {deviceType === "PC" ? (
          <div className={styles.imageContainer}>
            <img
              src={
                hoverIndex
                  ? items[hoverIndex - 1]?.image
                  : items[activeAccordionItem - 1]?.image || items[0].image
              }
              alt="bus"
            />
            <motion.h2
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ once: true }}
              variants={downAnimation}
              custom={2}
            >
              <Trans i18nKey={"whoUses"} />
            </motion.h2>
          </div>
        ) : (
          <motion.h2
            className={styles.userTitle}
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ amount: 0.2, once: true }}
          >
            {t("whoUsesMobile")}
          </motion.h2>
        )}
        <motion.div
          initial={"hidden"}
          whileInView={"visible"}
          viewport={{ amount: 0.3, once: true }}
          variants={downAnimation}
          className={styles.accordionItems}
        >
          {items.map((item) => (
            <Accordion
              key={item.id}
              item={item}
              activeItem={activeAccordionItem}
              setActiveItem={setActiveAccordionItem}
              hoverIndex={hoverIndex}
              setHoverIndex={setHoverIndex}
            />
          ))}
        </motion.div>
      </motion.div>
      <div className={styles.benefitWrapper}>
        <motion.h2
          initial={"hidden"}
          whileInView={"visible"}
          viewport={{ amount: 0.3, once: true }}
          variants={downAnimation}
          className={styles.benefitTitle}
        >
          <Trans
            i18nKey={
              mobileVersion
                ? "whatMakesIntransoUniqueMobile"
                : "whatMakesIntransoUnique"
            }
          />
        </motion.h2>
        <motion.h3
          variants={downAnimation}
          initial={"hidden"}
          whileInView={"visible"}
          viewport={{ amount: 0.3, once: true }}
          className={styles.benefitSubTitle}
        >
          <Trans
            i18nKey={
              mobileVersion
                ? "advancedTechnologyPlanningOptimizationMobile"
                : "advancedTechnologyPlanningOptimization"
            }
          />
        </motion.h3>
        <div className={styles.benefitGrid}>
          {benefits.map((b) => (
            <motion.div
              key={b.id}
              className={styles.benefitItem}
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ amount: 0.2, once: true }}
              variants={downAnimation}
            >
              <img src={b.image} alt={"approach"} />
              <span>{b.text}</span>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
