import React, { useEffect, useRef } from "react";
import styles from "./Cursor.module.scss";

const Cursor = () => {
  const cursorRef = useRef(null);
  useEffect(() => {
    const mouseMove = ({ clientX, clientY }) => {
      const mouseX = clientX - cursorRef.current.clientWidth / 2;
      const mouseY = clientY - cursorRef.current.clientHeight / 2;
      cursorRef.current.style.transform = `translate3d(${mouseX}px,${mouseY}px,0)`;
    };
    window.addEventListener("mousemove", mouseMove);
    return () => window.removeEventListener("mousemove", mouseMove);
  }, []);
  return <div ref={cursorRef} className={styles.cursor}></div>;
};

export default Cursor;
