import React, { useContext, useEffect, useState } from "react";
import styles from "./MenuButton.module.scss";
import cn from "classnames";

import { AppContext } from "../../../providers/AppContext";

const MenuButton = ({ setOpenMenu, setOpenModal }) => {
  const [toggle, setToggle] = useState(false);
  const { state } = useContext(AppContext);
  useEffect(() => {
    (!state.openMenu || !state.openModal) && setToggle(false);
    (state.openMenu || state.openModal) && setToggle(true);
  }, [state]);
  const onToggle = () => {
    if (state.openModal) {
      setOpenModal();
      setToggle(!toggle);
    } else {
      setOpenMenu();
      setToggle(!toggle);
    }
  };
  return (
    <div
      className={cn(styles.menuBurger, {
        [styles.menuBurgerOpen]: toggle,
      })}
      onClick={onToggle}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default MenuButton;
