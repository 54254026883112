import React from "react";
import styles from "./ImageListItem.module.scss";
import { downAnimation } from "../../styles/animations";
import { motion } from "framer-motion";

const ImageListItem = ({ title, className, sublist }) => {
  return (
    <>
      <motion.li
        initial={"hidden"}
        whileInView={"visible"}
        viewport={{ once: true }}
        variants={downAnimation}
        className={className}
      >
        {title}
      </motion.li>
      {sublist.length !== 0 && (
        <ul className={styles.sublist}>
          {sublist.map((subli) => (
            <motion.li
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ once: true }}
              variants={downAnimation}
              key={subli}
            >
              {subli}
            </motion.li>
          ))}
        </ul>
      )}
    </>
  );
};

export default ImageListItem;
