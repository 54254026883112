import React, { useRef } from "react";
import Logo from "../../assets/icons/Logo-header.svg";
import { Trans, useTranslation } from "react-i18next";
import FooterContacts from "../FooterContacts/FooterContacts";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styles from "./Footer.module.scss";
import useOnScreen from "../../hooks/useScroll";
import { downAnimation } from "../../styles/animations";
import { motion } from "framer-motion";
import { useDevice } from "../../hooks/useDevice";
const links = [
  {
    id: 1,
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/intransocompany/?viewAsMember=true",
  },
  {
    id: 2,
    name: "Twitter",
    href: "https://twitter.com/INTRANSO_LLC",
  },
];

const Footer = ({ setOpenModal }) => {
  const shortInfoRef = useRef();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const { isViewed: shortInfoView } = useOnScreen(shortInfoRef);
  const navigate = useNavigate()
  const deviceType = useDevice();
  const mobileVersion = deviceType === "MOBILE";
  const downloadFilesHandle = () => {
    const resUa = "/documents/INTRANSOSmartCounter(UA).pdf";
    const resRu = "/documents/INTRANSOSmartCounter(RU).pdf";
    const resEn = "/documents/INTRANSOSmartCounter(EN).pdf";
    const fileByLanguage =
      i18n.language === "ru" ? resRu : i18n.language === "en" ? resEn : resUa;
    return fileByLanguage;
  };

  return (
    <div className={styles.footerWrapper}>
      {pathname !== "/contacts" && <FooterContacts />}
      <div ref={shortInfoRef} className={styles.footerShortInfo}>
        <div className={styles.leftBlock}>
          <motion.img
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={downAnimation}
            src={Logo}
            alt="logo"
            className={styles.logoFooter}
          />
          <motion.p
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={downAnimation}
          >
            <Trans
              i18nKey={
                mobileVersion
                  ? "leaderExpertMarketSolutionsTransportInfrastructureMobile"
                  : "leaderExpertMarketSolutionsTransportInfrastructure"
              }
            ></Trans>
          </motion.p>
        </div>
        <div className={styles.rightBlock}>
          <div className={styles.listOfLinks}>
            <motion.h3
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ once: true }}
              variants={downAnimation}
            >
              {t("COMPANY")}
            </motion.h3>
            <div>
              <motion.a
                download={true}
                initial={"hidden"}
                whileInView={"visible"}
                viewport={{ once: true }}
                variants={downAnimation}
                custom={0.3}
                onClick={() => navigate('/aboutUs')}
              >
                {t("aboutUs")}
              </motion.a>
              <br/>
              {/*<motion.a*/}
              {/*  href={downloadFilesHandle()}*/}
              {/*  download={true}*/}
              {/*  initial={"hidden"}*/}
              {/*  whileInView={"visible"}*/}
              {/*  viewport={{ once: true }}*/}
              {/*  variants={downAnimation}*/}
              {/*  custom={0.6}*/}
              {/*>*/}
              {/*  {t("Presentation")}*/}
              {/*</motion.a>*/}
            </div>
          </div>
          <div className={styles.listOfLinks}>
            <motion.h3
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ once: true }}
              variants={downAnimation}
              custom={0.9}
            >
              {t("CONTACT_US")}
            </motion.h3>
            <div>
              <motion.a
                initial={"hidden"}
                whileInView={"visible"}
                viewport={{ once: true }}
                variants={downAnimation}
                custom={1.2}
                onClick={() => setOpenModal()}
              >
                {t("demoAccess")}
              </motion.a>
              <motion.div
                initial={"hidden"}
                whileInView={"visible"}
                viewport={{ once: true }}
                variants={downAnimation}
                custom={1.5}
              >
                <NavLink
                  to={"/contacts"}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  {t("contact")}
                </NavLink>
              </motion.div>
            </div>
          </div>
          <div className={styles.listOfLinks}>
            <motion.h3
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ once: true }}
              variants={downAnimation}
              custom={1.8}
            >
              {t("SOCIAL_NETWORKS")}
            </motion.h3>
            <div>
              {links.map((l, i) => {
                return (
                  <motion.div
                    key={l.id}
                    initial={"hidden"}
                    whileInView={"visible"}
                    viewport={{ once: true }}
                    variants={downAnimation}
                    custom={i + 2.1}
                  >
                    <a href={l.href} target="_blank">
                      {l.name}
                    </a>
                    <br />
                  </motion.div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.underLine} />
      <div className={styles.footerBottom}>
        <span>Intranso © 2024. All right reserved.</span>
        <span> Designed and supported by Gemicle Inc.</span>
      </div>
    </div>
  );
};

export default Footer;
