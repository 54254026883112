import React, { useContext, useRef } from "react";
import BusStopImage from "../../assets/images/BusStopImage.png";
import WifiGif from "../../assets/icons/wifi-icon.gif";
import CloudServer from "../../assets/icons/cloub-server-icon.svg";
import RightArrow from "../../assets/icons/right-arrow-icon.svg";
import SoftwareIcon from "../../assets/icons/software-icon.svg";
import PCounterRechartsImage from "../../assets/images/PCounterRecarts.png";
import PCounterRechartsImageTablet from "../../assets/images/RecgartImageTablet.png";
import PCounterRechartsImageMobile from "../../assets/images/RechartImageMobile.png";
import BenefitBack1 from "../../assets/images/BenefitBack1.png";
import Route1Image from "../../assets/images/Route1Image.png";
import Bus3Image from "../../assets/images/Bus-3-image.png";
import Route2Image from "../../assets/images/Route2Image.png";
import BenefitBack2 from "../../assets/images/BenefitBack3.png";
import PassengerTrafficImage from "../../assets/images/passenger-traffic-image.png";
import TimeStopsImage from "../../assets/images/time-stops-image.png";
import BenefitBack3 from "../../assets/images/BenefitBack4.png";
import LoudeScheduleImg from "../../assets/images/loudeScheduleImg.png";
import LoudeSchedule2Img from "../../assets/images/loudeSchedule2.png";
import BenefitImage from "../../assets/images/BenefitImage.png";
import BenefitImageTablet from "../../assets/images/BenefitImageTablet.png";
import RotateLogo from "../../assets/icons/rotate-logo.svg";
import MarkerIcon from "../../assets/icons/marker-icon.svg";
import NotificationBell from "../../assets/icons/notification-bell.gif";
import Button from "../../components/shared/Button/Button";
import styles from "./Products.module.scss";
import Tabs from "./Tabs/Tabs";
import ImageListItem from "../../components/ImageListItem/ImageListItem";
import { motion } from "framer-motion";
import {
  RightAnimation,
  LeftAnimation,
  downAnimation,
  RightAnimationImage,
  ImageRightAnimation,
} from "../../styles/animations";
import { Trans, useTranslation } from "react-i18next";
import { AppContext } from "../../providers/AppContext";
import { useDevice } from "../../hooks/useDevice";
import UseAnimation from "../../hooks/useAnimation";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import Sliders from "./Slider/Slider";
import Marker from "../../assets/icons/marker-icon.svg";
import LogoCircle from "../../assets/icons/LogoRotate.svg";

const softwareListClass = ["flowInformation", "operationControl", "planning", "workOptimization", "statistic", "diagram", "mobile"];

const MobileBlock = ({ inviteDemoHandle, t, mobileVersion }) => (
  <>
    <motion.div
      initial={"hidden"}
      whileInView={"visible"}
      variants={downAnimation}
      viewport={{ amount: 0.1, once: true }}
      custom={0.4}
    >
      <Button
        className={styles.demoButton}
        title={t("demo")}
        onClick={inviteDemoHandle}
        arrowColor={"white"}
        image={true}
      />
    </motion.div>
  </>
);

const Products = () => {
  const { t } = useTranslation();
  const { setState } = useContext(AppContext);
  const deviceType = useDevice();

  const { ref: deviceDescriptionRef, inView: deviceDescriptionInView } =
    useInView({
      threshold: 0,
    });
  const { ref: deviceTitleRef, inView: deviceTitleInView } = useInView({
    threshold: 0,
  });
  const mobileVersion = deviceType === "MOBILE";
  const tabletVersion = deviceType === "TABLET";
  const pCounterSoftwareArray = [
    // {
    //   id: 1,
    //   description: (<>
    //     <Trans i18nKey="realTimePassengerFlowInformation" /><br/>
    //     <Trans i18nKey="operationControl" /><br/>
    //     <Trans i18nKey="planningScheduking" /><br/>
    //     <Trans i18nKey="workOptimization" /><br/>
    //     <Trans i18nKey="fleetManagement" /><br/>
    //     <Trans i18nKey="businessIntelligence" />
    //   </>),
    //   subList: [
    //     // t("realTimePassengerFlowInformation"),
    //     // t("operationControl"),
    //     // t("planningScheduking"),
    //     // t("workOptimization"),
    //     // t("fleetManagement"),
    //     // t("businessIntelligence")
    //   ],
    // },
    {
      id: 1,
      description: <Trans i18nKey={tabletVersion ? "realTimePassengerFlowInformationTablet": "realTimePassengerFlowInformation"} />,
      subList: [],
    },
    {
      id: 2,
      description: <Trans i18nKey="operationControl" />,
      subList: [],
    },
    {
      id: 3,
      description: <Trans i18nKey="planningScheduling" />,
      subList: [],
    },
    {
      id: 4,
      description: <Trans i18nKey="workOptimization" />,
      subList: [],
    },
    {
      id: 5,
      description: <Trans i18nKey="fleetManagement" />,
      subList: [],
    },
    {
      id: 6,
      description: <Trans i18nKey="businessIntelligence" />,
      subList: [],
    },
    {
      id: 7,
      description: <Trans i18nKey="webAndMobileVersions" />,
      subList: [],
    },
  ];

  const inviteDemoHandle = () => setState({ openModal: true });
  // UseAnimation(devicePartRef);
  return (
    <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
      <div className={styles.devicesWrapper}>
        <div
          // initial={"hidden"}
          // whileInView={"visible"}
          // viewport={{ amount: 0.3, once: true }}
          className={styles.content}
        >
          <motion.div
            ref={deviceDescriptionRef}
            initial={"hidden"}
            whileInView={"visible"}
            variants={downAnimation}
            viewport={{ amount: 0.1, once: true }}
            className={cn(
              styles.description
              //     {
              //   [styles.descriptionAnimation]: deviceDescriptionInView,
              // }
            )}
          >
            <Trans i18nKey={"Automatic passengers counting system"} />
          </motion.div>
          <motion.h2
            ref={deviceTitleRef}
            initial={"hidden"}
            whileInView={"visible"}
            variants={downAnimation}
            viewport={{ amount: 0.1, once: true }}
            custom={0.4}
            className={cn(
              styles.deviceTitle
              //     {
              //   [styles.descriptionAnimation]: deviceTitleInView,
              // }
            )}
          >
            <Trans
              i18nKey={
                mobileVersion
                  ? "realTimePassengerTrafficMobile"
                  : "realTimePassengerTraffic"
              }
            />
          </motion.h2>
          <motion.div
            initial={"hidden"}
            whileInView={"visible"}
            variants={downAnimation}
            custom={0.8}
            viewport={{ amount: 0.1, once: true }}
            className={styles.deviceDescription}
          >
            <Trans
              i18nKey={
                mobileVersion
                  ? "theIntransoAIDeviceMobile"
                  : tabletVersion
                    ? "theIntransoAIDeviceTablet"
                    : "theIntransoAIDevice"
              }
            />
          </motion.div>
          <motion.div
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={downAnimation}
          >
            <Button
              className={styles.demoButton}
              title={t("getDemo")}
              onClick={() => setState({ openModal: true })}
              image={true}
              arrowColor={"black"}
            />
          </motion.div>
        </div>
        <motion.div
          className={styles.image}
          initial={"hidden"}
          whileInView={"visible"}
          viewport={{ once: true }}
          variants={ImageRightAnimation}
        >
          <img
            className={styles.busStopImage}
            src={BusStopImage}
            alt="busStop"
          />
          <img className={styles.wifiGif} src={WifiGif} alt="" />
          <div className={styles.schemaContainer}>
            <img
              className={styles.cloudServerImg}
              src={CloudServer}
              alt="cloudServer"
            />
            <span className={styles.textContainer}>
              <Trans i18nKey="allDataTransferredServer" />
            </span>
            <img
              className={styles.arrowRightImg}
              src={RightArrow}
              alt="arrowRight"
            />
            <span className={styles.textContainer}>
              <Trans i18nKey="Intranso Pcounter Software receives the data" />
            </span>
            <img
              className={styles.softwareImg}
              src={SoftwareIcon}
              alt="arrowRight"
            />
          </div>
        </motion.div>
      </div>
      <motion.div className={styles.softwareWrapper}>
        <div className={styles.softwareContent}>
          <motion.h2
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            className={styles.softwareTitle}
          >
            Intranso Pcounter Software
          </motion.h2>
          {deviceType === "MOBILE" && (
            <motion.img
              className={styles.rechartsImage}
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ once: true }}
              variants={ImageRightAnimation}
              src={PCounterRechartsImageMobile}
              alt={"Recharts Image"}
            />
          )}
          <ul>
            {pCounterSoftwareArray.map((li, i) => (
              // <motion.div key={li.id} variants={downAnimation} delay={i + 1}>
              <ImageListItem
                className={styles[softwareListClass[i]]}
                title={li.description}
                sublist={li.subList}
                key={i}
              />
              // </motion.div>
            ))}
          </ul>
        </div>
        {deviceType !== "MOBILE" && (
          <motion.img
            className={styles.rechartsImage}
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={ImageRightAnimation}
            src={
              deviceType === "TABLET"
                ? PCounterRechartsImageTablet
                : PCounterRechartsImage
            }
            alt={"Recharts Image"}
          />
        )}
      </motion.div>
      <motion.div className={styles.tabsWrapper}>
        <motion.h4
          className={styles.subtitle}
          variants={downAnimation}
          initial={"hidden"}
          whileInView={"visible"}
          viewport={{ once: true }}
        >
          {t("itAllAboutIntranso")}
        </motion.h4>
        <motion.h2
          className={styles.tabsTitle}
          variants={downAnimation}
          initial={"hidden"}
          whileInView={"visible"}
          viewport={{ once: true }}
        >
          <Trans i18nKey={"toolEffectiveDecisionMaking"} />
        </motion.h2>
        {deviceType !== "MOBILE" ? <Tabs /> : <Sliders />}
      </motion.div>
      <div className={styles.benefitWrapper1}>
        <div className={styles.benefitContent1}>
          <motion.h2
            initial={"hidden"}
            whileInView={"visible"}
            variants={downAnimation}
            viewport={{ amount: 0.1, once: true }}
            custom={1}
          >
            <Trans
              i18nKey={
                mobileVersion
                  ? "IntransoProductsImproveRevenueMobile"
                  : "IntransoProductsImproveRevenue"
              }
            />
          </motion.h2>
          {deviceType !== "MOBILE" && (
            <MobileBlock
              inviteDemoHandle={inviteDemoHandle}
              t={t}
              mobileVersion={mobileVersion}
            />
          )}
        </div>
        <div className={styles.imageBenefitWrapper1}>
          <img
            className={styles.benefitBack1}
            src={BenefitBack1}
            alt={"benefitBack1"}
          />
          <motion.img
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ amount: 0.1, once: true }}
            variants={RightAnimation}
            className={styles.route1Img}
            src={Route1Image}
            alt={"benefitBack1"}
          />
          <motion.img
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ amount: 0.1, once: true }}
            variants={RightAnimation}
            custom={0.4}
            className={styles.bus3Img}
            src={Bus3Image}
            alt={"benefitBack1"}
          />
          <motion.div
            className={styles.route2Img}
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ amount: 0.1, once: true }}
            variants={RightAnimation}
            custom={0.8}
          >
            <img
              src={Route2Image}
              className={styles.routeImg}
              alt={"benefitBack1"}
            />
            <img className={styles.bell} src={NotificationBell} alt="" />
          </motion.div>
        </div>
        {deviceType === "MOBILE" && (
          <MobileBlock
            inviteDemoHandle={inviteDemoHandle}
            t={t}
            mobileVersion={mobileVersion}
          />
        )}
      </div>
      <motion.div
        className={styles.benefitWrapper2}
        initial={"hidden"}
        whileInView={"visible"}
        viewport={{ once: true }}
      >
        <motion.div
          className={styles.benefitDescription2}
          variants={LeftAnimation}
        >
          <p>
            <Trans
              i18nKey={
                deviceType === "PC"
                  ? "weJustOfferSystemForCountingPassengers"
                  : !mobileVersion
                  ? "weJustOfferSystemForCountingPassengersTablet"
                  : "weJustOfferSystemForCountingPassengersMobile"
              }
            ></Trans>
          </p>
        </motion.div>
        <motion.div
          className={styles.benefitPercentBlock}
          variants={RightAnimation}
        >
          <h4>
            <Trans
              i18nKey={
                mobileVersion
                  ? "centralizedManagementOfThePublicTransportMobile"
                  : "centralizedManagementOfThePublicTransport"
              }
            />
          </h4>
        </motion.div>
      </motion.div>
      <div className={styles.benefitWrapper3}>
        <div className={styles.benefitDescription3}>
          <motion.h3
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ amount: 0.2, once: true }}
            variants={downAnimation}
          >
            <Trans
              i18nKey={
                mobileVersion
                  ? "implementationEffectMobile"
                  : "implementationEffect"
              }
            />
          </motion.h3>
          <ul>
            <motion.li
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ amount: 0.2, once: true }}
              variants={downAnimation}
              // custom={2}
            >
              <Trans
                i18nKey={
                  deviceType === "PC"
                    ? "realTimePassengerFlowByRoutes"
                    : mobileVersion
                    ? "realTimePassengerFlowByRoutesMobile"
                    : "realTimePassengerFlowByRoutesTablet"
                }
              />
            </motion.li>
            <motion.li
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ amount: 0.2, once: true }}
              variants={downAnimation}
              //custom={4}
            >
              <Trans i18nKey={"incomeIncreaseTo30"} />
            </motion.li>
            <motion.li
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ amount: 0.2, once: true }}
              variants={downAnimation}
              //custom={4}
            >
              <Trans i18nKey={"OperatingEfficiencyIncrease"} />
            </motion.li>
            <motion.li
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ amount: 0.2, once: true }}
              variants={downAnimation}
              // custom={3}
            >
              <Trans
                i18nKey={
                  deviceType === "PC"
                    ? "convenientPlanningTransport"
                    : "convenientPlanningTransportTablet"
                }
              />
            </motion.li>
          </ul>
        </div>
        <div className={styles.imageBenefitWrapper3}>
          <img className={styles.benefitBack2} src={BenefitBack2} alt="" />
          <motion.img
            className={styles.passengerTraffic}
            src={PassengerTrafficImage}
            alt=""
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ amount: 0.2, once: true }}
            variants={RightAnimation}
          />
          <motion.img
            variants={RightAnimation}
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ amount: 0.2, once: true }}
            custom={0.5}
            className={styles.timeStops}
            src={TimeStopsImage}
            alt=""
          />
        </div>
      </div>
      <motion.div className={styles.benefitWrapper4}>
        <div className={styles.imageBenefitWrapper4}>
          <img className={styles.benefitBack4} src={BenefitBack3} alt="" />
          <motion.img
            className={styles.loudeShedule1}
            src={LoudeScheduleImg}
            alt=""
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ amount: 0.2, once: true }}
            variants={LeftAnimation}
          />
          <motion.div
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ amount: 0.2, once: true }}
            variants={LeftAnimation}
            custom={0.5}
            className={styles.loudeSheduleContainer}
          >
            <img
              className={styles.loudeShedule2}
              src={LoudeSchedule2Img}
              alt=""
            />
            <img className={styles.bell} src={NotificationBell} alt="" />
          </motion.div>
        </div>
        <ul>
          <motion.li
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ amount: 0.2, once: true }}
            variants={downAnimation}
            custom={0.5}
          >
            <Trans
              i18nKey={
                mobileVersion
                  ? "qualityControlTrafficMobile"
                  : "qualityControlTraffic"
              }
            />
          </motion.li>
          <motion.li
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ amount: 0.2, once: true }}
            variants={downAnimation}
            custom={0.8}
          >
            <Trans
              i18nKey={
                deviceType === "PC"
                  ? "successfulPlanningOptimalNumberVehicles"
                  : tabletVersion
                    ? "successfulPlanningOptimalNumberVehiclesTablet"
                    : "successfulPlanningOptimalNumberVehiclesMobile"
              }
            />
          </motion.li>
          <motion.li
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ amount: 0.2, once: true }}
            variants={downAnimation}
            custom={1.1}
          >
            <Trans
              i18nKey={
                mobileVersion
                  ? "complianceWithNormsPermissibleNumberMobile"
                  : "complianceWithNormsPermissibleNumber"
              }
            />
          </motion.li>
          <motion.li
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ amount: 0.2, once: true }}
            variants={downAnimation}
            custom={1.4}
          >
            <Trans i18nKey={"optimizationWorkControllers"} />
          </motion.li>
        </ul>
      </motion.div>

      <div className={styles.benefitWrapper5}>
        <motion.div className={styles.benefitTitle5}>
          <motion.h2
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={downAnimation}
            custom={0.4}
          >
            {t("benefits")}
          </motion.h2>
          <motion.span
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={downAnimation}
            custom={0.8}
          >
            <Trans i18nKey={mobileVersion ? "theWayToTheCityOfTheFutureMobile": "theWayToTheCityOfTheFuture"} />
          </motion.span>
        </motion.div>
        <motion.div
          initial={"hidden"}
          whileInView={"visible"}
          viewport={{ once: true }}
          className={styles.benefitImage}
          variants={downAnimation}
          custom={1.2}
        >
          <img
            className={styles.bigImage}
            src={deviceType === "PC" ? BenefitImage : BenefitImageTablet}
            alt={"benefitImage"}
          />
          <div className={styles.rotateImg}>
            <img className={styles.marker} src={Marker} alt={"logo"} />
            <img className={styles.animImg} src={LogoCircle} alt={"logo"} />
          </div>
        </motion.div>

        <ul>
          <motion.li
            className={styles.liIncome}
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={downAnimation}
          >
            <span>
              <Trans
                i18nKey={
                  deviceType === "PC"
                    ? "TheConceptOfModularProduct"
                    : tabletVersion
                      ? "TheConceptOfModularProductTablet"
                      : "TheConceptOfModularProductMobile"
                }
                shouldUnescape={true}
              />
            </span>
            {/*<span>{" 30%"}</span>*/}
          </motion.li>
          <motion.li
            className={styles.liPayback}
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={downAnimation}
          >
            <span>
              <Trans
                i18nKey={
                  mobileVersion ? "paybackPeriodMobile" : "paybackPeriod"
                }
                shouldUnescape={true}
              />
            </span>
          </motion.li>

          <motion.li
            className={styles.liPlanning}
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={downAnimation}
            custom={0.1}
          >
            <span>
              <Trans
                i18nKey={
                  deviceType === "PC"
                    ? "schedulingAndMatchingPassengers"
                    : mobileVersion
                    ? "schedulingAndMatchingPassengersMobile"
                    : "schedulingAndMatchingPassengersTablet"
                }
              />
            </span>
          </motion.li>
          <motion.li
            className={styles.liEfficiency}
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={downAnimation}
            custom={0.2}
          >
            <span>
              <Trans
                i18nKey={
                  deviceType === "PC"
                    ? "UsingTheServiceIsSimple"
                    : "UsingTheServiceIsSimpleMobile"
                }
              />
            </span>
          </motion.li>

          <motion.li
            className={styles.liСompetition}
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={downAnimation}
            custom={0.3}
          >
            <span>
              {" "}
              <Trans
                i18nKey={
                  deviceType === "PC"
                    ? "Increasing competitiveness through the introduction of information technology"
                    : mobileVersion
                    ? "Increasing competitiveness through the introduction of information technology mobile"
                    : "Increasing competitiveness through the introduction of information technology tablet"
                }
              />
            </span>
          </motion.li>

          <motion.li
            className={styles.liCounter}
            initial={"hidden"}
            whileInView={"visible"}
            viewport={{ once: true }}
            variants={downAnimation}
            custom={0.4}
          >
            <span>
              {" "}
              <Trans
                i18nKey={
                  deviceType === "PC"
                    ? "AutomaticCountingOfPassengers"
                    : mobileVersion
                    ? "AutomaticCountingOfPassengersMobile"
                    : "AutomaticCountingOfPassengersTablet"
                }
              />
            </span>
          </motion.li>
        </ul>
      </div>
    </div>
  );
};

export default Products;
