import React, { useEffect, useLayoutEffect, useState } from "react";

const useOnScreen = (ref) => {
  const [isViewed, setIsViewed] = useState(false);

  useEffect(() => {
    const topPos = (element) => element?.getBoundingClientRect().top;
    // const getHeight = (element) => element.offsetHeight;
    const divPos = topPos(ref.current);
    // const div3Height = getHeight(refThree.current);
    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;
      // console.log("divPos", divPos);
      // console.log("scrollPos", scrollPos);
      if (divPos < scrollPos) {
        setIsViewed(true);
      } else {
        setIsViewed(false);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [window.scrollY]);
  return { isViewed };
};

export default useOnScreen;
