import React from "react";
import styles from "./Slider.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Trans, useTranslation } from "react-i18next";
import PlaneMobile from "../../../assets/images/PlanMobile.png";
import OptimizeMobile from "../../../assets/images/OptimizeMobile.png";
import ControlMobile from "../../../assets/images/controlMobile.png";
import ForFleetMobile from "../../../assets/images/ForFleetMobile.png";
import ApplicationMpbile from "../../../assets/images/ApplicationMobile.png";
import { useDevice } from "../../../hooks/useDevice";

const Sliders = () => {
  const { t } = useTranslation();
  const deviceType = useDevice();
  const mobileVersion = deviceType === "MOBILE";
  const contentArray = [
    {
      id: 1,
      title: t("plan"),
      type: "plan",
      image: PlaneMobile,
      descriptionTitle: t("planning"),
      list: [
        <Trans i18nKey="creationStops" />,
        <Trans i18nKey="createEditRoutes" />,
        <Trans i18nKey="support for layouts and multi-directional trips" />,
        <Trans i18nKey="correcting and arranging stops" />,
        <Trans i18nKey="editing routes on the map" />,
        <Trans i18nKey="Create routes on the map" />,
        <Trans i18nKey="route scheduling" />,
      ],
    },
    {
      id: 2,
      title: t("control"),
      type: "control",
      image: ControlMobile,
      descriptionTitle: t("controlInstruments"),
      list: [
        <Trans i18nKey="PassengerTrafficInReal" />,
        <Trans i18nKey="RoutesAndTrips" />,
        <Trans i18nKey="trackingOfTransport" />,
        <Trans i18nKey="AnticipationOfPossible" />,
        <Trans i18nKey="ETAEstimated" />,
      ],
    },
    {
      id: 3,
      title: t("optimize"),
      type: "optimize",
      image: OptimizeMobile,
      descriptionTitle: t("flightMonitoring"),
      list: [
        <Trans i18nKey="optimization of routes, flights" />,
        <Trans i18nKey="onlineExecutionChart" />,
        <Trans
          i18nKey={
            mobileVersion
              ? "different colors for completed mobile"
              : "different colors for completed"
          }
        />,
        <Trans
          i18nKey={
            mobileVersion
              ? "editing routes on the map mobile"
              : "editing routes on the map"
          }
        />,
      ],
    },
    {
      id: 4,
      title: t("forFleet"),
      type: "fleet",
      image: ForFleetMobile,
      descriptionTitle: t("forFleetManagement"),
      list: [
        t("fleet management tools"),
        <Trans
          i18nKey={
            mobileVersion
              ? "GPS tracking and monitoring mobile"
              : "GPS tracking and monitoring"
          }
        />,
        t("wide range of reports"),
        <Trans
          i18nKey={
            mobileVersion
              ? "Records of service and repair work mobile"
              : "Records of service and repair work"
          }
        />,
        t("maintenance planning"),
        <Trans
          i18nKey={
            mobileVersion
              ? "online control of repair costs mobile"
              : "online control of repair costs"
          }
        />,
        t("Employee time tracking"),
        t("fuel consumption control"),
      ],
    },
    {
      id: 5,
      title: t("application"),
      type: "application",
      image: ApplicationMpbile,
      descriptionTitle: <Trans i18nKey={"Application for the driver"} />,
      list: [
        <Trans
          i18nKey={
            mobileVersion
              ? "information and messages about traffic intervals on the route mobile"
              : "information and messages about traffic intervals on the route"
          }
        />,
        <Trans
          i18nKey={
            mobileVersion
              ? "notification of the trip and changes mobile"
              : "notification of the trip and changes"
          }
        />,
        <Trans
          i18nKey={
            mobileVersion
              ? "detailed information about the route with timetable mobile"
              : "detailed information about the route with timetable"
          }
        />,
        <Trans
          i18nKey={
            mobileVersion
              ? "comparison of the actual trip with the schedule and estimated time of arrival mobile"
              : "comparison of the actual trip with the schedule and estimated time of arrival "
          }
        />,
      ],
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={styles.sliders}>
      <Slider {...settings}>
        {contentArray.map((slide) => (
          <div className={styles.currentSlider}>
            <div className={styles.inputContent}>
              <div className={styles.imageContainer}>
                <img src={slide.image} />
              </div>
              <h2>{slide.title}</h2>
              <ul>
                {slide.list.map((li) => (
                  <li>{li}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Sliders;
