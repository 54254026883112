import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { ru, en, ua } from "./translations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: en },
      ru: { translations: ru },
      ua: { translations: ua },
    },
    detection: {
      lookupCookie: "language",
    },
    debug: false,
    fallbackLng: "ua",
    // setting namespace
    ns: ["translations"],
    defaultNs: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });

export default i18n;
