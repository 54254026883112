import React, { useContext, useEffect, useState, } from "react";
import Header from "../Header/Header";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import ModalCustom, { useLockBodyScroll } from "../shared/Modal/Modal";
import styles from "./Layout.module.scss";
import { AppContext } from "../../providers/AppContext";
import ArrowDown from "../../assets/icons/arrow-down.svg";
import PreviewPage from "../../pages/PreviewPage/PreviewPage";
import cn from "classnames";
import Cursor from "../shared/Cursor/Cursor";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import HeaderImageHome from "../../assets/images/HeaderImage1.png";
import HeaderImageHomeTablet from "../../assets/images/HeaderImage1Tablet.png";
import HeaderImageHomeMobile from "../../assets/images/HeaderImage1Mobile.png";
import HeaderImageProduct from "../../assets/images/HeaderImage2.png";
import HeaderImageProductTablet from "../../assets/images/HeaderImage2Tablet.png";
import HeaderImageProductMobile from "../../assets/images/HeaderImage2Mobile.png";
import HeaderImageAboutUs from "../../assets/images/HeaderAboutUs.png";
import HeaderImageAboutUsMobile from "../../assets/images/HeaderAboutUsMobile.png";
import Image1Home from "../../assets/images/Main-Image1.png";
import Image2Home from "../../assets/images/Main-Image2.png";
import Benefit1Image from "../../assets/images/BenefitBack1.png";
import Benefit2Image from "../../assets/images/BenefitBack3.png";
import Benefit4Image from "../../assets/images/BenefitBack4.png";
import ContactsImage from "../../assets/images/ContactsImage.png";
import BusStopImage from "../../assets/images/BusStopImage.png";
import SoftwareImage from "../../assets/images/SoftwareImage.png";
import TabBackground from "../../assets/images/tabs-background.png";
import PCounterRechartImage from "../../assets/images/PCounterRecarts.png";
import PCounterRechartImageMobile from "../../assets/images/RechartImageMobile.png";
import AboutUsImage from "../../assets/images/AboutUsBackground.png";
import AboutUsImageTablet from "../../assets/images/AboutUsBackgroundTablet.png";

import AboutUsImageMobile from "../../assets/images/AboutUsBackgroundMobile.png";
import { useDevice } from "../../hooks/useDevice";

const Layout = () => {
  const bigImagesHome = [
    HeaderImageHome,
    HeaderImageProduct,
    HeaderImageAboutUs,
    Image1Home,
    Image2Home,
    ContactsImage,
  ];

  const bigImagesHomeMobile = [
    HeaderImageHomeMobile,
    HeaderImageProductMobile,
    HeaderImageAboutUsMobile,
  ];
  const bigImagesHomeTablet = [
    HeaderImageHomeTablet,
    HeaderImageProductTablet,
    HeaderImageAboutUs,
  ];

  const bigImageAboutUs = [
    AboutUsImage,
    HeaderImageHome,
    HeaderImageProduct,
    HeaderImageAboutUs,
  ];
  const bigImageAboutUsMobile = [
    AboutUsImageMobile,
    // HeaderImageHomeMobile,
    // HeaderImageProductMobile,
    HeaderImageAboutUsMobile,
  ];
  const bigImageAboutUsTablet = [
    AboutUsImageTablet,
    HeaderImageHomeTablet,
    HeaderImageProductTablet,
    HeaderImageAboutUs,
  ];

  const bigImagesProduct = [
    HeaderImageHome,
    HeaderImageProduct,
    HeaderImageAboutUs,
    //AboutUsImage,
    ContactsImage,
    Benefit1Image,
    Benefit2Image,
    //Benefit4Image,
    BusStopImage,
    SoftwareImage,
    TabBackground,
    // BenefitImage,
    PCounterRechartImage,
  ];
  const bigImagesProductTablet = [
    HeaderImageHomeTablet,
    HeaderImageProductTablet,
    HeaderImageAboutUs,
    //AboutUsImage,
    //ContactsImage,
    Benefit1Image,
    //Benefit2Image,
    //Benefit4Image,
    BusStopImage,
    SoftwareImage,
    TabBackground,
    // BenefitImageTablet,
    // PCounterRechartImage,
  ];
  const bigImagesProductMobile = [
    // HeaderImageHomeMobile,
    HeaderImageProductMobile,
    // HeaderImageAboutUsMobile,
    Benefit1Image,
    Benefit2Image,
    Benefit4Image,
    BusStopImage,
    // BenefitImageTablet,
    PCounterRechartImageMobile,
  ];

  const { state, setState } = useContext(AppContext);
  const { pathname } = useLocation();
  const deviceType = useDevice();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollToUp, setScrollToUp] = useState(false);
  const [loading, setLoading] = useState(true);
  useLockBodyScroll(state.openMenu);
  useLockBodyScroll(state.openModal);
  const typeLocation = (
    location,
    arrayImgHome,
    arrayImgProduct,
    arrayImgAboutUs
  ) => {
    switch (location) {
      case "/": {
        Promise.all(arrayImgHome.map(checkImage)).then(() => setLoading(false));
        // Promise.all(arrayImgProduct.map(checkImage));
        // Promise.all(arrayImgAboutUs.map(checkImage));
        break;
      }
      case "/products": {
        Promise.all(arrayImgProduct.map(checkImage)).then(() =>
          setLoading(false)
        );
        // Promise.all(arrayImgHome.map(checkImage));
        // Promise.all(arrayImgAboutUs.map(checkImage));
        break;
      }
      case "/aboutUs": {
        Promise.all(arrayImgAboutUs.map(checkImage)).then(() =>
          setLoading(false)
        );
        Promise.all(arrayImgProduct.map(checkImage));
        Promise.all(arrayImgHome.map(checkImage));
        break;
      }
      default: {
        Promise.all(arrayImgHome.map(checkImage)).then(() => setLoading(false));
        Promise.all(arrayImgProduct.map(checkImage));
        Promise.all(arrayImgAboutUs.map(checkImage));
        break;
      }
    }
  };
  const onloadTypeImages = (device) => {
    switch (device) {
      case "PC": {
        typeLocation(
          pathname,
          bigImagesHome,
          bigImagesProduct,
          bigImageAboutUs
        );
        break;
      }
      // case "MOBILE":
      //   break;
      case "TABLET":
        typeLocation(
          pathname,
          bigImagesHomeTablet,
          bigImagesProductTablet,
          bigImageAboutUsTablet
        );
        break;
      default:
        typeLocation(
          pathname,
          bigImagesHomeMobile,
          bigImagesProductMobile,
          bigImageAboutUsMobile
        );
        break;
    }
  };
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const checkImage = (path) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(path);
      //img.onerror = () => reject()

      img.src = path;
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    onloadTypeImages(deviceType);
    // Promise.all(bigImagesHome.map(checkImage)).then(() => setLoading(false));
    // Promise.all(bigImagesProduct.map(checkImage));
    // Promise.all(bigImageAboutUs.map(checkImage));
    // const time = setTimeout(() => {
    //   setLoading(false);
    // }, 3000);
    // return () => {
    //   clearInterval(time);
    // };
  }, []);
  useEffect(() => {
    scrollPosition > document.body.scrollHeight / 2.5
      ? setScrollToUp(true)
      : setScrollToUp(false);
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AnimatePresence>
      {loading ? (
        <motion.div
          animate={{ background: "transparent" }}
          transition={{ duration: 2 }}
          exit={{ background: "transparent" }}
        >
          <PreviewPage className={cn({ [styles.hidden]: !loading })} />
        </motion.div>
      ) : (
        <>
          <Header
            setOpenModal={() =>
              setState((prev) => ({ ...prev, openModal: !state.openModal }))
            }
            setOpenMenu={() =>
              setState((prev) => ({ ...prev, openMenu: !state.openMenu }))
            }
          />
          <Outlet />
          <Footer setOpenModal={() => setState({ openModal: true })} />
          <ModalCustom
            isOpen={state.openModal}
            isOpenMenu={state.openMenu}
            onClose={() => setState((prev) => ({ ...prev, openModal: false }))}
            setOpenModal={() =>
              setState((prev) => ({ ...prev, openModal: !state.openModal }))
            }
            setOpenMenu={() =>
              setState((prev) => ({ ...prev, openMenu: !state.openMenu }))
            }
          />
          {/*{scrollToUp && (*/}
          <button
            className={cn(styles.buttonScrollUp, {
              [styles.buttonScrollUpShow]: scrollToUp,
            })}
            onClick={() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            <img src={ArrowDown} alt="" />
          </button>
          {/*)}*/}
        </>
      )}

      {/*{state.typeDevice === "PC" && <Cursor />}*/}
    </AnimatePresence>
  );
};

export default Layout;
