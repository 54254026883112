import React, { useState } from "react";
import styles from "./Input.module.scss";
import "react-phone-input-2/lib/style.css";
import Select from "../Select/Select";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import {
  cutLongText,
  telephoneValidation,
} from "../../../utils/helpers";

const timeArray = Array(24)
  .fill(24)
  .map(
    (_, i) =>
      `${i.toString().padStart(2, 0)}:00 - ${(i + 1)
        .toString()
        .padStart(2, 0)}:00`
  );
const Input = ({
  name,
  label,
  register,
  watch,
  errors,
  setValue,
}) => {
  const [activeInput, setActiveInput] = useState(false);
  const { t } = useTranslation();

  const {
    onChange,
    onBlur,
    ref,
    name: N,
  } = register(name, {
    required: true,
    minLength: name === "telephone" && 10,
    maxLength: 40,
    pattern:
      name === "email"
        ? /\S+@\S+\.\S+/
        : name === "name"
        ? /^(?=[a-z0-9а-яА-ЯёЁІі])[a-z0-9а-яА-ЯёЁІі\s]{0,99}[a-z0-9а-яА-ЯёЁІі]$/i
        : /(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,3})/,
    onBlur: () => watch(name).length === 0 && setActiveInput(false),
    onChange: (e) => {
      if (name === "telephone") {
        const t = telephoneValidation(e.target.value);
        setValue("telephone", t);
      }
      if (name === "name" || name === "email") {
        const t = cutLongText(e.target.value);
        setValue(name, t);
      }
    },
  });
  const selectTimePeriodHandle = (t) => {
    setValue("time", t);
  };
  const inputByNameHandle = () => {
    switch (name) {
      case "time":
        return (
          <Select
            options={timeArray}
            onChange={selectTimePeriodHandle}
            onFocus={() => setActiveInput(true)}
            onBlur={() => setActiveInput(false)}
            register={register}
            ref={ref}
            name={N}
            errors={errors}
            activeInput={activeInput}
          />
        );
      // case "telephone":
      //   return (
      //     <TelephoneSelect
      //       activeInput={activeInput}
      //       setValue={setValue}
      //       onFocus={() => setActiveInput(true)}
      //       onBlur={() => setActiveInput(false)}
      //       errors={errors}
      //     />
      //   );
      default:
        return (
          <input
            className={cn(styles.input, {
              [styles.activeFieldBorder]: activeInput,
              [styles.errorFieldBorder]: errors[name],
            })}
            type="text"
            autoComplete="off"
            placeholder=""
            onFocus={() => setActiveInput(true)}
            onBlur={onBlur}
            onChange={onChange}
            maxLength={40}
            ref={ref}
            name={N}
          />
        );
    }
  };

  const chooseErrors = () => {
    switch (true) {
      case errors[name]?.type === "pattern" && name === "email": {
        return t("enter valid email");
      }
      case errors[name]?.type === "pattern" && name === "name": {
        return t("enter valid name");
      }
      case errors[name]?.type === "minLength": {
        return t("enter valid telephone");
      }
      default:
        return `${t("enter")} ${t(name)}`;
    }
  };
  return (
    <div className={styles.inputContainer}>
      {inputByNameHandle()}
      <label className={cn({ [styles.activeInput]: activeInput })}>
        {label}
      </label>
      {errors[name] && (
        <span className={styles.errorField}>{chooseErrors()}</span>
      )}
      {/*<span className="focus-border"></span>*/}
    </div>
  );
};

export default Input;
