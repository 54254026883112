import React, { useEffect, useState } from "react";
import Input from "../shared/Input/Input";
import styles from "./FormStepper.module.scss";
import { useTranslation } from "react-i18next";
import Button from "../shared/Button/Button";
import { validateEmail, validateName } from "../../utils/helpers";

const FormStepper = ({ register, watch, errors, setValue, sendForm }) => {
  const inputs = ["name", "email", "telephone"];
  const { t, i18n } = useTranslation();
  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);
  useEffect(() => {
    selectErrorOnChange();
    // error !== null &&
    //   ((watch()[inputs[step - 1]].length > 0 && inputs[step - 1] !== "email") ||
    //   (inputs[step - 1] === "email" && validateEmail(watch()[inputs[step - 1]]))
    //     ? setError(false)
    //     : setError(true));
  }, [watch()[inputs[step - 1]]]);

  const selectErrorOnChange = () => {
    if (error !== null) {
      if (
        watch()[inputs[step - 1]].length > 0 &&
        inputs[step - 1] === "email" &&
        validateEmail(watch()[inputs[step - 1]])
      ) {
        setError(false);
      } else if (
        watch()[inputs[step - 1]].length > 0 &&
        inputs[step - 1] === "name" &&
        validateName(watch()[inputs[step - 1]])
      ) {
        setError(false);
      } else if (
        watch()[inputs[step - 1]].length > 10 &&
        inputs[step - 1] === "telephone"
      ) {
        setError(false);
      } else {
        setError(true);
      }
    }
  };

  const selectErrorOnClick = () => {
    if (
      (watch()[inputs[step - 1]].length === 0 &&
        inputs[step - 1] === "email") ||
      (!validateEmail(watch()[inputs[step - 1]]) &&
        inputs[step - 1] === "email")
    ) {
      setError(true);
    } else if (
      (watch()[inputs[step - 1]].length === 0 && inputs[step - 1] === "name") ||
      (!validateName(watch()[inputs[step - 1]]) && inputs[step - 1] === "name")
    ) {
      setError(true);
    } else if (
      (watch()[inputs[step - 1]].length === 0 &&
        inputs[step - 1] === "telephone") ||
      (watch()[inputs[step - 1]].length < 10 &&
        inputs[step - 1] === "telephone")
      // (!validateName(watch()[inputs[step - 1]]) && inputs[step - 1] === "telephone")
    ) {
      setError(true);
    } else {
      setStep((prev) => ++prev);
      setValue(inputs[step], "");
      setError(null);
    }
  };
  const chooseErrors = () => {
    switch (true) {
      case inputs[step - 1] === "email": {
        if (watch()[inputs[step - 1]].length === 0) {
          return `${t("enter")} ${t(inputs[step - 1])}`;
        }
        return t("enter valid email");
      }
      case inputs[step - 1] === "name": {
        if (watch()[inputs[step - 1]].length === 0) {
          return `${t("enter")} ${t(inputs[step - 1])}`;
        }
        return t("enter valid name");
      }
      case inputs[step - 1] === "telephone": {
        if (watch()[inputs[step - 1]].length === 0) {
          return `${t("enter")} ${t(inputs[step - 1])}`;
        }
        return t("enter valid telephone");
      }
      default:
        break;
    }
  };

  const nextStepHandle = () => {
    step < 3
      ? (() => {
          selectErrorOnClick();
          // watch()[inputs[step - 1]].length === 0 ||
          // (inputs[step - 1] === "email" &&
          //   !validateEmail(watch()[inputs[step - 1]]))
          //   ? setError(true)
          //   : (() => {
          //       setStep((prev) => ++prev);
          //       setValue(inputs[step], "");
          //       setError(null);
          //     })();
        })()
      : (watch()[inputs[step - 1]].length === 0 &&
          inputs[step - 1] === "telephone") ||
        (watch()[inputs[step - 1]].length < 10 &&
          inputs[step - 1] === "telephone")
      ? // (!validateName(watch()[inputs[step - 1]]) && inputs[step - 1] === "telephone")
        setError(true)
      : (() => {
          sendForm(watch());
        })();
  };
  return (
    <div className={styles.formMobileWrapper}>
      {/*<h2>Intranso Demo</h2>*/}
      <p>
        {t(
          "Just 3 steps and our managers will help you choose the most effective solution for the development of your business"
        )}
      </p>

      <Input
        name={inputs[step - 1]}
        register={register}
        label={t(inputs[step - 1])}
        watch={watch}
        errors={errors}
        setValue={setValue}
      />
      {error && (
        <span className={styles.errors}>
          {chooseErrors()}
          {/*{watch()[inputs[step - 1]].length > 0 && inputs[step - 1] === "email"*/}
          {/*  ? t("enter valid email")*/}
          {/*  : `${t("enter")} ${t(inputs[step - 1])}`}*/}
        </span>
      )}
      <div className={styles.nextStepWrapper}>
        <div className={styles.steps}>{`${step}/3`}</div>
        <Button
          className={styles.buttonNext}
          title={t("next")}
          type="button"
          onClick={nextStepHandle}
        />
      </div>
    </div>
  );
};

export default FormStepper;
